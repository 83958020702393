import { QueryClient } from "react-query";

const queryConfig = {
    queries: {
        useErrorBoundary: true,
        refetchOnWindowFocus: false,
        retry: false,
        cacheTime: 6000
    }
}

export const queryClient = new QueryClient({ defaultOptions: queryConfig })

export const BACKEND_URL = process.env.REACT_APP_BACK_URL
export const BACKEND_URL_STAKING = process.env.REACT_APP_STAKE_BACK_URL
export const BACKEND_URL_DEPOSIT = process.env.REACT_APP_DEPOSIT_BACK_URL

export const SERVICE_URL = process.env.REACT_APP_SERVICE_URL;

