import React, { useEffect, useState } from 'react';
import "../../assets/scss/Innerpages/Innerpages.scss";
import xlsIcon from "../../assets/images/xlsIcon.png";
import * as XLSX from "xlsx";
import { useAnawalletHistory, useExportAnawalletHistory } from './api/Anawalletapi';
import { useDebounce } from '../../hook/useDounce';
import Pagination from '../common/pagination/Pagination';
import Nodatafound from '../Nodata/Nodatafound';
import { TransferHistorySkeleton } from '../../Components/Loader/skeleton'
import * as FileSaver from "file-saver";
import Converter from '../../lib/converter';
import { networkSymbols } from '../../lib/network';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
const AnawalletHistory = () => {

   const [page, setPage] = useState(1)
   const size = 10
   const [filter, setFilter] = useState('')
   const searchText = useDebounce(filter)
   const [sort, setSort] = useState('0')
   const status = false
   const [data, setData] = useState(null);
   const [isLoading, setIsLoading] = useState(false);
   const anaWalletmutation = useAnawalletHistory();
   const anaExportHistory = useExportAnawalletHistory()

   useEffect(() => {
      fetchData()
      window.scrollTo(0, 0)
   }, [page, size, searchText, sort])

   const fetchData = async () => {
      setIsLoading(true);
      let obj =
      {
         page: page,
         size: size,
         filter: searchText,
         time: +sort,
         status: status
      }

      try {
         const fetchedData = await anaWalletmutation.mutateAsync(obj);
         setData(fetchedData);
      } catch (error) {
      }

      setIsLoading(false);
   };

   const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
   const fileExtension = ".xlsx";

   const handleDownload = async () => {
      let fileName = "anaicohistory";

      let obj =
      {
         filter: searchText,
         time: +sort,
      }
      const fetchedData = await anaExportHistory.mutateAsync(obj);
      const ws = XLSX.utils.json_to_sheet(fetchedData?.data);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
   }


   return (
      <div className="container animated_div">
         <h3 className="secHeading text-center">ICO ANA Wallet History</h3>

         <div className="row">
            <div className="col-12">
               <div className="d-flex align-items-center flex-wrap">
                  <h3 className="cardTitle lgTitle">ICO Buy ANA for ANA Wallet History</h3>
                  <div className="d-flex align-items-center filterCnt mb-3 ml-auto flex-wrap flex-md-nowrap">
                     <input type="search" placeholder="Search ID / Phase" className={(filter ? "form-control my-1" : "form-control searchbox my-1") } onChange={(e) => setFilter(e.target.value)} />

                     <div className='octDrpdown dark mx-1'>
                        <UncontrolledDropdown>
                           <DropdownToggle caret>
                              {sort === "7" ? "1 Week Ago" : sort === "30" ? "1 Month Ago" : "Recently"}
                           </DropdownToggle>
                           <DropdownMenu>
                              <DropdownItem tag={"a"} type="button" onClick={() => setSort("0")}>Recently</DropdownItem>
                              <DropdownItem tag={"a"} type="button" onClick={() => setSort("7")}>1 Week Ago</DropdownItem>
                              <DropdownItem tag={"a"} type="button" onClick={() => setSort("30")}>1 Month Ago</DropdownItem>
                           </DropdownMenu>
                        </UncontrolledDropdown>
                     </div>
                     <button className="btn filterBtn my-1" type="button" onClick={handleDownload} disabled={Boolean(!data?.status)}><img src={xlsIcon} width="24" height="24" alt="xlsIcon" className="mr-2" />Export File</button>
                  </div>
               </div>

               <div className="card tableCard">
                  <div className="cardInnerCnt">
                     <div className="table-responsive">
                        <table className="table table-hover mb-0">
                           <thead>
                              <tr>
                                 <th>S.No</th>
                                 <th>Date and Time</th>
                                 <th>ID</th>
                                 <th>Amount Value</th>
                                 <th>Amount Value</th>
                                 <th>Phase</th>
                                 <th className="text-right">Status</th>
                              </tr>
                           </thead>
                           <tbody>
                              {isLoading ?
                                 <TransferHistorySkeleton />
                                 :
                                 data?.data?.length > 0 ?
                                    data?.data?.map((n, i) => (
                                       <tr key={n?.BusinessICOID}>
                                          <td>{page == 1
                                             ? i + 1
                                             : (page - 1) * 10 +
                                             (i + 1)}</td>
                                          <td>{Converter.formatDated(n.createdAt)}</td>
                                          <td>{n.BusinessICOID}</td>
                                          <td>{n.currencyAmount} {n.currencySymbol} {n?.currencySymbol === 'USDT' && networkSymbols[n?.currencyNetwork]}</td>
                                          <td>{(n?.ANAamount)} ANA</td>
                                          <td>{n.purchasedPhase}</td>
                                          <td className="text-right text-success">{n.status}</td>
                                       </tr>
                                    ))
                                    :
                                    <tr>
                                       <td colSpan={7}><Nodatafound message="No Transactions found." /> </td>
                                    </tr>
                              }
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
               <Pagination
                  total={data?.count}
                  current={page}
                  size={size}
                  onPageChange={no => setPage(no)}
               />

            </div>
         </div>
      </div>
   )
}
export default AnawalletHistory;