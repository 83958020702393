import "../../../../assets/scss/Home/About/About.scss";
import limitedSupply from "../../../../assets/images/Home/About/limitedSupply.png";
import fundRaising from "../../../../assets/images/Home/About/fundRaising.png";
import referEarn from "../../../../assets/images/Home/About/referEarn.png";
import staking from "../../../../assets/images/Home/About/staking.png";
import { Parallax } from "react-scroll-parallax";
import PropTypes from 'prop-types'; 

const About = ({ data }) => {
   return (
      <div className="abt">
         <div className="container">
            <div className="abtHd">
               <div className="row mb-5">
                  <div className="col-lg-6">
                     <div className="abtHdTls">
                        <div className="secTlTg mb-3">
                           <span>About us</span>
                        </div>
                        <h2 className="secTl">
                           <span className="txt-gradient">{data?.status ? data?.data[1]?.title : ''}</span>
                        </h2>
                     </div>
                  </div>
                  <div className="col-lg-6 mt-auto">
                     <div className="abtHdpr">
                        <p className="secPara" dangerouslySetInnerHTML={{ __html: data?.status ? data?.data[1]?.pagecontent : '' }} />
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-lg-6 d-flex">
                     <div className="futureCnt ylwCnt w-100">
                        <Parallax speed={3}>
                           <img src={limitedSupply} width="143" height="125" alt="futureSecurityIcon" />
                        </Parallax>
                        <div className="d-flex">
                           <div className="futureInnerCnt ml-auto">
                              <h3 className="heading">{data?.status ? data?.data[2]?.title : ''}</h3>
                              <p dangerouslySetInnerHTML={{ __html: data?.status ? data?.data[2]?.pagecontent : '' }} />
                           </div>
                        </div> 
                        {/* <div className="row">
                           <div className="col-sm-7 col-md-8 col-lg-7 ml-sm-auto">
                              <h3 className="heading">{data?.status ? data?.data[2]?.title : ''}</h3>
                              <p dangerouslySetInnerHTML={{ __html: data?.status ? data?.data[2]?.pagecontent : '' }} />
                           </div>
                        </div> */}
                     </div>
                  </div>
                  <div className="col-lg-6 d-flex">
                     <div className="futureCnt bluCnt w-100">
                        <Parallax speed={3}>
                           <img src={fundRaising} width="143" height="125" alt="futureLowFeeIcon" />
                        </Parallax>
                        <div className="d-flex">
                           <div className="futureInnerCnt ml-auto">
                              <h3 className="heading">{data?.status ? data?.data[3]?.title : ''}</h3>
                              <p dangerouslySetInnerHTML={{ __html: data?.status ? data?.data[3]?.pagecontent : '' }} />
                           </div>
                        </div>
                        {/* <div className="row">
                           <div className="col-sm-7 col-md-8 col-lg-7 ml-sm-auto">
                              <h3 className="heading">{data?.status ? data?.data[3]?.title : ''}</h3>
                              <p dangerouslySetInnerHTML={{ __html: data?.status ? data?.data[3]?.pagecontent : '' }} />
                           </div>
                        </div> */}
                     </div>
                  </div>
                  <div className="col-lg-6 d-flex">
                     <div className="futureCnt bluCnt w-100">
                        <Parallax speed={3}>
                           <img src={referEarn} width="143" height="125" alt="futureBlockchainIcon" />
                        </Parallax>
                        <div className="d-flex">
                           <div className="futureInnerCnt ml-auto">
                              <h3 className="heading">{data?.status ? data?.data[4]?.title : ''}</h3>
                              <p dangerouslySetInnerHTML={{ __html: data?.status ? data?.data[4]?.pagecontent : '' }} />
                           </div>
                        </div>
                        {/* <div className="row">
                           <div className="col-sm-7 col-md-8 col-lg-7 ml-sm-auto">
                              <h3 className="heading">{data?.status ? data?.data[4]?.title : ''}</h3>
                              <p dangerouslySetInnerHTML={{ __html: data?.status ? data?.data[4]?.pagecontent : '' }} />
                           </div>
                        </div> */}
                     </div>
                  </div>
                  <div className="col-lg-6 d-flex">
                     <div className="futureCnt ylwCnt w-100">
                        <Parallax speed={3}>
                           <img src={staking} width="143" height="125" alt="futureEasyIcon" />
                        </Parallax>
                        <div className="d-flex">
                           <div className="futureInnerCnt ml-auto">
                              <h3 className="heading">{data?.status ? data?.data[5]?.title : ''}</h3>
                              <p dangerouslySetInnerHTML={{ __html: data?.status ? data?.data[5]?.pagecontent : '' }} />
                           </div>
                        </div>                         
                        {/* <div className="row">
                           <div className="col-sm-7 col-md-8 col-lg-7 ml-sm-auto">
                              <h3 className="heading">{data?.status ? data?.data[5]?.title : ''}</h3>
                              <p dangerouslySetInnerHTML={{ __html: data?.status ? data?.data[5]?.pagecontent : '' }} />
                           </div>
                        </div> */}
                     </div>
                  </div>
               </div>
            </div>
            <div className="abtBd">

            </div>
         </div>
      </div>
   )
}
About.propTypes ={
   data: PropTypes.shape({
         status: PropTypes.any,
         data: PropTypes.arrayOf(
            PropTypes.shape({
               title: PropTypes.any,
               pagecontent : PropTypes.any
            })
        ),
    }), 
}
export default About;