import "../../assets/scss/Innerpages/Innerpages.scss";
import userAV from "./userAV.webp";
import verifiedBadge from "../../assets/images/verifiedBadge.png";
import editIcon from "../../assets/images/editIcon.svg";
import ChangePassword from "./tabs/changePassword";
import ChangeMPIN from "./tabs/changeMPIN";
import Converter from "../../lib/converter";
import { useEffect, useState } from "react";
import { useToast } from "../../hook/usetoast";
import { useUpdateProfile } from "./api/fetchprofile";
import { useModalContext } from "../../contexts/ModalContext";
import Loader from "../../Components/Loader/Loader";
import { WaitingLoader } from "../../Components/Loader/buttonLoad";
import Cookies from "js-cookie"

const Profile = () => {
   const toast = useToast();
   const [disable, setDisabled] = useState(false);
   const [file, setFile] = useState('');

   const { profileData, isLoading } = useModalContext();
   const changeProfilemutation = useUpdateProfile();

   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

   const readFileAsync = (file) => {

      return new Promise(
         (resolve, reject) => {
            if (!file) return
            const reader1 = new FileReader()
            reader1.onload = () => {
               resolve(`data:${file.type};base64,${btoa(reader1.result)}`)
            }
            reader1.onerror = reject
            reader1.readAsBinaryString(
               file
            )
         }
      )
   }

   const sizeChk = (event) => {
      let img = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
         const imgCheck = new Image();
         imgCheck.src = event.target.result;
         imgCheck.onload = async () => {
            if (imgCheck.width <= 6000 && imgCheck.height <= 6000) {

               if (img.size < 2 * 1024 * 1024) {
                  setDisabled(true)
                  const newImage1 = await readFileAsync(img)
                  const formData = new FormData()
                  formData.append('image', img)

                  const res = await changeProfilemutation.mutateAsync(formData)
                  if (res.status) {
                     setFile(newImage1)
                  }
                  setDisabled(false)
               } else {
                  toast.error("Image Size Must be Less than 2MB.");
               }
            } else {
               toast.error("Image Dimensions should be 6000 x 6000 Pixels.");
            }
         };
         imgCheck.onerror = (error) => {
            
         };
      }
      reader.onerror = (error) => {
    
      };
      reader.readAsDataURL(img);
   }

   return (
      <>
         <Loader loaded={isLoading} />
         {disable && <WaitingLoader />}
         <div className="container animated_div">
            <h3 className="secHeading text-center">Profile</h3>
            <div className="row">
               <div className="col-12">
                  <div className="card">
                     <div className="cardInnerCnt">
                        <div className="cardHeader">
                           <h3>Profile details</h3>
                        </div>
                        <div className="d-flex justify-content-center justify-content-md-start align-items-start align-items-lg-center flex-wrap flex-md-nowrap">
                           <div className="profPicEdit mr-5">
                              <img src={file || profileData?.profileImage || userAV} width="120" height="120" alt="profilePic" className="profilePic" />
                              {sessionStorage.getItem('type') != 'admin' && !Cookies.get('sdgsdsadgt') ? <div className="editIcon">
                                 <div className="prf-uppBtn">
                                    <img src={editIcon} width="18" height="18" alt="editIcon" />
                                     <input
                                       type="file"
                                       disabled={disable}
                                       name="profileimg"
                                       accept=".png,.jpg,.jpeg"
                                       onChange={async (e) => {
                                          try {
                                             let img = e.target.files[0]
                                             const reader = new FileReader();

                                             reader.onload = (event) => {
                                                const bytes = new Uint8Array(event.target.result);
                                                if (bytes[0] === 0x89 && bytes[1] === 0x50 || bytes[0] === 0xFF && bytes[1] === 0xD8) { 
                                                   sizeChk(e)
                                                } else {
                                                   toast.error('Please select image file')
                                                }
                                             };

                                             reader.onerror = (error) => {
                                                
                                             };
                                             reader.readAsArrayBuffer(img);
                                          } catch (e) { }
                                       }}
                                    />
                                 </div>
                              </div>: ''}
                           </div>
                           <div className="d-flex align-items-center flex-wrap">
                              <div className="editProfValueCnt d-flex align-items-center my-2">
                                 <div>
                                    <h5 className="cardLabel mb-2">Name</h5>
                                    <h3 className="cardValue">{profileData?.name || "---"}</h3>
                                 </div>
                              </div>
                              <div className="editProfValueCnt d-flex align-items-center my-2">
                                 <div>
                                    <h5 className="cardLabel mb-2">Email ID</h5>
                                    <h3 className="cardValue truncate">
                                       <span className="CVp">{profileData?.email || "---"}</span>
                                       <img src={verifiedBadge} width="20" height="20" alt="verifiedBadge" className="ml-1" />
                                    </h3>
                                 </div>
                              </div>
                              <div className="editProfValueCnt d-flex align-items-center my-2">
                                 <div>
                                    <h5 className="cardLabel mb-2">Mobile Number</h5>
                                    <h3 className="cardValue">
                                       +{(profileData?.dial_code)?.substring(1) || "-"} {(profileData?.mobile)?.split((profileData?.dial_code)?.substring(1))[1] || "--"}
                                       <img src={verifiedBadge} width="20" height="20" alt="verifiedBadge" className="ml-1" />
                                    </h3>
                                 </div>
                              </div>
                              <div className="editProfValueCnt d-flex align-items-center my-2">
                                 <div>
                                    <h5 className="cardLabel mb-2">Date of Birth</h5>
                                    <h3 className="cardValue">{Converter.formatDateandMonth(profileData?.DOB) || "---"}</h3>
                                 </div>
                              </div>
                              <div className="editProfValueCnt d-flex align-items-center my-2">
                                 <div>
                                    <h5 className="cardLabel mb-2">Country</h5>
                                    <h3 className="cardValue">{profileData?.country || "---"}</h3>
                                 </div>
                              </div>
                              <div className="editProfValueCnt d-flex align-items-center my-2">
                                 <div>
                                    <h5 className="cardLabel mb-2">Member ID</h5>
                                    <h3 className="cardValue">{profileData?.memberID || "---"}</h3>
                                 </div>
                              </div>
                           </div>
                        </div>


                     </div>
                  </div>
               </div>
            </div>
            <ChangePassword />
            <ChangeMPIN />
         </div>
      </>
   )
}
export default Profile;