import { BrowserRouter as Router } from 'react-router-dom'
import "./assets/scss/common.scss";
import 'locomotive-scroll/dist/locomotive-scroll.min.css';
import { queryClient } from "./config";
import { QueryClientProvider } from "react-query";
import { Toaster } from 'react-hot-toast';
import { AppRoutes } from './routes';
import { AnimatePresence } from 'framer-motion';
import { ModalContextProvider } from './contexts/ModalContext';
import { ParallaxProvider } from "react-scroll-parallax";

function App() {
  return (
    <ParallaxProvider>
      <AnimatePresence>
        <QueryClientProvider client={queryClient}>
          <ModalContextProvider>
            <Router>
              <AppRoutes />
            </Router>
          </ModalContextProvider>
          <Toaster position='top-center' />
        </QueryClientProvider>
      </AnimatePresence>
    </ParallaxProvider>
  );
}

export default App;
