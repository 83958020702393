import React, { useState } from 'react'
import { HistorySkeleton } from '../../../Components/Loader/skeleton'
import { NotFound } from '../../Nodata/Nodatafound'
import Converter from '../../../lib/converter'
import { useDashboard } from '../api/fetchDashboard'
import Pagination from '../../common/pagination/Pagination'
import { networkSymbols } from '../../../lib/network'
import { transactionurl } from '../../../lib/transactionurl'
import { useToast } from '../../../hook/usetoast'
import { Copy, Check, ExternalLink } from 'lucide-react';
import { copyToClipboard } from '../../../lib/copyToClipboard'
function TransactionHistory() {

    const [page, setPage] = useState(1);
    const { data: UserTransaction, isLoading } = useDashboard({ page: page, size: 10 })

    const [done, setDone] = useState(false)

    const toast = useToast()
    const copyuserAddress = async data => {
        setDone(data._id)
        await copyToClipboard(data.cryptoAddress)
        toast.success("Address copied")
        setTimeout(() => {
            setDone()
        }, 800)
    }


    return (
            <div className="container animated_div">
                <h3 className="secHeading text-center">Transaction History</h3>
                <div className="row">
                    <div className="col-12">
                        <div className="card tableCard">
                            <div className="cardInnerCnt">
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Date & Time</th>
                                                <th>Type</th>
                                                <th>Address</th>
                                                <th>Amount</th>
                                                <th>Transaction ID</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ?
                                                <HistorySkeleton />
                                                :
                                                UserTransaction?.length > 0 ?
                                                    UserTransaction?.slice(0, 8).map((n, i) => (
                                                        <tr key={n}>
                                                            <td>{page == 1
                                                                ? i + 1
                                                                : (page - 1) * 10 +
                                                                (i + 1)}</td>
                                                            <td>{Converter.formatDated(n.updatedAt)}</td>
                                                            <td>{n.type}</td>
                                                            <td>{n.cryptoAddress ? n.cryptoAddress?.slice(0, 10) + "..." + n.cryptoAddress?.slice(40, 45) : "-"}
                                                                {done == n._id ?
                                                                    <Check size={20} className="ml-3" />
                                                                    :
                                                                    <Copy onClick={() => copyuserAddress(n)} size={20} className='ml-3' style={{ cursor: "pointer" }} />
                                                                }
                                                            </td>
                                                            <td>{n.amount} {n.currencySymbol} {n?.currencySymbol === 'USDT' && networkSymbols[n?.network]}</td>
                                                            <td style={{ color: "white" }}>{n.transactionHash ?
                                                                n.transactionHash?.slice(0, 10) + "..." + n.transactionHash?.slice(40, 45) : "-"}
                                                                <a href={transactionurl[n.network] + n.transactionHash} target="_blank" style={{ color: "white" }} className='ml-2'>  <ExternalLink size={20} /> </a>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <tr>
                                                        <td colSpan={6}><NotFound message="No Data Found" /></td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Pagination
                            total={UserTransaction?.count}
                            current={page}
                            size={10}
                            onPageChange={no => setPage(no)}
                        />
                    </div>
                </div>
            </div>
    )
}

export default TransactionHistory