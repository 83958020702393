import { Fragment, useEffect, useState } from "react";
import { useSendOTP, useVerifyOTP } from "../api/authmutations";
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ButtonLoader } from "../../../Components/Loader/buttonLoad";
import { Dots } from "../../../lib/dots";
import { CheckCircle } from "lucide-react";
import { Timer } from "../../../lib/expireTime";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { useModalContext } from "../../../contexts/ModalContext";
import PropTypes from 'prop-types';
import { generateSecureRandomNumber } from "../../../hook/generateSecureRandomNumber";

const schema = yup.object().shape({
    octPhone: yup.string()
        .min(10, "Mobile number invalid")
        .required('Mobile number is Required'),
    // otp: yup.string(),
})

const MobileVerification = ({ setValue, ismailVerified, email,selectedcountry }) => {

    const [phoneOTP, setPhoneOTP] = useState("");
    const [isPhoneVerified, setIsPhoneVerified] = useState(false);
    const [mobileComplete, setMobileComplete] = useState(false);
    const [waitingTime, setWaitingTime] = useState(false);
    const { setCountryuser ,setCountryverified} = useModalContext();
    const [resDialCode , setResDialCode] = useState('')
    const sendmutation = useSendOTP()
    const verifyOTPmutation = useVerifyOTP()

    const { control, handleSubmit, formState: { errors }, getValues, clearErrors, setError } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            octEmail: "",
            // otp: ""
        }
    });

    useEffect(() => {
        let interval = setTimeout(() => {
            setWaitingTime(false);
        }, 60000);

        return () => {
            clearInterval(interval);
        };
    }, [waitingTime])

    const handleKeyDown = e => e.target.value = e.target.value.match(/^[0-9]{0,6}/)[0]

    const sendPhoneOTP = async (data) => {
        let random = Math.floor(generateSecureRandomNumber() * 10000)
        sessionStorage.setItem('random', random)
        const phoneNum = (data.octPhone).split(resDialCode)[1] 
        let obj = {
            "email": email,
            "type": "MobileNumber",
            "mobile": phoneNum,
            "random": random
        }
        let res = await sendmutation.mutateAsync(obj)
        if (res?.status) {
            setMobileComplete(true)
            setWaitingTime(true)
        }
    }

    const verifyphoneOTP = async (e) => {
        if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
        let obj = {
            "type": "MobileNumber",
            "mobile_OTP": phoneOTP,
            "mobile": (getValues("octPhone")).split(resDialCode)[1]
        }

        clearErrors("otp")
        if (!phoneOTP) {
            return setError("otp", { message: "OTP is required" })
        } else if (phoneOTP.length !== 6) {
            return setError("otp", { message: "Invalid OTP" })
        }

        if (!getValues("octPhone")) {
            return setError("octPhone", { message: "Mobile number is Required" })
        }

        let res = await verifyOTPmutation.mutateAsync(obj)        
        if (res?.status) {
            setCountryverified(true)
            setIsPhoneVerified(true);
            setValue("mobile", getValues("octPhone"))
        }else setPhoneOTP('')
    }
    }

    return (
        <Fragment>
            <div className="col-lg-6">
                <div className="form-group">
                    <label className="formLabel" htmlFor="regId5">Mobile</label>
                    <Controller name='octPhone' control={control}
                        render={({ field: { onChange, ref, ...field } }) => (
                            <div className='nji_ki mb-3'>
                                <PhoneInput
                                    {...field}
                                    inputExtraProps={{
                                        ref,
                                        required: false,
                                        autoFocus: false,
                                    }}
                                    buttonclassName="phnDrpBtn"
                                    dropdownclassName="phnDrp"
                                    value={field.value ?? ""}
                                    specialLabel={''}
                                    placeholder='Enter phone number'
                                    countryCodeEditable={false}
                                    disableDropdown
                                    onChange={(value, countryObj) => {
                                        onChange(value)
                                        setValue("dialCode", countryObj.dialCode)
                                        setResDialCode(countryObj.dialCode)
                                        setValue("country", (countryObj.countryCode).toUpperCase())
                                        setCountryuser(countryObj?.countryCode.toUpperCase())
                                        setValue("mobile",getValues('octPhone'))
                                    }}
                                    country={selectedcountry?.toLocaleLowerCase() || "in"}                     
                                    disabled={isPhoneVerified || !ismailVerified}
                                />
                                {/* <span className='nji_kiTxt'>
                                    {isPhoneVerified ?
                                        <span className="yellowTxt">
                                            <CheckCircle size={20} color="#05af05" />
                                        </span>
                                        :
                                        sendmutation.isLoading ?
                                            <span className="yellowTxt">
                                                <Dots>Sending</Dots>
                                            </span>
                                            :
                                            waitingTime ?
                                                <span className="yellowTxt">
                                                    <Timer />
                                                </span>
                                                :
                                                <span className="yellowTxt" type="submit" onClick={handleSubmit(sendPhoneOTP)} onKeyDown={handleSubmit(sendPhoneOTP)}>
                                                    {mobileComplete ? "Re-send" : "Send OTP"}
                                                </span>
                                    }
                                </span> */}
                            </div>
                        )} />

                    {errors.octPhone && <p className="errMsg">* {errors.octPhone.message}</p>}
                </div>
            </div>
            {/* <div className="col-lg-6">
                <div className="form-group">
                    <label className="formLabel" htmlFor="phoneOTP">OTP send to Mobile number</label>
                    <div className="input-group mb-3">
                        <input type="tel" className="form-control"
                            maxLength={6}
                            value={phoneOTP}
                            disabled={!mobileComplete || isPhoneVerified}
                            onChange={(e) => setPhoneOTP(e.target.value)}
                            onInput={handleKeyDown}
                            id="phoneOTP" placeholder="Enter your OTP"
                        />
                        <div className="input-group-append">
                            {isPhoneVerified ?
                                <span className="input-group-text grnTxt">
                                    Verified
                                </span>
                                :
                                <span className="input-group-text yellowTxt" type="button"
                                    disabled={verifyOTPmutation.isLoading}
                                    onClick={(e)=>verifyphoneOTP(e)} onKeyDown={(e)=>verifyphoneOTP(e)}>
                                    {verifyOTPmutation.isLoading ?
                                        <ButtonLoader />
                                        :
                                        "Verify"}
                                </span>
                            }
                        </div>
                    </div>
                    {errors.otp && <p className="errMsg">* {errors.otp.message}</p>}
                </div>
            </div> */}
        </Fragment>
    )

}
MobileVerification.propTypes = {
    setValue : PropTypes.any,
    ismailVerified : PropTypes.any,
    email : PropTypes.any,
    selectedcountry : PropTypes.any

}

export default MobileVerification;