import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import "../../assets/scss/Innerpages/Innerpages.scss";
import logoLg from "../../assets/images/logoLg.png";
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import EyeSlashIcon from '../../assets/images/Auth/eye-slash.png'
import EyeIcon from '../../assets/images/Auth/eye.png'
import { useBalance } from '../common/fetchBalance';
import { BounceInDownButton } from '../../Components/animation/bouncein';
import { useRedeemMove } from './api/fetchredeem';
import { useModalContext } from '../../contexts/ModalContext';
import { CoinListLoader } from '../../Components/Loader/skeleton';
import { Submitbutton } from '../common/Submitbutton';
import { useGetCurrentPhase } from '../StakeStore/api/fetchstakestore';
import Converter from '../../lib/converter';
import { useTransferFee } from '../Transfer/api/fetchTransfer';
import { useDebounce } from '../../hook/useDounce';
import { formatAmount } from '../../lib/formatAmount';
import { ButtonLoader } from '../../Components/Loader/buttonLoad';
import { useToast } from '../../hook/usetoast';
import { generateSecureRandomNumber } from '../../hook/generateSecureRandomNumber';



const RedeemStore = () => {

   const { data: getBalance, isLoading } = useBalance();

   const schema = yup.object().shape({
      type: yup.string()
         .required('Select redeem type'),
      MPIN: yup.string()
         .required('MPIN is Required')
         .matches(/^[0-9]{6}$/, 'MPIN must be 6 digits'),
      walletAddress: yup.string()
         .when(["type"], (val, schema) => {
            if (val[0] === "Withdraw")
               return yup.string()
                  .required('Wallet Address is required');
            else return yup.string().notRequired()
         }),
      amount: yup.string()
         .when(["type"], (val, schema) => {
            if (val[0] === "Withdraw")
               return yup.number()
                  .typeError('Amount is Required')
                  .required('Amount is required').positive('Amount must be in positive values').min(1, 'Minimum amount must be 1')
                  .test('not-zero', 'The price should be at least Zero above', (value) => value > 0)
                  .test('max-decimal-places', 'The amount must have at most 6 decimal places', value => {
                     if (value) {
                        const numString = formatAmount(value.toString());
                        const [, decimal] = numString.includes('.') ? numString.split('.') : [numString, ''];
                        return !decimal || decimal.length >= 6;
                     }
                     return true;
                  })
                  .test(
                     'greater-than',
                     'Insufficient balance',
                     function (value) {
                        if (getBalance && getBalance?.redeemstoreAvailable !== undefined) {
                           return +value <= getBalance?.redeemstoreAvailable;
                        }
                        return true;
                     }
                  );
            else if (val[0] === "Transfer")
               return yup.number()
                  .typeError('Amount is Required')
                  .required('Amount is required').positive('Amount must be in positive values').min(1, 'Minimum amount must be 1')
                  .test('not-zero', 'The price should be at least Zero above', (value) => value > 0).test('max-decimal-places', 'The amount must have at most 6 decimal places', value => {
                     if (value) {
                        const numString = formatAmount(value.toString());
                        const [, decimal] = numString.includes('.') ? numString.split('.') : [numString, ''];
                        return !decimal || decimal.length >= 6;
                     }
                     return true;
                  })
                  .test(
                     'greater-than',
                     'Insufficient balance',
                     function (value) {
                        if (getBalance && getBalance?.redeemstoreAvailable !== undefined) {
                           return +value <= getBalance?.redeemstoreAvailable;
                        }
                        return true;
                     }
                  );
            else if (val[0] === "stake")
               return yup.number()
                  .typeError('Amount is Required')
                  .required('Amount is required')
                  .positive('Amount must be in positive values').min(1, 'Minimum amount must be 1').min(1, 'Minimum amount must be 1')
                  .test('not-zero', 'The price should be at least Zero above', (value) => value > 0).test('max-decimal-places', 'The amount must have at most 6 decimal places', value => {
                     if (value) {
                        const numString = formatAmount(value.toString());
                        const [, decimal] = numString.includes('.') ? numString.split('.') : [numString, ''];
                        return !decimal || decimal.length >= 6;
                     }
                     return true;
                  })
                  .test(
                     'greater-than',
                     'Insufficient balance',
                     function (value) {
                        if (getBalance && getBalance?.redeemstoreAvailable !== undefined) {
                           return +value <= getBalance?.redeemstoreAvailable;
                        }
                        return true;
                     }
                  );
            else return yup.string().notRequired()
         }),
      memberID: yup.string()
         .when(["type"], (val, schema) => {
            if (val[0] === "Transfer")
               return yup.string()
                  .required('Member ID is required');
            else return yup.string().notRequired()
         }),
   })
   const { siteSettings } = useModalContext();
   const redeemMutation = useRedeemMove();
   const { data: currentPhasemutation } = useGetCurrentPhase()
   const [amount, setAmount] = useState(0);
   const [feeAmount, setFeeAmount] = useState(0);
   const [selected, setselected] = useState();
   const [fee, setFee] = useState(0)
   const amountVal = useDebounce(feeAmount)
   const transferFeeMutation = useTransferFee()

   useEffect(() => {
      getFee();
   }, [selected, amountVal])

   const getFee = async () => {

      const response = await transferFeeMutation.mutateAsync({ amount: amountVal, type: selected === "Withdraw" ? "withdrawnFee" : selected === "stake" ? "stakeRedeem" : "transferFee" })

      if (response?.status) {
         setFee(response?.data?.fee)
         let value = Number(response?.data?.fee) / 100;
         value = value * Number(amountVal)
         if (selected === "Withdraw" || selected === "stake") {
            value = Number(amountVal) - value
            setAmount(value)

         } else {
            value = Number(amountVal) + value
            setAmount(value)

         }
      }
   }

   const [dropdownOpen, setDropdownOpen] = useState(false);
   const toggle = () => setDropdownOpen((prevState) => !prevState);

   const [show, setShow] = useState(true);
   const toggleShow = (e) => {
      if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse") {
         setShow(!show)
      }
   }

   const { control, handleSubmit, formState: { errors }, reset, clearErrors, watch, setValue, getValues } = useForm({
      mode: 'onChange',
      resolver: yupResolver(schema),
      defaultValues: {
         type: "",
         MPIN: "",
         walletAddress: '',
         amount: "",
         memberID: "",
      }
   });

   const watchFields = watch("type")
   const toast = useToast()

   const handleKeyDown = e => e.target.value = e.target.value.match(/^[0-9]{0,6}/)[0]

   let x = Math.floor(generateSecureRandomNumber() * 90000) + 10000;
   const onsubmit = async (data) => {
      try {
         if (getBalance?.redeemstoreAvailable < data?.amount) {
            toast.error("Insufficient Balance")
         } else {
            data["amount"] = (data.amount).toString()
            data["randomId"] = x.toString();
            data["phase"] = currentPhasemutation?.phase;
            data["typeFee"] = selected === "Withdraw" ? "withdrawnFee" : selected === "stake" ? "stakeRedeem" : "transferFee"
            await redeemMutation.mutateAsync(data);
            reset()
         }
      } catch (error) {

      }
   }

   return (
      <div className="container animated_div">
         <h3 className="secHeading text-center">Redeem Store</h3>
         <div className="row justify-content-center">
            <div className="col-lg-10">
               <div className="row">
                  <div className="col-lg-4 col-xl-3">
                     <div className="card p-3 w-100">
                        <div className="cardInnerCnt">
                           <h3 className="cardHeading text-center">Redeem Vault</h3>
                           <div className="goldCnt">
                              <h5 className="cardLabel mb-0">Total available</h5>
                              <h3 className="cardValue mb-0 d-flex align-items-center">
                                 <img src={logoLg} width="22" height="22" alt="logoLg" className="mr-2" />
                                 {isLoading ?
                                    <ButtonLoader /> : getBalance?.redeemstoreAvailable > 0 ?
                                       Converter.formattedValue(getBalance?.redeemstoreAvailable) + " ANA" : getBalance?.redeemstoreAvailable == 0 ? "0 ANA" : ""
                                 }
                              </h3>
                           </div>
                           {isLoading ?
                              <CoinListLoader />
                              :
                              <div className="coinInfoCnt unHgt">
                                 <div className="coinInfoRow d-flex align-items-start my-2">
                                    <div className="d-flex flex-column">
                                       <h5 className="cardLabel mb-0">Total Received</h5>
                                       <h5 className="cardLabel whiteTxt mb-0">
                                          {getBalance?.redeemStore > 0 ? Converter.formattedValue(getBalance?.redeemStore) + " ANA" : getBalance?.redeemStore == 0 ? "0 ANA" : ""}</h5>
                                    </div>
                                 </div>
                                 <hr className="my-1" />
                                 <div className="coinInfoRow d-flex align-items-start my-2">
                                    <div className="d-flex flex-column">
                                       <h5 className="cardLabel mb-0">Total Withdraw</h5>
                                       <h5 className="cardLabel whiteTxt mb-0">
                                          {getBalance?.redeemStoreWithdraw > 0 ? Converter.formattedValue(getBalance?.redeemStoreWithdraw) + " ANA" : getBalance?.redeemStoreWithdraw == 0 ? "0 ANA" : ""}</h5>
                                    </div>
                                 </div>
                                 <hr className="my-1" />
                                 <div className="coinInfoRow d-flex align-items-start my-2">
                                    <div className="d-flex flex-column">
                                       <h5 className="cardLabel mb-0">Moved to Stake</h5>
                                       <h5 className="cardLabel whiteTxt mb-0">
                                          {getBalance?.redeemStorestake > 0 ? Converter.formattedValue(getBalance?.redeemStorestake) + " ANA" : getBalance?.redeemStorestake == 0 ? "0 ANA" : ""}</h5>
                                    </div>
                                 </div>
                                 <hr className="my-1" />
                                 <div className="coinInfoRow d-flex align-items-start my-2">
                                    <div className="d-flex flex-column">
                                       <h5 className="cardLabel mb-0">Total Transfer</h5>
                                       <h5 className="cardLabel whiteTxt mb-0">
                                          {getBalance?.redeemStoreTransfer > 0 ? Converter.formattedValue(getBalance?.redeemStoreTransfer) + " ANA" : getBalance?.redeemStoreTransfer == 0 ? "0 ANA" : ""}</h5>
                                    </div>
                                 </div>
                              </div>
                           }
                        </div>
                     </div>
                  </div>

                  <div className="col-lg-8 col-xl-9">
                     <BounceInDownButton>
                        <div className="card walletCard">
                           <div className="px-3 cardInnerCnt">
                              <div className="cardHeader">
                                 <div className="d-flex flex-column flex-sm-row align-items-center">
                                    <h3>Redeem Amount</h3>
                                    <div className="d-flex ml-sm-auto">
                                       <Link to="/redeem-history" className="outlinedBtn m-1">History</Link>
                                    </div>
                                 </div>
                              </div>

                              <form onSubmit={handleSubmit(onsubmit)}>
                                 <div className="form-group">
                                    <label className="formLabel" htmlFor="Id1">Select</label>
                                    <div className='octDrpdown w-100'>
                                       <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                          <DropdownToggle caret>{getValues("type") == 'stake' ? 'Stake' : getValues("type") || "Select"}</DropdownToggle>
                                          <DropdownMenu>
                                             <DropdownItem onClick={() => {
                                                clearErrors();
                                                setValue("type", "Withdraw")
                                                setselected("Withdraw")
                                             }}>
                                                Withdraw
                                             </DropdownItem>
                                             <DropdownItem onClick={() => {
                                                clearErrors();
                                                setValue("type", "stake")
                                                setselected("stake")

                                             }}>
                                                Stake Vault
                                             </DropdownItem>
                                             <DropdownItem onClick={() => {
                                                clearErrors();
                                                setValue("type", "Transfer")
                                                setselected("Transfer")

                                             }}>
                                                Transfer Vault
                                             </DropdownItem>
                                          </DropdownMenu>
                                       </Dropdown>
                                    </div>
                                 </div>
                                 {!watchFields && errors.type && <p className="errMsg">* {errors.type.message}</p>}

                                 {getValues("type") === "Withdraw" ?
                                    <>
                                       <div className="form-group">
                                          <label className="formLabel" htmlFor="loginId2">Wallet Address</label>
                                          <Controller name='walletAddress' control={control} render={({ field }) => (
                                             <input
                                                {...field}
                                                type="text"
                                                className={`form-control  ${errors.walletAddress && "is-invalid"}`}
                                                placeholder="Enter your wallet address"
                                                autoComplete='off'
                                             />
                                          )} />
                                          {errors.walletAddress && <p className="errMsg">* {errors.walletAddress.message}</p>}
                                       </div>
                                       <div className="form-group">
                                          <label className="formLabel" htmlFor="loginId2">Amount</label>
                                          <Controller name='amount' control={control} render={({ field }) => (
                                             <input
                                                {...field}
                                                type="number"
                                                onChange={(e) => {
                                                   field.onChange(e.target.value);
                                                   setFeeAmount(e.target.value);
                                                   const value = e.target.value;
                                                   const regex = /^\d*(\.\d{0,6})?$/;
                                                   if (regex.test(value) || value === "") {
                                                      field.onChange(value);
                                                   }
                                                }}
                                                onWheel={(e) => e.target.blur()}
                                                className={`form-control  ${errors.amount && "is-invalid"}`}
                                                placeholder="Enter your wallet amount"
                                                autoComplete='off'
                                                onKeyDown={(e) => {
                                                   if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                                                      e.preventDefault();
                                                   }
                                                }}

                                             />
                                          )} />
                                          {errors.amount && <p className="errMsg">* {errors.amount.message}</p>}
                                       </div>
                                    </>
                                    :
                                    getValues("type") === "stake" ?
                                       <div className="form-group">
                                          <label className="formLabel" htmlFor="loginId2">Send Amount</label>
                                          <Controller name='amount' control={control} render={({ field }) => (
                                             <input
                                                {...field}
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                onChange={(e) => {
                                                   field.onChange(e.target.value);
                                                   setFeeAmount(e.target.value);
                                                   const value = e.target.value;
                                                   const regex = /^\d*(\.\d{0,6})?$/;
                                                   if (regex.test(value) || value === "") {
                                                      field.onChange(value);
                                                   }
                                                }}
                                                className={`form-control  ${errors.amount && "is-invalid"}`}
                                                placeholder="Enter your amount"
                                                autoComplete='off'
                                                onKeyDown={(e) => {
                                                   if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                                                      e.preventDefault();
                                                   }
                                                }}
                                             />
                                          )} />
                                          {errors.amount && <p className="errMsg">* {errors.amount.message}</p>}
                                       </div>
                                       :
                                       getValues("type") === "Transfer" ?
                                          <>
                                             <div className="form-group">
                                                <label className="formLabel" htmlFor="loginId2">Member ID</label>
                                                <Controller name='memberID' control={control} render={({ field }) => (
                                                   <input
                                                      {...field}
                                                      type="text"
                                                      className={`form-control  ${errors.memberID && "is-invalid"}`}
                                                      placeholder="Enter Your Member ID"
                                                      autoComplete='off'
                                                   />
                                                )} />
                                                {errors.memberID && <p className="errMsg">* {errors.memberID.message}</p>}
                                             </div>
                                             <div className="form-group">
                                                <label className="formLabel" htmlFor="loginId2">Send Amount</label>
                                                <Controller name='amount' control={control} render={({ field }) => (
                                                   <input
                                                      {...field}
                                                      type="number"
                                                      onChange={(e) => {
                                                         field.onChange(e.target.value);
                                                         setFeeAmount(e.target.value);
                                                         const value = e.target.value;
                                                         const regex = /^\d*(\.\d{0,6})?$/;
                                                         if (regex.test(value) || value === "") {
                                                            field.onChange(value);
                                                         }
                                                      }}
                                                      onWheel={(e) => e.target.blur()}
                                                      onKeyDown={(e) => {
                                                         if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                                                            e.preventDefault();
                                                         }
                                                      }}
                                                      className={`form-control  ${errors.amount && "is-invalid"}`}
                                                      placeholder="Enter your amount"
                                                      autoComplete='off'
                                                   />
                                                )} />
                                                {errors.amount && <p className="errMsg">* {errors.amount.message}</p>}
                                             </div>
                                          </>
                                          :
                                          null
                                 }



                                 <div className="form-group">
                                    <label className="formLabel" htmlFor="loginId2">MPIN</label>
                                    <div className="input-group mb-3">
                                       <Controller name='MPIN' control={control} render={({ field }) => (
                                          <input
                                             {...field}
                                             type={show ? "text" : "password"}
                                             className={`form-control  ${errors.MPIN && "is-invalid"}`}
                                             placeholder="Enter Your MPIN"
                                             maxLength={6}
                                             onInput={handleKeyDown}
                                             autoComplete='off'
                                          />
                                       )} />
                                       <div className="input-group-append">
                                          <span className="input-group-text" type="button">
                                             {show ?
                                                <img src={EyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" onClick={(e) => toggleShow(e)} onKeyDown={(e) => toggleShow(e)} />
                                                :
                                                <img src={EyeIcon} width="20" height="20" alt="eyeSlashIcon" onClick={(e) => toggleShow(e)} onKeyDown={(e) => toggleShow(e)} />
                                             }
                                          </span>
                                       </div>
                                    </div>
                                    {errors.MPIN && <p className="errMsg">* {errors.MPIN.message}</p>}
                                 </div>

                                 <div className="d-flex align-items-center">
                                    <h5 className="cardLabel my-1">Fees</h5>
                                    <h5 className="cardLabel whiteTxt my-1 ml-sm-auto">
                                       {fee || 0} %
                                    </h5>
                                 </div>
                                 <div className="d-flex align-items-center">
                                    <h5 className="cardLabel my-1">Total Amount</h5>
                                    <h5 className="cardLabel whiteTxt my-1 ml-sm-auto">{amount} ANA</h5>
                                 </div>

                                 <div className="d-flex justify-content-center">
                                    <Submitbutton
                                       buttonText="Confirm"
                                       loading={redeemMutation.isLoading}
                                       Status={siteSettings?.redeemStatus}
                                       message="Redeem option disabled. Users can't redeem temporarily"
                                    />
                                 </div>
                              </form>

                           </div>
                        </div>
                     </BounceInDownButton>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}
export default RedeemStore;