import { useEffect, useState } from "react";
import PropTypes from 'prop-types';

export const Timer = () => {
    const [sec, setSec] = useState(0);
    let temp = new Date();
    temp = temp.setSeconds(temp.getSeconds() + 60);

    useEffect(() => {
        const intervalId = setInterval(() => {
            var Enddate = new Date(temp).getTime();
            var countDownDate = Enddate;
            var now = new Date().getTime();
            var timeleft = countDownDate - now;
            var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

            if (timeleft > 0) {
                setSec(seconds);
            }
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const value = "00 :" + sec
    return value
};

export const RemainingTime = ({ temp }) => {
    const [days, setDays] = useState(0);

    useEffect(() => {
        var countDownDate = new Date(temp).getTime();
        
        var now = new Date().getTime();
        
        var timeleft = countDownDate - now;
        var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));

        if (timeleft > 0) {
            setDays(days);
        }
    }, []);

    return <>{days}</>;
};

RemainingTime.propTypes = {
    temp : PropTypes.any,
}

// export const RemainingTime = ({ temp }) => {
//     const [days, setDays] = useState(0);

//     useEffect(() => {
//         let date = new Date();
//         // date.setHours(0, 0, 0, 0);

//         let enddate = new Date(temp);
//         // enddate.setHours(0, 0, 0, 0);
//         let difference = (enddate - date) / (1000 * 60 * 60 * 24); 

//         if (difference > 0) {
//             setDays(difference);
//         }else{
//             setDays(0)
//         }
//     }, []);

//     return <>{days}</>;
// };


export const RewardsEarned = ({ period, temp, staked, yeild }) => {

    const [perDay, setperDay] = useState(0);

    useEffect(() => {
        var countDownDate = new Date(temp).getTime();
        var now = new Date().getTime();
        var timeleft = countDownDate - now;
        var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));

        if (timeleft > 0) {

            let value = Number(yeild) / 100 * Number(staked);
            let noofDays = period - (days + 1);
            value = Number(value) / Number(period) * noofDays;
            setperDay(value)
        }


    }, []);


    return perDay;
}

RewardsEarned.propTypes = {
    period : PropTypes.any,
    temp : PropTypes.any,
    staked : PropTypes.any,
    yeild : PropTypes.any,
}

export const CheckActive = ({ temp }) => {
    const [active, setActive] = useState(false);

    useEffect(() => {
        var countDownDate = new Date(temp).getTime();
        var now = new Date().getTime();
        var timeleft = countDownDate - now;

        if (timeleft > 0) {
            setActive(true);
        } else setActive(false)
    }, []);

    return active ?
        <h3 className="cardLabel text-success">Active</h3> : <h3 className="errMsg">Completed</h3>
};

CheckActive.propTypes = {
    temp : PropTypes.any,
}