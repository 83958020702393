import Cms from "../Layout/Cms";
import About from "../Pages/CMS/About";
import Faq from "../Pages/CMS/Faq";
import Privacy from "../Pages/CMS/Privacy"
import Terms from "../Pages/CMS/Terms"
import Readmore from '../Pages/Home/tabs/News/Readmore'

export const CmsRoutes = [
    {
        path: "/",
        element: <Cms />,
        children: [
            {
                path: "/about",
                element: <About />
            },
            {
                path: "/faq",
                element: <Faq />
            },
            {
                path: "/privacy",
                element: <Privacy />
            },
            {
                path: "/terms",
                element: <Terms />
            },
            {
                path: "/Readmore",
                element: <Readmore />
            }
        ]
    },

]