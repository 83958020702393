import Innerpages from "../Layout/Layout";
import AnaWallet from "../Pages/ANAwallet/ANAWallet";
import Dashboard from '../Pages/Dashboard';
import LoginHistory from "../Pages/Dashboard/fullHistory/loginHistory";
import Kyc from "../Pages/Kyc";

import Profile from '../Pages/Profile';
import Wallet from "../Pages/DepositeWithdraw/Wallet";
import BusinessTransfer from "../Pages/Transfer/BusinessTransfer";
import BussinessStore from "../Pages/ANAwallet/Anaico";
import TransferHistory from "../Pages/Transfer/TransferHistory";
import StakingStore from "../Pages/StakeStore/Staking";
import StakeHistory from "../Pages/StakeStore/tabs/stakeStoreHistory";
import StakingStoreDetails from "../Pages/StakeStore/StakingStoreDetails";
import ClaimHistory from "../Pages/StakeStore/tabs/claimHistory";
import RedeemStore from "../Pages/RedeemStore/redeem";
import RedeemHistory from "../Pages/RedeemStore/tabs";

import AnawalletHistory from "../Pages/ANAwallet/AnawalletHistory";
import { SuccessBuy } from "../Pages/success";
import TransactionHistory from "../Pages/Dashboard/fullHistory/transactionHistory";

import BussinessVolume from '../Pages/BussinessVolume/BusinessVolume'
import Bussinesstore from '../Pages/BusinessStore/BusinessStore'
import BusinessStoreHistory from "../Pages/BusinessStore/history/businessStoreHistory";
import ReferalHistory from "../Pages/BusinessStore/history/ReferalHistory";
import RoleHistory from "../Pages/BusinessStore/history/roleHistory"
import PoolBonusHistory from "../Pages/BusinessStore/history/PoolBonusHistory";
import PoolBonusReport from "../Pages/BusinessStore/PoolBonusReport";
import OneTimeReward from "../Pages/BusinessStore/history/oneTimeReward"
import LiquidityPoolReport from "../Pages/LiquidityPool/LiquidityPoolReport";
import LevelMemberHistory from "../Pages/BusinessStore/history/levelMemberHistory";
import Premium from '../Pages/BusinessStore/history/PremiumBusiness'
export const ProtectedRoutes = [
    {
        path: "/",
        element: <Innerpages />,
        children: [
            {
                path: "dashboard",
                element: <Dashboard />
            },
            {
                path: "login-history",
                element: <LoginHistory />
            },
            {
                path: "transaction-history",
                element: <TransactionHistory />
            },
            {
                path: "profile",
                element: <Profile />
            },
            {
                path: "kyc",
                element: <Kyc />
            },
            {
                path: "/wallet",
                element: <Wallet />
            },
            {
                path: "/withdraw",
                element: <Wallet />
            },
            {
                path: "/anawallet",
                element: <AnaWallet />
            },
            {
                path: "/anawallet-history",
                element: <AnawalletHistory />
            },
            {
                path: "/stake-store",
                element: <StakingStore />
            },
            {
                path: "/stake-history",
                element: <StakeHistory />
            },
            {
                path: "/premium-achievement",
                element: <Premium />
            },
            // {
            //     path: "/participate",
            //     element: <ParticipateStore />
            // },
            // {
            //     path: "/participate-store",
            //     element: <ParticipateTransfer />
            // },
            // {
            //     path: "/participate-history",
            //     element: <ParticipateHistory />
            // },
            // {
            //     path: "/partipate-ico-history",
            //     element: <ParticipateIcoHistory />
            // },
            {
                path: "/business-transfer",
                element: <BusinessTransfer />
            },
            {
                path: "/bussiness-ico",
                element: <BussinessStore />
            },
            {
                path: "/transfer-history",
                element: <TransferHistory />
            },
            {
                path: "/stake-view",
                element: <StakingStoreDetails />
            },
            {
                path: "/claim-history",
                element: <ClaimHistory />
            },
            {
                path: "/redeem",
                element: <RedeemStore />
            },
            {
                path: "/redeem-history",
                element: <RedeemHistory />
            },
            {
                path: "/bussiness-volume",
                element: <BussinessVolume />
            },
            {
                path: "/bussiness-store",
                element: <Bussinesstore />
            },
            {
                path: "/referal-claim-history",
                element: <ReferalHistory />
            },
            {
                path: "/bussiness-store-history/:level",
                element: <BusinessStoreHistory />
            },
            {
                path: "/oneTimeReward-history/:reward",
                element: <OneTimeReward />
            },
            {
                path: "/qualification/:level",
                element: <RoleHistory />
            },
            {
                path: "/poolbonus-claim-history",
                element: <PoolBonusHistory />
            },
            {
                path: "/poolbonus-report",
                element: <PoolBonusReport />
            },
            {
                path: "/liquidity-report",
                element: <LiquidityPoolReport />
            },
            {
                path: "/level-member-history",
                element: <LevelMemberHistory />
            },
        ]
    },
    {
        path: "/buy-success",
        element: <SuccessBuy />
    }
]