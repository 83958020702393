import React, { useEffect, useState } from 'react';
import "../../../assets/scss/Innerpages/Innerpages.scss";
import xlsIcon from "../../../assets/images/xlsIcon.png";
import { useDebounce } from '../../../hook/useDounce';
import * as XLSX from "xlsx";
import { DropdownItem, DropdownMenu, DropdownToggle, TabContent, TabPane, UncontrolledDropdown } from 'reactstrap';
import RedeemWithdrawHistory from './redeemHistory';
import { useRedeemHistory } from '../api/fetchredeem';
import RedeemStakeHistory from './redeemStakeHistory';
import RedeemTransferHis from './redeemTransferHis';
import { useExportDocs } from '../../Transfer/api/fetchTransfer';
import { useModalContext } from '../../../contexts/ModalContext';
import { ButtonLoader } from '../../../Components/Loader/buttonLoad';

const RedeemHistory = () => {

    const { profileData } = useModalContext();
    const [activeTab, setActiveTab] = useState("Stake");
    const [filter, setFilter] = useState('')
    const [dayFilter, setdayFilter] = useState("");
    const searchText = useDebounce(filter)
    const [page, setPage] = useState(1)

    const [transferList, settransferList] = useState([])
    const redeemHistorymutation = useRedeemHistory();
    const exportmutation = useExportDocs()

    useEffect(() => {
        fetchData();
    }, [searchText, page, activeTab, dayFilter])

    const fetchData = async () => {
        let obj = {
            page: page,
            size: 10,
            type: activeTab,
            dayFilter: dayFilter,
            filter : filter
        }
        let res = await redeemHistorymutation.mutateAsync(obj);
        settransferList(res)
    }

    const handleDownload = async () => {
        let obj = {
            type: activeTab === "Stake" ?
                "redeemStake" :
                activeTab === "Transfer" ?
                    "redeemTransfer" :
                    "redeemWithdraw",
            dayFilter: dayFilter,
            filter: filter
        }
        let res = await exportmutation.mutateAsync(obj);
        if (res?.status) {
            const header = Object.keys(res?.data[0]);
            const sheet = XLSX.utils.json_to_sheet(res?.data, { header });

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, sheet, "Sheet1");

            XLSX.writeFile(wb, `${profileData?.memberID}-Redeem-${activeTab}.xlsx`);
        }
    };

    return (
        <div className="container animated_div">
            <h3 className="secHeading text-center">Redeem History</h3>

            <div className="row">
                <div className="col-12">
                    <div className="d-flex align-items-center flex-wrap">
                        <div className="d-flex align-items-center filterCnt mb-3 ml-auto flex-wrap flex-md-nowrap">
                            <input
                                onChange={(e) => setFilter(e.target.value)}
                                type="search"
                                placeholder="Search"
                                className={(filter ? "form-control my-1" : "form-control searchbox my-1") } />

                            <div className='octDrpdown dark mx-1'>
                                <UncontrolledDropdown>
                                    <DropdownToggle caret>
                                        {dayFilter === "7" ? "1 Week Ago" : dayFilter === "30" ? "1 Month Ago" : "Recently"}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag={"a"} type="button" onClick={() => setdayFilter("")}>Recently</DropdownItem>
                                        <DropdownItem tag={"a"} type="button" onClick={() => setdayFilter("7")}>1 Week Ago</DropdownItem>
                                        <DropdownItem tag={"a"} type="button" onClick={() => setdayFilter("30")}>1 Month Ago</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>

                            <div className='octDrpdown dark mx-1'>
                                <UncontrolledDropdown>
                                    <DropdownToggle caret style={{ fontSize: "14px" }}>
                                        {activeTab}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag={"a"} type="button" onClick={() => setActiveTab("Stake")}>Stake</DropdownItem>
                                        <DropdownItem tag={"a"} type="button" onClick={() => setActiveTab("Withdraw")}>Withdraw</DropdownItem>
                                        <DropdownItem tag={"a"} type="button" onClick={() => setActiveTab("Transfer")}>Transfer</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>

                            <div className="float-right">
                                <button className="btn filterBtn my-1" onClick={handleDownload} disabled={redeemHistorymutation.isLoading || transferList?.status == false}>
                                    {redeemHistorymutation.isLoading ?
                                        <ButtonLoader />
                                        : <>
                                            <img src={xlsIcon} width="24" height="24" alt="xlsIcon" />
                                            Export File
                                        </>}
                                </button>
                            </div>

                        </div>
                    </div>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="Stake">
                            <RedeemStakeHistory transferList={transferList} isLoading={redeemHistorymutation.isLoading} page={page} setPage={setPage} />
                        </TabPane>
                        <TabPane tabId="Withdraw">
                            <RedeemWithdrawHistory transferList={transferList} isLoading={redeemHistorymutation.isLoading} page={page} setPage={setPage} />
                        </TabPane>
                        <TabPane tabId="Transfer">
                            <RedeemTransferHis transferList={transferList} isLoading={redeemHistorymutation.isLoading} page={page} setPage={setPage} />
                        </TabPane>
                    </TabContent>
                </div>
            </div>
        </div>
    )
}
export default RedeemHistory;