import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import "../../assets/scss/Innerpages/Innerpages.scss";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import AnaWalletTransfer from '../StakeStore/stakeStore';
import RedeemStore from '../RedeemStore/redeemStore';
import TransferStore from '../Transfer/TransferStore';
import { useAnawalletBalance } from '../common/fetchBalance';
import { ButtonLoader } from '../../Components/Loader/buttonLoad';
import WalletHistory from './tabs/walletHistory';
import ReceivedHistory from './tabs/receivedHistory';
import Converter from '../../lib/converter';
import { useGetIcoDetails } from './api/Anawalletapi';
import { useToast } from '../../hook/usetoast';

const AnaWallet = () => {

   const { data: getwalletBalance, isLoading } = useAnawalletBalance();
   const { data: getIco } = useGetIcoDetails();
   const toast = useToast()

   const [modalstake, setModalstake] = useState(false);
   const toggle = () => setModalstake(!modalstake);
   const [modalRedeem, setModalRedeem] = useState(false);
   const toggleRedeem = () => {
      setModalRedeem(false)
      return toast.error('Currently unavailable.')
   };
   const [modalTransfer, setModalTransfer] = useState(false);
   const toggleTransfer = () => {
      setModalTransfer(false)
      return toast.error('Currently unavailable.')
   }

   useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

   return (
      <div className="container animated_div">
         <h3 className="secHeading text-center">ANA Wallet</h3>

         <div className="row">
            <div className="col-lg-12">
               <div className="card">
                  <div className="cardInnerCnt">
                     <div className="row">
                        <div className="col-lg-4 bdrCnt">
                           <div className="mb-3">
                              <h5 className="cardLabel mb-0">Total ANA Available</h5>
                              <h5 className="cardValue mb-0">
                                 {isLoading ?
                                    <ButtonLoader /> :
                                    getwalletBalance?.balance?.anabalance ? ((getwalletBalance?.balance?.anabalance)?.toString()).includes('.') ? (getwalletBalance?.balance?.anabalance)?.toFixed(6) :getwalletBalance?.balance?.anabalance : 0}
                                 {" "}ANA</h5>
                           </div>
                           <div className="d-flex flex-column">
                              {getIco?.message == "All phases end" ? "" :<Link className="cardLink mb-2" onClick={toggle}>Move To Stake</Link>}
                              <Link className="cardLink mb-2" onClick={toggleTransfer}>Move To Transfer</Link>
                              <Link className="cardLink" onClick={toggleRedeem}>Move To Redeem Store</Link>
                           </div>
                        </div>
                        <div className="col-lg-4 bdrCnt px-lg-5">
                           <div className="mb-5">
                              <h5 className="cardLabel mb-0">Total ANA Received</h5>
                              <h5 className="cardValue mb-0">{isLoading ? <ButtonLoader /> : getwalletBalance?.balance?.receivedAna ?  ((getwalletBalance?.balance?.receivedAna)?.toString()).includes('.') ? (getwalletBalance?.balance?.receivedAna)?.toFixed(6) :getwalletBalance?.balance?.receivedAna : 0} ANA</h5>
                           </div>
                           <div>
                              <h5 className="cardLabel mb-0">ANA In Transfer Store</h5>
                              <h5 className="cardValue mb-0">{isLoading ? <ButtonLoader /> : getwalletBalance?.balance?.totalTransfered ? ((getwalletBalance?.balance?.totalTransfered)?.toString())?.includes('.') ? (getwalletBalance?.balance?.totalTransfered)?.toFixed(6) :getwalletBalance?.balance?.totalTransfered : 0} ANA</h5>
                           </div>
                        </div>
                        <div className="col-lg-4 px-lg-5">
                           <div className="mb-5">
                              <h5 className="cardLabel mb-0">ANA In Stake Store</h5>
                              <h5 className="cardValue mb-0">{isLoading ? <ButtonLoader /> : getwalletBalance?.balance?.totalStakereceived ? ((getwalletBalance?.balance?.totalStakereceived)?.toString())?.includes('.') ? (getwalletBalance?.balance?.totalStakereceived)?.toFixed(6) : getwalletBalance?.balance?.totalStakereceived : 0} ANA</h5>
                           </div>
                           <div>
                              <h5 className="cardLabel mb-0">ANA In Redeem Store</h5>
                              <h5 className="cardValue mb-0">{isLoading ? <ButtonLoader /> : getwalletBalance?.balance?.totalRedeem ? ((getwalletBalance?.balance?.totalRedeem))?.toString().includes('.') ? (getwalletBalance?.balance?.totalRedeem)?.toFixed(6) :getwalletBalance?.balance?.totalRedeem : 0 } ANA</h5>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <WalletHistory />
            <ReceivedHistory />
         </div>


         <Modal isOpen={modalstake} toggle={toggle} modalClassName="authModal">
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
               <AnaWalletTransfer toggle={toggle} />
            </ModalBody>
         </Modal>
         <Modal isOpen={modalRedeem} toggle={toggleRedeem} modalClassName="authModal">
            <ModalHeader toggle={toggleRedeem}></ModalHeader>
            <ModalBody>
               <RedeemStore toggleRedeem={toggleRedeem} />
            </ModalBody>
         </Modal>
         <Modal isOpen={modalTransfer} toggle={toggleTransfer} modalClassName="authModal">
            <ModalHeader toggle={toggleTransfer}></ModalHeader>
            <ModalBody>
               <TransferStore toggleTransfer={toggleTransfer} />
            </ModalBody>
         </Modal>
      </div>
   )
}
export default AnaWallet;