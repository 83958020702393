import axios from 'axios'
import * as CryptoJS from 'crypto-js'
import { BACKEND_URL_DEPOSIT, SERVICE_URL } from './index';
import toast from 'react-hot-toast';

const environment = {
	dbvSTQDV6gwJkEv5IIBQA: SERVICE_URL,
	apiUrl: BACKEND_URL_DEPOSIT
}

const DepositService = () => {
	const REST_API_SERVER = environment.apiUrl

	const getGen = (url, getTime) => {
		url = `${url}`
		const date = getTime
		const ex_date = Math.round(date / 1000) + 60
		const payload = {
			exp: ex_date,
			isHuman: true
		}

		const token = signToken(payload, url)


		const encrtpted_token = CryptoJS.AES.encrypt(
			token,
			environment.dbvSTQDV6gwJkEv5IIBQA
		).toString()

		return { token: encrtpted_token }
	}

	const base64url = source => {
			let encodedSource = CryptoJS.enc.Base64.stringify(source)
			encodedSource = encodedSource.slice(0, encodedSource.indexOf('=') === -1 ? encodedSource.length : encodedSource.indexOf('='));
			encodedSource = encodedSource.replace(/\+/g, '-').replace(/\//g, '_');
			return encodedSource
		}

	const signToken = (payload, key) => {
		const secret = key
		let token = encodeToken(payload)
		let signature = CryptoJS.HmacSHA256(token, secret)
		signature = base64url(signature)
		const signedToken = `${token}.${signature}`
		return signedToken
	}

	const encodeToken = payload => {
		const header = {
			alg: 'HS256',
			typ: 'JWT'
		}
		const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header))
		const encodedHeader = base64url(stringifiedHeader)
		const stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(payload))
		const encodedData = base64url(stringifiedData)
		const token = `${encodedHeader}.${encodedData}`
		return token
	}

	const handleError = error => {
		let errorMessage = 'Unknown error!'
		if (error.response) {
			errorMessage = `Error Code: ${error.response.status}\nMessage: ${error.response.data}`
		} else if (error.request) {
			errorMessage = 'No response received from server.'
		} else {
			errorMessage = `Error: ${error.message}`
		}
		return Promise.reject(errorMessage)
	}

	const doAnotherthing = async () => {
		const datetime = await axios.get(`${REST_API_SERVER}user/getCurrentTime`)
		return datetime.data
	}

	const depositgetRequest = async url => {
		const token = sessionStorage.getItem('token_ana')
		const type = sessionStorage.getItem('type')

		try {
			const datetime = await doAnotherthing()
			const tokenHandling = getGen(`${REST_API_SERVER}${url}`, datetime.data)
			const headers = {
				'cache-control': 'no-cache',
				'content-type': 'application/json',
				Authorization: token,
				JdstEWUawW: tokenHandling.token,
				type : type

			}
			const response = await axios.get(`${REST_API_SERVER}${url}`, { headers })
			
			if (response?.data?.status === 700) {
				toast.error("Unauthorized request.")
				return window.location.href = '/unauthorized-request'
			} else if (response?.data?.status === 800) {
				return window.location.href = '/UnderMaintenance'
			} else if (response?.data?.status === 420 && sessionStorage.getItem('type') === "user"){
				sessionStorage.clear()
				return window.location.href = '/login'
			}
			return response.data
		} catch (error) {
			return handleError(error)
		}
	}

	const depositpostRequest = async (url, requestData) => {
		const token = sessionStorage.getItem('token_ana')
		const type = sessionStorage.getItem('type')

		try {
			const datetime = await doAnotherthing()
			const tokenHandling = getGen(`${REST_API_SERVER}${url}`, datetime.data)
			const headers = {
				'cache-control': 'no-cache',
				Authorization: token,
				JdstEWUawW: tokenHandling.token,
				type : type
			}

			const response = await axios.post(`${REST_API_SERVER}${url}`, requestData, { headers })
			
			if (response?.data?.status === 700) {
				toast.error("Unauthorized request.")
				return window.location.href = '/unauthorized-request'
			} else if (response?.data?.status === 800) {
				return window.location.href = '/UnderMaintenance'
			} else if (response?.data?.status === 420 && sessionStorage.getItem('type') === "user" ) {
				sessionStorage.clear()
				return window.location.href = '/login'
			}
			return response.data
		} catch (error) {
			return handleError(error)
		}
	}


	return {
		depositgetRequest,
		depositpostRequest
	}
}

export default DepositService