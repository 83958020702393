import eyeSlashIcon from '../../assets/images/Auth/eye-slash.png';
import eyeIcon from "../../assets/images/Auth/eye.png";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useState } from "react";
import { Dots } from '../../lib/dots';
import { Link } from 'react-router-dom';
import { usePoolBonusClaim } from './api/fetchBusinessStore';
import { useDebounce } from '../../hook/useDounce';
import { useTransferFee } from '../Transfer/api/fetchTransfer';
import { useModalContext } from '../../contexts/ModalContext';
import { Submitbutton } from '../common/Submitbutton';
import PropTypes from 'prop-types';
import { generateSecureRandomNumber } from '../../hook/generateSecureRandomNumber';
const schema = yup.object().shape({
    claimAmount: yup.number()
        .min(1, 'Minimum claim amount is 1 ANA')
        .typeError('Amount is Required')
        .required('Amount is Required'),
    MPIN: yup.string()
        .required('MPIN is Required')
        .matches(/^[0-9]{6}$/, 'MPIN must be 6 digits'),
})

const PoolBonusClaim = ({ toggle, available = 0 }) => {

    const [amount, setAmount] = useState();
    const [feeAmount, setFeeAmount] = useState(0);
    const { profileData } = useModalContext();
    const [fee, setFee] = useState(0)
    const amountVal = useDebounce(feeAmount)
    const transferFeeMutation = useTransferFee()

    useEffect(() => {
        getFee();
    }, [amountVal])

    const getFee = async () => {
        const response = await transferFeeMutation.mutateAsync({ amount: amountVal, type: "poolBonus" })
        if (response?.status) {
            setFee(response?.data?.fee)
            let value = Number(response?.data?.fee) / 100;
            value = value * Number(amountVal)
            value = Number(amountVal) - value
            setAmount(value)
        }
    }

    const poolBonusclaimMutation = usePoolBonusClaim();

    const [show, setShow] = useState(false)
    const toggleShow = (e) => {
        if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
        setShow(!show)
        }
    }

    const { control, handleSubmit, formState: { errors }, reset, setError } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            claimAmount: null,
            MPIN: "",
        }
    });

    const handleKeyDown = e => e.target.value = e.target.value.match(/^[0-9]{0,6}/)[0]

    let x = Math.floor(generateSecureRandomNumber() * 90000) + 10000;
    const onSubmit = async (data) => {
        if (data.claimAmount > available) {
            return setError("claimAmount", { message: "Insufficient claim amount." })
        }
        data["randomId"] = x.toString();
        await poolBonusclaimMutation.mutateAsync(data);
        reset()
        toggle();
    }

    return (
        <section className="authPagesCnt animated_div">
            <div className="row justify-content-center w-100">
                <div className="col-lg-6 col-xl-4">
                    <h3 className="modalHeading">Pool Bonus Claim</h3>
                    <div className="d-flex flex-column flex-sm-row align-items-center">
                        <div className="d-flex ml-sm-auto">
                            <Link to="/poolbonus-claim-history" className="btn btn-11545 btnGold">History</Link>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label className="formLabel" htmlFor="loginId3">ANACOIN</label>
                            <div className="input-group mb-3">
                                <Controller name='claimAmount' control={control} render={({ field }) => (
                                    <input
                                        {...field}
                                        type='number'
                                        className={`form-control  ${errors.claimAmount && "is-invalid"}`}
                                        placeholder="Enter amount"
                                        onChange={(e) => {
                                            field.onChange(e.target.value);
                                            setFeeAmount(e.target.value)
                                        }}
                                        autoComplete='off'
                                    />
                                )} />

                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        <span className="mx-2 prmyTxt">ANA</span>
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex">
                                <span className="scdryTxt">Available Balance</span>
                                <span className="prmyTxt ml-sm-auto">{available || "0"} ANA</span>
                            </div>
                        </div>
                        {errors.claimAmount && <p className="errMsg">* {errors.claimAmount.message}</p>}
                        <div className="form-group">
                            <label className="formLabel" htmlFor="loginId2">MPIN</label>
                            <div className="input-group mb-3">
                                <Controller name='MPIN' control={control} render={({ field }) => (
                                    <input
                                        {...field}
                                        type={show ? "text" : "password"}
                                        className="form-control"
                                        placeholder="Enter Your MPIN"
                                        maxLength={6}
                                        onInput={handleKeyDown}
                                        autoFocus
                                        autoComplete='off'
                                    />
                                )} />
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        {show ?
                                            <img src={eyeIcon} width="20" height="20" alt="eyeSlashIcon" onClick={(e)=>toggleShow(e)} onKeyDown={(e)=>toggleShow(e)}/>
                                            :
                                            <img src={eyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" onClick={(e)=>toggleShow(e)} onKeyDown={(e)=>toggleShow(e)}/>
                                        }
                                    </span>
                                </div>
                            </div>
                            {errors.MPIN && <p className="errMsg">* {errors.MPIN.message}</p>}
                        </div>
                        <div className="d-flex mb-3">
                            <span className="scdryTxt">Fees</span>
                            <span className="prmyTxt ml-sm-auto">{fee || 0} %</span>
                        </div>
                        <div className="d-flex">
                            <span className="scdryTxt">Total Amount</span>
                            <span className="prmyTxt ml-sm-auto">{amount || "0"} ANA</span>
                        </div>
                        <div className="d-flex justify-content-center my-4">
                            {profileData?.kyc_status == 3 && sessionStorage.getItem('type') != 'admin' ?
                                <button className="btn btn-11545 btnGold" disabled={poolBonusclaimMutation.isLoading}>
                                    {poolBonusclaimMutation.isLoading ?
                                        <Dots>Loading</Dots>
                                        :
                                        "Claim"}
                                </button> :
                                <Submitbutton
                                    buttonText="Submit"
                                    loading={poolBonusclaimMutation.isLoading}
                                    message="Ana Wallet option disabled. Users can't buy temporarily"
                                />}
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}
PoolBonusClaim.propTypes = {
    toggle : PropTypes.any,
    available : PropTypes.any
}

export default PoolBonusClaim;