import React, { useState, useEffect } from 'react';
import "../../assets/scss/Innerpages/Innerpages.scss";
import logoLg from "../../assets/images/logoLg.png";
import Deposite from './tabs/deposite'
import Withdraw from './tabs/withdraw'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { useUserTransaction, useCurrency } from './api/depositewithdrawapi'
import { useLocation } from 'react-router-dom';
import { CoinListLoader } from '../../Components/Loader/skeleton';
import Aos from 'aos';
import DepositeHistory from './tabs/depositeHistory';
import WithdrawHisroty from './tabs/withdrawHisroty';
import MoveAna from './tabs/moveAna';

import { useModalContext } from '../../contexts/ModalContext';
import BigNumber from 'bignumber.js'
const Wallet = () => {

   const [activeTab, setActiveTab] = useState('1');
   const location = useLocation();
   const { siteSettings, minwithdraw, mindeposit } = useModalContext();

   useEffect(() => {
      Aos.init({ once: true });

      if (location.pathname == '/withdraw') setActiveTab('2');
   }, [location, minwithdraw]);

   const toggle = tab => {
      if (activeTab !== tab) setActiveTab(tab);
   }
   const { data: UserTransaction, isLoading } = useUserTransaction()

   const { data: UserCurrency } = useCurrency()

   const Currency = UserTransaction?.find(c => c.currency === "ANA") || {};
   const getCurrency = UserCurrency?.find(c => c.currencySymbol === "ANA") || {};


   return (
      <div className="container animated_div">
         <h3 className="secHeading text-center">Wallet</h3>

         <div className="row">
            <div className="col-lg-4 col-xl-3">
               <div className="card px-3">
                  <div className="cardInnerCnt">
                     <h3 className="cardHeading">Wallet Balance</h3>
                     {Currency?.currencyNetwork ?
                        <div className="goldCnt">
                           <h5 className="cardLabel mb-0" style={{ textTransform: "capitalize" }}>ANA</h5>
                           <h3 className="cardValue mb-0 d-flex align-items-center"><img src={logoLg} width="22" height="22" alt="logoLg" className="mr-2" />{(BigNumber(Currency?.balance))?.toLocaleString("en-US", { maximumFractionDigits: 2 })} {Currency?.currency}</h3>
                        </div>
                        :
                        getCurrency?.network ?
                           <div className="goldCnt">
                              <h5 className="cardLabel mb-0" style={{ textTransform: "capitalize" }}>{getCurrency?.network} </h5>
                              <h3 className="cardValue mb-0 d-flex align-items-center"><img src={logoLg} width="22" height="22" alt="logoLg" className="mr-2" />0 {getCurrency?.currencySymbol}</h3>
                           </div>
                           :
                           ""
                     }

                     {isLoading ?
                        <CoinListLoader />
                        :
                        <div className="coinInfoCnt animated_div px-3">
                           {UserTransaction?.length > 0 ?
                              UserTransaction?.filter(c => c.status == true && c.currency !== "ANA").map((n, i) => (
                                 <div key={n} className="coinInfoRow d-flex align-items-start" data-aos="zoom-in" data-aos-dration={i * 100}>
                                    <img src={n.currencyImage} width="22" height="22" alt="usdt" className="mr-2" />
                                    <div className="d-flex flex-column">
                                       <h5 className="cardLabel whiteTxt mb-0" style={{ textTransform: "capitalize" }} >
                                       </h5>
                                       <h5 className="cardLabel mb-0">{parseFloat((BigNumber(n.balance))?.toFixed(6))} {n.currency}</h5>
                                    </div>
                                 </div>
                              ))
                              :
                              UserCurrency?.filter(c => c.currencySymbol !== "ANA").map((n, i) => (
                                 <div key={n} className="coinInfoRow d-flex align-items-start" data-aos="zoom-in" data-aos-dration={i * 100}>
                                    <img src={n.currencyImage} width="22" height="22" alt="usdt" className="mr-2" />
                                    <div className="d-flex flex-column">
                                       <h5 className="cardLabel whiteTxt mb-0" style={{ textTransform: "capitalize" }} >
                                          {n.network}</h5>
                                       <h5 className="cardLabel mb-0"> 0 {n.currencySymbol}</h5>
                                    </div>
                                 </div>
                              ))
                           }
                        </div>
                     }

                  </div>
               </div>
            </div>

            <div className="col-lg-8 col-xl-9">
               <div className="card walletCard">
                  <div className="cardInnerCnt">
                     <div className="cardHeader">
                        <Nav tabs>
                           <NavItem>
                              <NavLink
                                 className={classnames({ active: activeTab === '1' })}
                                 onClick={() => { toggle('1'); }}
                              >
                                 Deposit
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 className={classnames({ active: activeTab === '2' })}
                                 onClick={() => { toggle('2'); }}
                              >
                                 Withdraw
                              </NavLink>
                           </NavItem>
                           {/* <NavItem>
                              <NavLink
                                 className={classnames({ active: activeTab === '3' })}
                                 onClick={() => { toggle('3'); }}
                              >
                                 Move ANA
                              </NavLink>
                           </NavItem> */}
                        </Nav>
                     </div>
                     <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                           <Deposite />
                        </TabPane>
                        <TabPane tabId="2">
                           <Withdraw userCoins={UserTransaction} />
                        </TabPane>
                        <TabPane tabId="3">
                           <MoveAna Balance={(BigNumber(Currency?.balance))?.toLocaleString("en-US", { maximumFractionDigits: 2 })} />
                        </TabPane>
                     </TabContent>
                  </div>
               </div>
               {activeTab == 3 ? <>
               </> :
                  <div className="card notesCard">
                     <div className="cardInnerCnt">

                        {activeTab == 1 ?
                           <>
                              <p className="mb-0 mt-3">Notes : {siteSettings?.DepositeNotes}</p><br />

                              {mindeposit || getCurrency?.minimumDeposit ? <p className="mb-0" style={{ color: "red" }}>Minimum Deposit : {mindeposit ? mindeposit : getCurrency.minimumDeposit}</p> : ""}
                           </>
                           :
                           activeTab == 2 ?

                              <>
                                 <p className="mb-0 mt-3">Notes : {siteSettings?.withdrawNotes}</p><br />

                                 {minwithdraw ? <p className="mb-0" style={{ color: "red" }}>Minimum Withdraw : {minwithdraw}</p> : ""

                                 }
                              </>
                              : ""
                        }


                     </div>
                  </div>
               }
            </div>
            <div className="col-12">
               <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                     <DepositeHistory />
                  </TabPane>
                  <TabPane tabId="2">
                     <WithdrawHisroty />
                  </TabPane>
               </TabContent>
            </div>
         </div>
      </div>
   )
}
export default Wallet;