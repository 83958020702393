
import { useMutation, useQuery } from "react-query"
import { queryClient } from "../../../config";
import { useToast } from "../../../hook/usetoast";
import DepositService from "../../../config/DepositService";
import DataService from "../../../config/DataService";
const { depositgetRequest, depositpostRequest } = DepositService()
const { postRequest } = DataService()

export const useUserTransaction = () => {

    return useQuery({
        queryKey: ["user-Transactions"],
        queryFn: () => {
            return depositgetRequest("user/getUserWallet")
        },
        select: res => res.data
    })
}

export const useCurrency = () => {

    return useQuery({
        queryKey: ["user-Currency"],
        queryFn: () => {
            return depositgetRequest("user/getWalletCurrency")
        },
        select: res => res.data
    })
}

export const useDeposite = () => {

    return useMutation({
        mutationFn: (data) => {
            return depositpostRequest("user/createWalletAddress", { ...data })
        },
        onSuccess: success => {
            if (success?.status) {
                queryClient.refetchQueries('user-Transactions')
            }

        },
        select: res => res.data
    });
};

export const useWithdraw = () => {
    const toast = useToast();
    return useMutation({
        mutationFn: (data) => {
            return depositpostRequest('user/withdraw', { ...data })
        },
        onSuccess: success => {
            if (success?.status) {
                return toast.success("Withdraw request successfully")
            }
            return toast.error(success.message)
        },
        select: res => res.data,
    })
}

export const useExportHistory = () => {

    return useMutation({
        mutationFn: (data) => {
            return depositpostRequest('user/ExportTransactions', { ...data })
        },
        select: res => res.data
    })
}

function withdrawHistory(type, page, size, searchText, time) {
    return depositgetRequest(`user/UserTransactions?type=${type}&page=${page}&size=${size}&filter=${searchText}&time=${time}&sort=desc&status=${false}`)
}

export function useHistory(type, page, size, searchText, time) {

    return useQuery({
        queryKey: ['withdrawHistory', type, page, size, searchText, time],
        queryFn: () => {
            return withdrawHistory(type, page, size, searchText, time)

        },
    })
}

export const useSendOTP = () => {

    return useMutation({
        mutationFn: (data) => {
            return postRequest('user/withdraw_otp', { ...data })
        },
        select: res => res.data
    })
}