import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useGetTFA, useTfaSettings } from '../api/fetchDashboard';
import { Dots } from '../../../lib/dots';
import { CheckCheck, Copy } from 'lucide-react';
import { useToast } from '../../../hook/usetoast';
import { QRLoader } from '../../../Components/Loader/buttonLoad';
import { Submitbutton } from '../../common/Submitbutton';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

const schema = yup.object().shape({
   code: yup.string()
      .matches(/^[0-9]{6}$/, 'Invalid TFA code')
      .required('TFA code is Required'),
})

const TwoFactor = ({ toggle }) => {

   const toast = useToast()
   const [isCopied, setIsCopied] = useState(false);
   const { data: getTFA } = useGetTFA();
   
   const tfaSettingsmutation = useTfaSettings();

   const { control, handleSubmit, formState: { errors }, reset } = useForm({
      mode: 'onChange',
      resolver: yupResolver(schema),
      defaultValues: {
         code: '',
      }
   });

   const handleKeyDown = e => e.target.value = e.target.value.match(/^[0-9]{0,6}/)[0]

   const onSubmit = async (data) => {
      let res = await tfaSettingsmutation.mutateAsync(data)
      if (res?.status) {
         reset()
         toggle()
      }
   }

   const copytoClip = async (e,link) => {
      if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
      toast.copy("Secret key copied")
      setIsCopied(true)
      if ("clipboard" in navigator) {
         await navigator.clipboard.writeText(link);
      } else {
         document.execCommand("copy", true, link);
      }
      setTimeout(() => {
         setIsCopied(false);
      }, 1500);
      }
   }

   return (
      <section className="authPagesCnt animated_div">
         <div className="container-fluid">
            <div className="row justify-content-center">
               <div className="col-lg-8">
                  <h3 className="modalHeading">Two Factor Authentication</h3>
                  <p className="modalSubHeading">Two factor authentication is a method for protection your web account.</p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                     <div className="row justify-content-center">
                        <div className="col-lg-8 col-xl-8">
                           <div className="form-row">
                              {!getTFA?.tfa_status &&
                                 <div className="col-lg-4 d-flex justify-content-center align-items-start">
                                    {!getTFA?.tfa_url ? <QRLoader /> :
                                       <img src={getTFA?.tfa_url} className="img-fluid" width="177" height="177" alt="qrCode" />}
                                 </div>}
                              <div className="col-lg-8">
                                 {!getTFA?.tfa_status &&
                                    <div className="form-group">
                                       <label className="formLabel" htmlFor="authId1">Your Authentication Key</label>
                                       <label className="formLabel" htmlFor="loginId2">Password</label>
                                       <div className="input-group mb-3">
                                          <input type="text" className="form-control" id="authId1" value={getTFA?.tfa_secret} />
                                          <div className="input-group-append" onClick={(e) => copytoClip(e,getTFA?.tfa_secret)} onKeyDown={(e) => copytoClip(e,getTFA?.tfa_secret)}>
                                             <span className="input-group-text yellowTxt" type="button">
                                                {isCopied ? <CheckCheck size={20} /> : <Copy size={20} />}
                                             </span>
                                          </div>
                                          {errors.mpin && <p className="errMsg">* {errors.mpin.message}</p>}
                                       </div>

                                    </div>
                                 }
                                 <div className="form-group">
                                    <label className="formLabel">Google Authentication Code</label>
                                    <Controller name='code' control={control} render={({ field }) => (
                                       <input
                                          {...field}
                                          type="text"
                                          maxLength={6}
                                          onInput={handleKeyDown}
                                          className="form-control"
                                          placeholder='6 Digit Code'
                                          autoComplete='off'
                                       />
                                    )} />
                                    {errors.code && <p className="errMsg">* {errors.code.message}</p>}
                                 </div>
                                 {sessionStorage.getItem('type') != "admin" || !Cookies.get('sdgsdsadgt') ? <button className="btn btn-11545 btnGold" disabled={tfaSettingsmutation.isLoading}>
                                    {tfaSettingsmutation.isLoading ?
                                       <Dots>Loading</Dots>
                                       :
                                       getTFA?.tfa_status ? "Disable" : "Enable"
                                    }
                                 </button> : <Submitbutton />}
                              </div>
                           </div>

                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </section>
   )
}
TwoFactor.propTypes = {
    toggle : PropTypes.any
}
export default TwoFactor;