import React, { useEffect, useState } from 'react';
import "../../assets/scss/Innerpages/Innerpages.scss";
import xlsIcon from "../../assets/images/xlsIcon.png";
import { useExportDocs, useTransferHistory } from './api/fetchTransfer';
import { useDebounce } from '../../hook/useDounce';
import * as XLSX from "xlsx";
import Nodatafound from '../Nodata/Nodatafound';
import { TransferHistorySkeleton } from '../../Components/Loader/skeleton';
import Pagination from '../common/pagination/Pagination';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { ButtonLoader } from '../../Components/Loader/buttonLoad';
import Converter from '../../lib/converter';
import { useModalContext } from '../../contexts/ModalContext';


const TransferHistory = () => {

   const [page, setPage] = useState(1)
   const size = 10;
   const [sort, setSort] = useState("1")
   const [filter, setFilter] = useState('')
   const [dayFilter, setdayFilter] = useState("");
   const searchText = useDebounce(filter)
   const { profileData } = useModalContext();

   const [transferList, settransferList] = useState({});
   const transferHistory = useTransferHistory();
   const exportmutation = useExportDocs();

   useEffect(() => {
      fetchData()
   }, [page, sort, searchText,dayFilter])

   const fetchData = async () => {
      let obj = {
         "page": page,
         "size": size,
         "sort": sort,
         "filter": searchText,
         "dayFilter": dayFilter,
      }
      let res = await transferHistory.mutateAsync(obj)
      if (res?.status) {
         settransferList(res)
      } else settransferList({})
   }

   const handleDownload = async () => {
      let res = await exportmutation.mutateAsync({ type: "traferHistory" , "filter": searchText,
         "dayFilter": dayFilter,});
      if (res?.status) {
         const header = Object.keys(res?.data[0]);
         const sheet = XLSX.utils.json_to_sheet(res?.data, { header });

         const wb = XLSX.utils.book_new();
         XLSX.utils.book_append_sheet(wb, sheet, "Sheet1");

         XLSX.writeFile(wb, `${profileData?.memberID}-Transfer.xlsx`);
      }
   };

   return (
      <div className="container animated_div">
         <h3 className="secHeading text-center">Transfer</h3>

         <div className="row">
            <div className="col-12">
               <div className="d-flex align-items-center flex-wrap">
                  <h3 className="cardTitle lgTitle">Transfer History</h3>
                  <div className="d-flex align-items-center filterCnt mb-3 ml-auto flex-wrap flex-md-nowrap">
                     <input
                        onChange={(e) => setFilter(e.target.value)}
                        type="search"
                        placeholder="Search"
                        className={(filter ? "form-control my-1" : "form-control searchbox my-1") } />

                     <div className='octDrpdown dark mx-1'>
                        <UncontrolledDropdown>
                           <DropdownToggle caret>
                           {dayFilter === "7" ? "1 Week Ago" : dayFilter === "30" ? "1 Month Ago" : "Recently"}
                           </DropdownToggle>
                           <DropdownMenu>
                              <DropdownItem tag={"a"} type="button" onClick={() => setdayFilter("")}>Recently</DropdownItem>
                              <DropdownItem tag={"a"} type="button" onClick={() => setdayFilter("7")}>1 Week Ago</DropdownItem>
                              <DropdownItem tag={"a"} type="button" onClick={() => setdayFilter("30")}>1 Month Ago</DropdownItem>
                           </DropdownMenu>
                        </UncontrolledDropdown>
                     </div>

                     <div className="float-right">
                        {exportmutation.isLoading || transferHistory.isLoading ?
                           <button className="btn filterBtn my-1" disabled>
                              <ButtonLoader />
                           </button>
                           :
                           <button className="btn filterBtn my-1"
                              disabled={Boolean(transferList?.data?.length <= 0) || !transferList?.data}
                              onClick={handleDownload}
                           >
                              <img src={xlsIcon} width="24" height="24" alt="xlsIcon" />
                              Export File
                           </button>}
                     </div>
                  </div>
               </div>

               <div className="card tableCard">
                  <div className="cardInnerCnt">
                     <div className="table-responsive">
                        <table className="table table-hover mb-0">
                           <thead>
                              <tr>
                                 <th>S.No</th>
                                 <th>Date and Time</th>
                                 <th>From Member ID</th>
                                 <th>Amount Value</th>
                                 <th>To Member ID</th>
                                 <th>Fee</th>
                                 <th className="text-right">Status</th>
                              </tr>
                           </thead>
                           <tbody>
                              {transferHistory.isLoading ?
                                 <TransferHistorySkeleton />
                                 :
                                 transferList?.data?.length > 0 ?
                                    transferList?.data?.map((n, i) => (
                                       <tr className='animated_div' key={i}>
                                          <td>{(page - 1) * size + (i + 1)}</td>
                                          <td>{Converter.formatDated(n.createdAt)}</td>
                                          <td>{n.FromMemid}</td>
                                          <td>{Converter.formattedValue(n.amount)} ANA</td>
                                          <td>{n.toMemberid}</td>
                                          <td>{n.fee}%</td>
                                          <td className="text-right text-success">Completed</td>
                                       </tr>
                                    ))
                                    :
                                    <tr>
                                       <td colSpan={7}><Nodatafound message="No Transactions found." /> </td>
                                    </tr>
                              }
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>

               <Pagination
                  total={transferList?.count}
                  current={page}
                  size={size}
                  onPageChange={no => setPage(no)}
               />
            </div>
         </div>
      </div>
   )
}
export default TransferHistory;