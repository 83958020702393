import React from 'react'
import { HistorySkeleton } from '../../../Components/Loader/skeleton'
import { NotFound } from '../../Nodata/Nodatafound'
import Converter from '../../../lib/converter'
import { Link } from 'react-router-dom'
import { useDashboard } from '../api/fetchDashboard'
import { networkSymbols } from '../../../lib/network'
import { transactionurl } from '../../../lib/transactionurl'
import { ExternalLink } from 'lucide-react'
function TransactionHistory() {

    const { data: UserTransaction, isLoading } = useDashboard({ page: 1, size: 10 })

    return (
        <div className="card tableCard">
            <div className="cardInnerCnt">
                <div className="table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="">Date & Time</th>
                                <th>Type</th>
                                <th>Transaction ID</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ?
                                <HistorySkeleton />
                                :
                                UserTransaction?.length > 0 ?
                                    UserTransaction?.slice(0, 5).map((n) => (
                                        <tr key={n}>
                                            <td>{Converter.formatDated(n.updatedAt)}</td>
                                            <td>{n.type}</td>
                                            <td style={{ color: "white" }}>{n.transactionHash ?
                                                n.transactionHash?.slice(0, 5) + "..." + n.transactionHash?.slice(40, 45) : "-"}
                                                <a href={transactionurl[n.network] + n.transactionHash} target="_blank" style={{ color: "white" }} className='ml-2'>  <ExternalLink size={20} /> </a>
                                            </td>
                                            <td>{n.amount} {n.currencySymbol} {n?.currencySymbol === 'USDT' && networkSymbols[n?.network]}</td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td colSpan={4}><NotFound message="No Data Found" /></td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
                {UserTransaction?.length > 0 &&
                    <div className='d-flex justify-content-end'>
                        <Link to='/transaction-history' className="viewMre">View more</Link>
                    </div>}
            </div>
        </div>
    )
}

export default TransactionHistory