import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import "../../assets/scss/Innerpages/Innerpages.scss";
import logoLg from "../../assets/images/logoLg.png";
import usdt from "../../assets/icons/usdt.svg";
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import Date from '../../lib/dateformat';
import { useGetIcoDetails, useGetCurrency, useAnawallet } from './api/Anawalletapi';
import { queryClient } from '../../config';
import { ButtonLoader, WaitingLoader } from '../../Components/Loader/buttonLoad';
import { Dots } from '../../lib/dots';
import { useToast } from '../../hook/usetoast';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ddIcon from "../../assets/images/ddIcon.png";
import { Submitbutton } from '../common/Submitbutton';
import { useModalContext } from '../../contexts/ModalContext';
import { networkSymbols } from '../../lib/network';
import { formatAmount } from '../../lib/formatAmount';
import Ended from '../Phasecompletion/Ended';

const validationSchema = yup.object().shape({
  price: yup
    .number()
    .required('Price is required')
    .max(999999,'Maximum 6 digits only')
    .test('not-zero', 'The price should be at least Zero above', (value) => value > 0)
    .test(
      'greater-than',
      'Insufficient balance',
      function (value) {
        const { selectedCurrency } = this.parent;
        if (selectedCurrency && selectedCurrency.balance !== undefined) {
          return value <= selectedCurrency.balance;
        }
        return true;
      }
    )
    .positive('Amount must be a positive number')
    .test('max-decimal-places', 'The amount must have at most 6 decimal places', value => {
      if (value) {
        const numString = formatAmount(value.toString());
        const [, decimal] = numString.includes('.') ? numString.split('.') : [numString, ''];
        return !decimal || decimal.length >= 6;
      }
      return true;
    })
    .typeError('Price is required')
    ,
  currency: yup.string().required('Currency is required')
});



const BussinessStore = () => {
  const generateSecureRandomNumber = () => {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return array[0] % 90000 + 10000; // Generates a random number between 10000 and 99999
    }
  const { data: getIco, isLoading } = useGetIcoDetails();
  const { data: currencies } = useGetCurrency();
  const anaWalletmutation = useAnawallet();
  const { siteSettings, profileData } = useModalContext();
  const [randomData, setRandomData] = useState(Math.floor(100000 + generateSecureRandomNumber() * 900000))
  const [loading, setLoading] = useState(false)

  const defaultValues = {
    price: '',
    currency: 'Select Currency',
    selectedCurrency: null,
    anaPrice: ''
  };
  const toast = useToast()
  const { control, handleSubmit, watch, formState: { errors }, setValue, setError, reset, clearErrors } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const [selectedCurrencyValue, setSelectedCurrencyValue] = useState('Select Currency');

  const handleCurrencyChange = (selectedId) => {
    setSelectedCurrencyValue(selectedId)
  };

  const price = watch('price');
  const selectedCurrency = currencies?.find(c => c._id === selectedCurrencyValue) || "";

  useEffect(() => {
    if (selectedCurrency && price) {
      const hasInsufficientBalance = price > selectedCurrency.balance;
      hasInsufficientBalance ? setError("price", { type: 'required', message: 'Insufficient balance' }) : clearErrors();
    }

    setValue('selectedCurrency', selectedCurrencyValue ? selectedCurrency : null);
  }, [selectedCurrency, selectedCurrencyValue, price, setValue]);

  const anaPrice = price ? (price * (selectedCurrency?.usdPrice || 0) * (getIco?.data?.usdttoANA || 0)).toFixed(2) : 0;

  const onSubmit = async (data) => {
    setLoading(true);

    if (getIco?.data?.allocatedRemainingANA == 0) {
      toast.error(`Allocation completed in this locker, try after ${Math.abs(getIco?.data?.endDay - getIco?.data?.currentDate)} days `);
      setLoading(false);
      return;
    }

    if (anaPrice && Number(anaPrice) < getIco?.data?.minBuy) {
      toast.error(`Minimum you can buy ${getIco?.data?.minBuy} ANA`);
      setLoading(false);
      return;
    }

    if (anaPrice && Number(anaPrice) > ((+getIco?.data?.maxBuy) - (+getIco?.data?.anaBalance))) {
      let errorMsg = ((+getIco?.data?.maxBuy) - (+getIco?.data?.anaBalance)) > 0 ? `Maximum you can buy ${Math.abs(((+getIco?.data?.maxBuy) - (+getIco?.data?.anaBalance))?.toFixed(5))} ANA` : "You have reached maximum amount in this phase"
      toast.error(errorMsg);
      setLoading(false);
      return;
    }

    const obj = {
      currencyAmount: data.price,
      currencySymbol: selectedCurrency.currencySymbol,
      currencyNetwork: selectedCurrency.currencyNetwork,
      ANAamount: Number(anaPrice),
      phaseNumber: getIco?.data.phase,
      currencyType: selectedCurrency.currencyType,
      randomdata: randomData
    };

    let res = await anaWalletmutation.mutateAsync(obj);

    if (res?.status) {
      reset();
      setSelectedCurrencyValue('Select Currency');
      queryClient.refetchQueries('get_ico_details');
      setRandomData(Math.floor(100000 + generateSecureRandomNumber() * 900000));
    }

    setLoading(false);
  }

  let part = '';
  if (getIco?.data?.phase) {
    part = getIco.data.phase.split(' ')[1];
  }
  const startDateLabel = +part + 1 <= 6 ? "Start Date" : "";
  const phaseDateLabel = +part + 1 <= 6 ? "Phase " + (+part + 1) : ''

  const endRoute = '/anawallet'
  return (
    <>
      {loading && <WaitingLoader />}
      {getIco?.message == "All phases end" ? <Ended endRoute={endRoute}/>:<div className="container animated_div">
        <h3 className="secHeading text-center">ICO</h3>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="cardInnerCnt">
                    <div className="row">
                      <div className="col-lg-6 d-flex bdrCnt my-1">
                        <div>
                          <h5 className="cardLabel mb-0 whiteTxt">Ending Date</h5>
                          <h3 className="cardValue mb-0">
                            {isLoading ? <ButtonLoader /> : getIco?.data?.phase || ""}
                          </h3>
                        </div>
                        <div className="dateCnt ml-auto">
                          <h3 className="dateValue mb-0">
                            {isLoading ? <ButtonLoader /> : Date(getIco?.data?.currentPhaseEndDate)}
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-6 d-flex my-1">
                        <div>
                          <h5 className="cardLabel mb-0 whiteTxt">{startDateLabel}</h5>
                          <h3 className="cardValue mb-0">
                            {isLoading ? <ButtonLoader /> : phaseDateLabel}
                          </h3>
                        </div>
                        <div className="dateCnt ml-auto">
                          <h3 className="dateValue mb-0">
                            {isLoading ? <ButtonLoader /> : +part + 1 <= 6 ? Date(getIco?.data?.nextPhaseStartDate) : ""}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-xl-3 d-flex">
                <div className="card p-3 w-100">
                  <div className="cardInnerCnt">
                    <div className="goldCnt">
                      {getIco?.data?.usdttoANA && <h5 className="cardLabel mb-0">1 ANA Coin Price</h5>}
                      <h3 className="cardValue mb-0 d-flex align-items-center">
                        <img src={usdt} width="22" height="22" alt="logoLg" className="mr-2" />
                        {isLoading ? <ButtonLoader /> : getIco?.data?.usdttoANA ? parseFloat((getIco?.data?.usdttoANA)?.toFixed(6)) + " USDT" : ''}
                      </h3>
                    </div>
                    <div className="coinInfoCnt unHgt">
                      <div className="coinInfoRow d-flex align-items-start">
                        <div className="d-flex flex-column">
                          <h5 className="cardLabel mb-0">{part ? "Phase" : ""}</h5>
                          <h5 className="cardLabel whiteTxt mb-0">{part ? 0 : ""}
                            {isLoading ? <ButtonLoader /> : part || ""}
                          </h5>
                        </div>
                      </div>
                      <hr className="my-1" />
                      <div className="coinInfoRow d-flex align-items-start">
                        <div className="d-flex flex-column">
                          <h5 className="cardLabel mb-0">Allocation</h5>
                          <h5 className="cardLabel whiteTxt mb-0">
                            {isLoading ? <ButtonLoader /> :
                              (getIco?.data?.totalAllocation || 0).toLocaleString("en-US", { maximumFractionDigits: 2 }) + " ANA" }
                          </h5>
                        </div>
                      </div>
                      <hr className="my-1" />
                      <div className="coinInfoRow d-flex align-items-start">
                        <div className="d-flex flex-column">
                          <h5 className="cardLabel mb-0">Remaining ANA</h5>
                          <h5 className="cardLabel whiteTxt mb-0">
                            {isLoading ?
                              <ButtonLoader /> : 
                                (getIco?.data?.remainingANA || 0).toLocaleString("en-US", { maximumFractionDigits: 2 }) + " ANA"}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 col-xl-9">
                <div className="card walletCard">
                  <div className="cardInnerCnt">
                    <div className="cardHeader">
                      <div className="d-flex flex-column flex-sm-row align-items-center">

                        <h3>Buy ANA for ANA Wallet</h3>
                        <div className="d-flex ml-sm-auto">
                          <Link to="/anawallet-history" className="outlinedBtn m-1">History</Link>
                        </div>
                      </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <label className="formLabel" htmlFor="price">Enter Value</label>
                        <div className="input-group mb-3">
                          <Controller
                            name="price"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <input
                                {...field}
                                type="number"
                                className={`form-control ${errors.price ? 'is-invalid' : ''}`}
                                id="price"
                                placeholder="0"
                                autoComplete="off"
                                inputMode="decimal"
                                step="0.01"
                                onWheel={(e) => e.target.blur()}
                                onChange={e => {
                                  const value = e.target.value;
                                  const regex = /^\d*(\.\d{0,6})?$/;
                                  if (regex.test(value) || value === "") {
                                    field.onChange(value);
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            )}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              {currencies?.length > 0 ?
                                <Controller
                                  name="currency"
                                  control={control}
                                  render={({ field }) => (

                                    <UncontrolledDropdown>
                                      <DropdownToggle>
                                        {selectedCurrency?.currencyImage && (
                                          <img
                                            src={selectedCurrency?.currencyImage}
                                            width="20"
                                            height="20"
                                            alt={selectedCurrency?.currencySymbol}
                                          />
                                        )}
                                        <span className="mx-2">
                                          {selectedCurrency?.currencySymbol ? selectedCurrency?.currencySymbol : field.value}
                                          {selectedCurrency?.currencySymbol === 'USDT' && networkSymbols[selectedCurrency?.currencyNetwork]}
                                        </span>
                                        <img src={ddIcon} alt="dropdown icon" />
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        {currencies?.filter(item => item.status === true && item._id != selectedCurrency._id).map((currency) => (
                                          <DropdownItem key={currency._id} onClick={() => handleCurrencyChange(currency._id)}>
                                            <div className="d-flex align-items-center">
                                              <img
                                                src={currency.currencyImage}
                                                width="20"
                                                height="20"
                                                alt={currency.currencySymbol}
                                              />
                                              <span className="mx-2">
                                                {currency.currencySymbol}
                                                {currency?.currencySymbol === 'USDT' && networkSymbols[currency?.currencyNetwork]}
                                              </span>
                                            </div>
                                          </DropdownItem>
                                        ))}
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  )}
                                />
                                :
                                <Link to='/wallet' className='prmyTxt'>Deposit Currency</Link>
                              }
                            </span>
                          </div>
                          {errors.price && <div className="invalid-feedback">{errors.price.message}</div>}
                          {errors.currency && <div className="invalid-feedback">{errors.currency.message}</div>}
                        </div>
                        <div className="d-flex flex-column flex-sm-row">
                          {selectedCurrencyValue != "Select Currency" ?
                            < span className="prmyTxt">
                              1 {selectedCurrency.currencySymbol}
                              {selectedCurrency?.currencySymbol === 'USDT' && networkSymbols[selectedCurrency?.currencyNetwork]} - {isLoading ? <ButtonLoader /> : selectedCurrency?.usdPrice} USD
                            </span>
                            :
                            ""
                          }
                          <span className="prmyTxt ml-sm-auto">
                            {selectedCurrency?.balance >= 0 && !isNaN(selectedCurrency.balance) ? `Balance : ${parseFloat(selectedCurrency.balance.toFixed(6))}` : ''}  {isLoading ? <ButtonLoader /> : ''}  {selectedCurrency?.balance >= 0 && !isNaN(selectedCurrency.balance) ? selectedCurrency.currencySymbol : ''}  {selectedCurrency?.currencySymbol === 'USDT' && networkSymbols[selectedCurrency?.currencyNetwork]}
                          </span>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="formLabel" htmlFor="anaPrice">Token Value</label>
                        <div className="input-group mb-3">
                          <Controller
                            name="anaPrice"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <input
                                {...field}
                                type="number"
                                className={`form-control ${errors.anaPrice ? 'is-invalid' : ''}`}
                                id="anaPrice"
                                placeholder="0.0"
                                value={parseFloat(anaPrice)}
                                disabled
                              />
                            )}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <img src={logoLg} width="20" height="20" alt="logo" />
                              <span className="mx-2 whiteTxt">ANA</span>
                            </span>
                          </div>
                          {errors.anaPrice && <div className="invalid-feedback">{errors.anaPrice.message}</div>}
                        
                        </div>
                        <div className="d-flex flex-column flex-sm-row">
                          {selectedCurrency?
                            < span className="blinkText" style={{ color: "red" }}>
                             Min Buy : {getIco?.data?.minBuy} ANA
                              
                            </span>
                            :
                            ""
                          }
                          <span className="prmyTxt ml-sm-auto">
                          {selectedCurrency?
                            < span className='blinkText' style={{ color: "red" }}>
                             Max Buy : {getIco?.data?.maxBuy} ANA
                              
                            </span>
                            :
                            ""
                          }                          </span>
                        </div>
                      </div>
                      <br />
                      <div className="d-flex justify-content-center">
                        {siteSettings?.participate_status && profileData?.kyc_status == 3 && sessionStorage.getItem('type') != 'admin' ?
                          <button
                            className="btn btn-11545 btnGold"
                            type="submit"
                            disabled={selectedCurrencyValue == "Select Currency" || anaWalletmutation.isLoading}
                          >
                            {anaWalletmutation.isLoading ?
                              <Dots>Buying...</Dots>
                              :
                              "Buy Now"}
                          </button>
                          :
                          <Submitbutton
                            buttonText="Submit"
                            loading={anaWalletmutation.isLoading}
                            Status={siteSettings?.participate_status}
                            message="Ana Wallet option disabled. Users can't buy temporarily"
                          />
                        }
                      </div>
                    </form>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <Link to="/anawallet" className="prmyTxt pgLink">Go to ANA Wallet</Link>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>}
    </>
  );
};

export default BussinessStore;