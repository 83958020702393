import React, { useEffect, useState } from 'react'
import xlsIcon from "../../../assets/images/xlsIcon.png";
import { useWalletHistory } from '../api/Anawalletapi';
import { useExportDocs } from '../../Transfer/api/fetchTransfer';
import Pagination from '../../common/pagination/Pagination';
import { useDebounce } from '../../../hook/useDounce';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import * as XLSX from "xlsx";
import { useModalContext } from '../../../contexts/ModalContext';
import { ButtonLoader } from '../../../Components/Loader/buttonLoad';
import Converter from '../../../lib/converter';
import { TransferHistorySkeleton } from '../../../Components/Loader/skeleton';
import Nodatafound from '../../Nodata/Nodatafound';

function ReceivedHistory() {

    const [page, setPage] = useState(1)
    const size = 10;
    const [filter, setFilter] = useState('')
    const [dayFilter, setDayFilter] = useState("");
    const searchText = useDebounce(filter)
    const walletHistorymutation = useWalletHistory();
    const { profileData } = useModalContext();

    const [transferList, setTransferList] = useState({});
    const exportmutation = useExportDocs();

    useEffect(() => {
        fetchData();
    }, [page, searchText,dayFilter])

    const fetchData = async () => {
        let obj = {
            page: page,
            size: size,
            filter: searchText,
            dayFilter: dayFilter,
            type: "receiveHistory",
        }
        let res = await walletHistorymutation.mutateAsync(obj)
        if (res?.status) {
            setTransferList(res)
        } else setTransferList({})
    }

    const handleDownload = async () => {
        let res = await exportmutation.mutateAsync({ type: "receiveHistory",  filter: searchText,
            dayFilter: dayFilter,});
        if (res?.status) {
            const header = Object.keys(res?.data[0]);
            const sheet = XLSX.utils.json_to_sheet(res?.data, { header });

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, sheet, "Sheet1");

            XLSX.writeFile(wb, `${profileData?.memberID}-Receive-History.xlsx`);
        }
    };


    return (
        <div className="col-12">
            <div className="d-flex align-items-center flex-wrap">
                <h3 className="cardTitle lgTitle">Receive History</h3>
                <div className="d-flex align-items-center filterCnt mb-3 ml-auto flex-wrap flex-md-nowrap">
                    <input
                        onChange={(e) => setFilter(e.target.value)}
                        type="search"
                        placeholder="Search"
                        className={(filter ? "form-control my-1" : "form-control searchbox my-1") }  />

                    <div className='octDrpdown dark mx-1'>
                        <UncontrolledDropdown>
                            <DropdownToggle caret>
                            {dayFilter === "7" ? "1 Week Ago" : dayFilter === "30" ? "1 Month Ago" : "Recently"}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem tag={"a"} type="button" onClick={() => setDayFilter("")}>Recently</DropdownItem>
                                <DropdownItem tag={"a"} type="button" onClick={() => setDayFilter("7")}>1 Week Ago</DropdownItem>
                                <DropdownItem tag={"a"} type="button" onClick={() => setDayFilter("30")}>1 Month Ago</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>

                    <div className="float-right">
                        {exportmutation.isLoading || walletHistorymutation.isLoading ?
                            <button className="btn filterBtn my-1" disabled>
                                <ButtonLoader />
                            </button>
                            :
                            <button className="btn filterBtn my-1"
                                disabled={Boolean(transferList?.data?.length <= 0) || !transferList?.data}
                                onClick={handleDownload}
                            ><img src={xlsIcon} width="24" height="24" alt="xlsIcon" />
                                Export File
                            </button>}
                    </div>
                </div>
            </div>

            <div className="card tableCard">
                <div className="cardInnerCnt">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Type</th>
                                    <th>Date and Time</th>
                                    <th>From</th>
                                    <th>Store</th>
                                    <th>Amount</th>
                                    <th>Phase</th>
                                    <th className="text-right">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {walletHistorymutation.isLoading ?
                                    <TransferHistorySkeleton />
                                    :
                                    transferList?.data?.length > 0 ?
                                        transferList?.data?.map((n, i) => (
                                            <tr className='animated_div' key={n}>
                                                <td>{(page - 1) * size + (i + 1)}</td>
                                                <td>Receive</td>
                                                <td>{Converter.formatDated(n.createdAt)}</td>
                                                <td>{n.FromMemid}</td>
                                                <td className='text-capitalize'>{n.type == '---' ? "Stake" : n.type} {n?.type == 'Admin' || n?.type == '--' ||  n?.type == 'Non-Staker' || n?.type == 'ANA Wallet' || n?.type == 'Admin LP Transfer'? '' :"Store"}</td>
                                                <td>{Number(n?.stakeValue)} ANA</td>
                                                <td>Phase {n.phase}</td>
                                                <td className="text-right text-success">Completed</td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td colSpan={8}><Nodatafound message="No Data found." /> </td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Pagination
                total={transferList?.count}
                current={page}
                size={size}
                onPageChange={no => setPage(no)}
            />
        </div>
    )
}

export default ReceivedHistory