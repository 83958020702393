
import React, { useEffect, useState } from 'react'
import { useCheckAdminJwt } from './api/index'
import { useNavigate, useParams } from 'react-router'
import { useToast } from '../../hook/usetoast'
import Loader from '../../Components/Loader/Loader'
import Nodatafound from '../Nodata/Nodatafound'
import Cookies from 'js-cookie';

const Adminview = () => {
    const toast = useToast()
    const navigate = useNavigate()
    const { token } = useParams()
    const [isLoading, setIsLoading] = useState(true)

    const checkAdminView = useCheckAdminJwt()

    useEffect(() => {
        setIsLoading(true)
        checkAdminView.mutate({ token: token })
    }, [])

    useEffect(() => {
        if (checkAdminView.isSuccess) {
            if (checkAdminView.status) {
                sessionStorage.setItem('token_ana', token)
                sessionStorage.setItem('type', 'admin')
                Cookies.set('sdgsdsadgt','twrtcvysargsgy')
                setTimeout(() => {
                    setIsLoading(false)
                    navigate("/dashboard")
                }, 4000);
            } else {
                setIsLoading(false)
                toast.error('Something went wrong.')
            }
        }
    }, [checkAdminView.isSuccess])

    return <div>
        {isLoading ? <Loader loaded={isLoading} /> : !checkAdminView.status ? <Nodatafound message={"Something went wrong."} /> : ""}
    </div>
}

export default Adminview
