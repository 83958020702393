import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import "../../assets/scss/Innerpages/Innerpages.scss";
import logoLg from "../../assets/images/logoLg.png";
import eyeSlashIcon from "../../assets/images/Auth/eye-slash.png";
import eyeIcon from "../../assets/images/Auth/eye.png";
import { useBalance } from '../common/fetchBalance';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTransfer, useTransferFee } from './api/fetchTransfer';
import { ButtonLoader } from '../../Components/Loader/buttonLoad';
import { BounceInDownButton } from '../../Components/animation/bouncein';
import { useModalContext } from '../../contexts/ModalContext';
import { Submitbutton } from '../common/Submitbutton';
import { useGetCurrentPhase } from '../StakeStore/api/fetchstakestore';
import Converter from '../../lib/converter';
import { useDebounce } from '../../hook/useDounce';
import { useToast } from '../../hook/usetoast';
import { formatAmount } from '../../lib/formatAmount';
import { generateSecureRandomNumber } from '../../hook/generateSecureRandomNumber';



const BusinessTransfer = () => {

   const { data: getBalance, isLoading } = useBalance();

   const schema = yup.object().shape({
      ana: yup.number()
         .typeError('Transfer amount is Required')
         .required('Transfer amount is Required')
         .positive('Amount must be in positive values')
         .min(1, 'Minimum amount must be 1')
         .test('not-zero', 'The price should be at least Zero above', (value) => value > 0)
         .test('max-decimal-places', 'The amount must have at most 6 decimal places', value => {
            if (value) {
               const numString = formatAmount(value.toString());
               const [, decimal] = numString.includes('.') ? numString.split('.') : [numString, ''];
               return !decimal || decimal.length >= 6;
            }
            return true;
         })
         .test(
            'greater-than',
            'Insufficient balance',
            function (value) {
               if (getBalance && getBalance?.transferStore !== undefined) {
                  return +value <= getBalance?.transferStore;
               }
               return true;
            }
         ),
      MPIN: yup.string()
         .required('MPIN is Required')
         .matches(/^[0-9]{6}$/, 'MPIN must be 6 digits'),
      memberId: yup.string()
         .required('Member ID is Required')
         .matches(/[A-Z0-9]/, 'Member ID invalid'),
   })
   const { siteSettings } = useModalContext()

   const transferMutation = useTransfer();
   const [amount, setAmount] = useState(0);
   const amountVal = useDebounce(amount)
   const transferFeeMutation = useTransferFee()
   const { data: currentPhasemutation } = useGetCurrentPhase()
   const toast = useToast()
   const [show, setShow] = useState(false)
   const [fee, setFee] = useState(0)
   const toggle = (e) => { if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") { setShow(!show) } }

   const { control, handleSubmit, formState: { errors }, reset } = useForm({
      mode: 'onChange',
      resolver: yupResolver(schema),
      defaultValues: {
         ana: "",
         MPIN: "",
         memberId: "",
      }
   });

   const handleKeyDown = e => e.target.value = e.target.value.match(/^[0-9]{0,6}/)[0]
   const checkChar = (e) => {
      if (!/[A-Z0-9]/.test(e.key)) {
         e.preventDefault();
      }
   };

   let x = Math.floor(generateSecureRandomNumber() * 90000) + 10000;
   const onSubmit = async (data) => {
      try {
         if (getBalance?.transferStore < data?.ana) {
            toast.error('Insufficient Balance')
         } else {
            data["randomId"] = x.toString();
            data["phase"] = currentPhasemutation?.phase;
            let res = await transferMutation.mutateAsync(data)
            if (res.status) reset()
         }
      } catch (error) {

      }
   }

   useEffect(() => {
      getFee();
   }, [amountVal])

   const [totalamount, setTotalamount] = useState('')

   const getFee = async () => {
      const response = await transferFeeMutation.mutateAsync({ amount: amountVal, type: "transferFee" })
      if (response?.status) {
         setFee(response?.data?.fee)
         const a = +amountVal * (+response?.data?.fee / 100)
         setTotalamount((+amountVal) + +a)
      }
      else {
         setTotalamount(0)
      }
   }

   return (
      <div className="container animated_div">
         <h3 className="secHeading text-center">Transfer</h3>
         <BounceInDownButton>
            <div className="row justify-content-center">
               <div className='col-lg-10'>
                  <div className="row justify-content-center">

                     <div className="col-lg-6 col-xl-7">
                        <div className="card walletCard">
                           <div className="px-3 cardInnerCnt">
                              <div className="cardHeader">
                                 <div className="d-flex flex-column flex-sm-row align-items-center">
                                    <h3>Business Transfer</h3>
                                    <div className="d-flex ml-sm-auto">
                                       <Link to="/transfer-history" className="outlinedBtn m-1">History</Link>
                                    </div>
                                 </div>
                              </div>
                              <form onSubmit={handleSubmit(onSubmit)}>
                                 <div className="form-group">
                                    <label className="formLabel" htmlFor="Id1">ANACOIN</label>
                                    <div className="input-group mb-3">
                                       <Controller name='ana' control={control} render={({ field: { value, onChange, ...field } }) => (
                                          <input
                                             {...field}
                                             type='number'
                                             className={`form-control  ${errors.ana && "is-invalid"}`}
                                             maxLength={6}
                                             onChange={(e) => {
                                                onChange(e.target.value)
                                                setAmount(e.target.value);
                                             }}
                                             placeholder="0.0"
                                             autoComplete='off'
                                             onKeyDown={(e) => {
                                                if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                                                   e.preventDefault();
                                                }
                                             }}
                                          />
                                       )} />

                                       <div className="input-group-append">
                                          <span className="input-group-text">
                                             <img src={logoLg} width="20" height="20" alt="usdt" />
                                             <span className="ml-2">ANA</span>
                                          </span>
                                       </div>
                                    </div>
                                    <div className="d-flex flex-column flex-sm-row">
                                       {errors.ana && <p className="errMsg">* {errors.ana.message}</p>}
                                       {isLoading ?
                                          <span className="prmyTxt ml-sm-auto"><ButtonLoader /></span> :
                                          <span className="prmyTxt ml-auto">{getBalance?.transferStore > 0 ? "Balance: " + Converter.formattedValue(getBalance?.transferStore) + " ANA" : getBalance?.transferStore == 0 ? "Balance: 0 ANA" : ""} </span>}
                                    </div>
                                 </div>
                                 <div className="form-group">
                                    <label className="formLabel" htmlFor="Id2">Member ID</label>
                                    <Controller name='memberId' control={control} render={({ field }) => (
                                       <input
                                          {...field}
                                          type="text"
                                          className={`form-control  ${errors.memberId && "is-invalid"}`}
                                          placeholder="Enter Your Member ID"
                                          onKeyPress={checkChar}
                                          autoComplete='off'
                                          autoFocus
                                       />
                                    )} />
                                    {errors.memberId && <p className="errMsg">* {errors.memberId.message}</p>}
                                 </div>
                                 <div className="form-group">
                                    <label className="formLabel" htmlFor="Id3">MPIN</label>
                                    <div className="input-group mb-3">
                                       <Controller name='MPIN' control={control} render={({ field }) => (
                                          <input
                                             {...field}
                                             type={show ? "text" : "password"}
                                             className={`form-control  ${errors.MPIN && "is-invalid"}`}
                                             placeholder="Enter Your MPIN"
                                             maxLength={6}
                                             onInput={handleKeyDown}
                                             autoFocus
                                             autoComplete='off'
                                          />
                                       )} />
                                       <div className="input-group-append">
                                          <span className="input-group-text">
                                             {show ?
                                                <img src={eyeIcon} width="20" height="20" alt="eyeSlashIcon" onClick={(e) => toggle(e)} onKeyDown={(e) => toggle(e)} />
                                                :
                                                <img src={eyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" onClick={(e) => toggle(e)} onKeyDown={(e) => toggle(e)} />
                                             }
                                          </span>
                                       </div>
                                    </div>
                                    {errors.MPIN && <p className="errMsg">* {errors.MPIN.message}</p>}
                                    <div className="d-flex flex-column flex-sm-row" style={{ justifyContent: 'space-between' }}>
                                       <div>
                                          <span className="greyTxt">Total Amount:</span>
                                          <span className="whiteTxt ml-2">{totalamount || 0}</span>
                                       </div>
                                       <div>
                                          <span className="greyTxt">Fees:</span>
                                          <span className="whiteTxt ml-2">{fee || 0}%</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="d-flex justify-content-center mt-3">
                                    <Submitbutton
                                       buttonText="Submit"
                                       loading={transferMutation.isLoading}
                                       Status={siteSettings?.transfer_status}
                                       message="Transfer option disabled. Users can't transfer funds temporarily"
                                    />
                                 </div>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </BounceInDownButton>
      </div>
   )
}
export default BusinessTransfer;