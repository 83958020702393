import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useCheckLink, useResetPass } from './api/authmutations';
import { Dots } from '../../lib/dots';
import { Link, useParams } from 'react-router-dom';
import { BounceInDownButton } from '../../Components/animation/bouncein';
import EyeSlashIcon from '../../assets/images/Auth/eye-slash.png'
import EyeIcon from '../../assets/images/Auth/eye.png'
import { WaitingLoader } from '../../Components/Loader/buttonLoad';
import { generateSecureRandomNumber } from '../../hook/generateSecureRandomNumber';
const schema = yup.object().shape({
    MPIN: yup.string().required('MPIN is Required'),
    password: yup.string()
        .min(6, 'Password must be at least 6 characters')
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,30}$/, 'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character')
        .max(30, 'Password must be at most 30 characters')
        .required('Password is Required'),
    passwordConfirm: yup.string()
        .oneOf([yup.ref('password'), null], "Confirm Password doesn't match")
        .required('Confirm Password is Required'),
})

const ResetPassword = () => {

    const checklinkmutation = useCheckLink()
    const signinMutation = useResetPass()
    const { token } = useParams();
    const [loading, setLoading] = useState(false)

    const [show, setShow] = useState(true);
    const [showCon, setShowCon] = useState(true);
    const [mpshow, setMpshow] = useState(true);

    const { control, handleSubmit, formState: { errors, isValid, isDirty }, reset } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            MPIN: '',
            password: "",
            passwordConfirm: "",
        }
    });

    useEffect(() => {
        CheckvalidLink();
    }, [])

    const CheckvalidLink = async () => {

        await checklinkmutation.mutateAsync({ link: token })
    }

    const handleKeyDown = e => e.target.value = e.target.value.match(/^[0-9]{0,6}/)[0]

    const onSubmit = async (data) => {
        setLoading(true)
        let random = Math.floor(generateSecureRandomNumber() * 10000)
        sessionStorage.setItem('random', random)
        data["link"] = token;
        data["random"] = random
        await signinMutation.mutateAsync(data)
        setLoading(false)
        reset()
    }

    const showPassword = e =>{
        if(e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch"){
           setShow(!show)
        }
    }

    const showConPassword = e =>{
        if(e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch"){
          setShowCon(!showCon)
        }
     }

     const showMpShow = e =>{
        if(e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch"){
            setMpshow(!mpshow)
        }
     }

    return (
        <BounceInDownButton>
            {loading && <WaitingLoader />}
            <div className="row justify-content-center animated_div">
                <div className="col-lg-6 col-xl-4">
                    <h3 className="modalHeading">Reset Password</h3>
                    <p className="modalSubHeading">Create a New Password</p>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="form-group">
                            <label className="formLabel" htmlFor="regId7">Password</label>
                            <div className="input-group mb-3">
                                <Controller name='password' control={control} render={({ field }) => (
                                    <input
                                        {...field}
                                        type={show ? "password" : "text"}
                                        className="form-control"
                                        placeholder='Enter your password'
                                        autoComplete='off'
                                    />
                                )} />
                                <div className="input-group-append">
                                    <span className="input-group-text" type="button" onClick={(e) => showPassword(e)} onKeyDown={(e) => showPassword(e)}>
                                        {show ? <img src={EyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" /> :
                                            <img src={EyeIcon} width="20" height="20" alt="eyeSlashIcon" />}
                                    </span>
                                </div>
                            </div>
                            {errors.password && <p className="errMsg">* {errors.password.message}</p>}
                        </div>

                        <div className="form-group">
                            <label className="formLabel" htmlFor="regId8">Confirm Password</label>
                            <div className="input-group mb-3">
                                <Controller name='passwordConfirm' control={control} render={({ field }) => (
                                    <input
                                        {...field}
                                        type={showCon ? "password" : "text"}
                                        className="form-control"
                                        id="regId8" placeholder="Confirm your password"
                                        autoComplete='off'
                                    />
                                )} />
                                <div className="input-group-append">
                                    <span className="input-group-text" type="button" onClick={(e) => showConPassword(e)} onKeyDown={(e) => showConPassword(e)}>
                                        {showCon ? <img src={EyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" /> :
                                            <img src={EyeIcon} width="20" height="20" alt="eyeSlashIcon" />}
                                    </span>
                                </div>
                            </div>
                            {errors.passwordConfirm && <p className="errMsg">* {errors.passwordConfirm.message}</p>}
                        </div>

                        <div className="form-group">
                            <label className="formLabel" htmlFor="regId9">MPIN</label>
                            <div className="input-group mb-3">
                                <Controller name='MPIN' control={control} render={({ field }) => (
                                    <input
                                        {...field}
                                        type={mpshow ? "password" : "text"}
                                        className="form-control"
                                        maxLength={6}
                                        onInput={handleKeyDown}
                                        id="regId9" placeholder="Enter your MPIN"
                                        autoComplete='off'
                                    />
                                )} />
                                <div className="input-group-append">
                                    <span className="input-group-text" type="button" onClick={(e)=>showMpShow(e)} onKeyDown={(e)=>showMpShow(e)}>
                                        {mpshow ? <img src={EyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" /> :
                                            <img src={EyeIcon} width="20" height="20" alt="eyeSlashIcon" />}
                                    </span>
                                </div>
                            </div>
                            {errors.MPIN && <p className="errMsg">* {errors.MPIN.message}</p>}
                        </div>

                        <div className="d-flex justify-content-center mb-4">
                            <button className="btn btn-11545 btnGold" disabled={!isValid || !isDirty || signinMutation.isLoading}>
                                {signinMutation.isLoading ? <Dots>Loading</Dots> : "Reset Password"}
                            </button>
                        </div>
                        <p className="text-center">
                            <Link to='/login' className="yellowTxt mx-2">Back to Login</Link>
                        </p>
                    </form>
                </div>
            </div>
        </BounceInDownButton>
    )
}
export default ResetPassword;