export const copyToClipboard = async (text) => {
	if (navigator.clipboard && navigator.permissions) {
		return navigator.clipboard.writeText(text)
	} else if (document.queryCommandSupported('copy')) {
		const ele = document.createElement('textarea')
		ele.value = text
		document.body.appendChild(ele)
		ele.select()
		document.execCommand('copy')
		document.body.removeChild(ele)
		return
	}
}
