import { createContext, useContext, useState } from "react";
import PropTypes from 'prop-types';
const ModalContext = createContext();

export const useModalContext = () => useContext(ModalContext);

export const ModalContextProvider = ({ children }) => {

  const [siteSettings, setSitesettings] = useState({});
  const [profileData, setprofileData] = useState({});
  const [isLoading, setisLoading] = useState(true)
  const [minwithdraw, setMinWithdraw] = useState()
  const [mindeposit, setMinDeposit] = useState()
  const [isLoggedIn, setIsLoggedIn] = useState("")
  const [hisupdated, setHisupdated] = useState(false);
  const [countryuser, setCountryuser] = useState("IN");
  const [countryverified, setCountryverified] = useState(false);

  return (
    <ModalContext.Provider
      value={{
        siteSettings, setSitesettings,
        profileData, setprofileData,
        isLoading, setisLoading,
        minwithdraw, setMinWithdraw,
        mindeposit, setMinDeposit,
        isLoggedIn, setIsLoggedIn,
        hisupdated, setHisupdated,
        countryuser, setCountryuser,
        countryverified,setCountryverified
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

ModalContextProvider.propTypes = {
    children : PropTypes.any,
}
