import { Link } from "react-router-dom";
import "../../assets/scss/Footer/Footer.scss";
import cloudIcon from "../../assets/images/Footer/downloadCloud.png";
import { useFetchDocs } from "../Header/api";
import { useModalContext } from "../../contexts/ModalContext";
import { useEffect } from "react";
import PropTypes from 'prop-types';

const Footer = ({ arrdata }) => {
   const { siteSettings } = useModalContext();
   const { data: Docs } = useFetchDocs();

   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      })
   }, [])


   const onButtonClick = () => {
      const pdfUrl = Docs?.data[0]?.newsbanner;
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "doc.pdf"; 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
   };
   return (
      <footer data-scroll-section>
         <div className="container">

            <div className="ftadRf">
               <div className="adRfBx" data-scroll data-scroll-speed="2" data-scroll-offset="100,50%">
                  <div className="row align-items-center justify-content-between gy-3">
                     <div className="col-xl-8">
                        <div className="adRfBxTxIP">
                           <div className="adRfBxTx">{arrdata?.status ? arrdata?.data[13]?.title : ''}</div>
                           <div className="adRfBxIp">
                              <p dangerouslySetInnerHTML={{ __html: arrdata?.status ? arrdata?.data[13]?.pagecontent : '' }} />
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-4 d-flex justify-content-xl-center">
                        <div className="adRfLk">
                           <Link to="/" className="btn btn-20555 btn-blkOl" onClick={onButtonClick}>
                              <img src={cloudIcon} height="32" width="32" alt="cloudIcon" className="mr-2" />Download
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="ftrBx">
               <div className="row justify-content-between gy-4">
                  <div className="col-md-auto col-lg-auto order-2 order-lg-1">
                     <div className="ftMenu">
                        <h5 className="ftMenuTl">Quick Link</h5>
                        <ul>
                           <li>
                              <Link to="/about">About Us</Link>
                           </li>
                           <li>
                              <Link to="/terms">Terms and Conditions</Link>
                           </li>
                           <li>
                              <Link to="/privacy">Privacy Policy</Link>
                           </li>
                           <li>
                              <Link to="/faq">FAQ</Link>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-lg order-1 order-lg-2">
                     <div className="ftCon">
                        <div className="ftLogo">
                           <Link to="/">
                              <img src={require("../../assets/images/logo.png")} alt="logo" className="img-fluid" />
                           </Link>
                        </div>
                        <div className="ftTx">
                           <p>{siteSettings?.description}</p>
                        </div>
                        <div className="ftScl">
                           <ul>
                              <li>
                                 <a href={siteSettings?.facebook} target="_blank">
                                    <img src={require("../../assets/icons/facebook.svg").default} alt="facebook" className="img-fluid" />
                                 </a>
                              </li>
                              <li>
                                 <a href={siteSettings?.telegram} target="_blank">
                                    <img src={require("../../assets/icons/telegram.svg").default} alt="telegram" className="img-fluid" />
                                 </a>
                              </li>
                              <li>
                                 <a href={siteSettings?.twitter} target="_blank">
                                    <img src={require("../../assets/icons/twitter.svg").default} alt="twitter" className="img-fluid" />
                                 </a>
                              </li>
                              <li>
                                 <a href={siteSettings?.linkedin} target="_blank">
                                    <img src={require("../../assets/icons/linkedin.svg").default} alt="linkedin" className="img-fluid" />
                                 </a>
                              </li>
                              <li>
                                 <a href={siteSettings?.discord} target="_blank">
                                    <img src={require("../../assets/icons/reddit.svg").default} alt="reddit" className="img-fluid" />
                                 </a>
                              </li>
                              <li>
                                 <a href={siteSettings?.instagram} target="_blank">
                                    <img src={require("../../assets/icons/instagram.svg").default} alt="instagram" className="img-fluid" />
                                 </a>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>

                  <div className="col-md-auto col-lg-auto order-3">
                     <div className="ftMenu">
                        <h5 className="ftMenuTl">Contact Us</h5>
                        <ul>
                           <li>
                              <Link to="/">{siteSettings?.contactMail}</Link>
                           </li>
                           {/* <li>
                              <Link to="/">{siteSettings?.supportMail}</Link>
                           </li> */}
                        </ul>
                     </div>
                  </div>
               </div>
            </div>


         </div>
      </footer>
   )
}
Footer.propTypes ={
   arrdata: PropTypes.shape({
         status: PropTypes.any,
         data: PropTypes.arrayOf(
            PropTypes.shape({
               title: PropTypes.any,
               pagecontent : PropTypes.any
            })
        ),
    }), 
}

export default Footer;