import { Fragment, useEffect, useState } from "react";
import { useSendOTP, useVerifyOTP } from "../api/authmutations";
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ButtonLoader } from "../../../Components/Loader/buttonLoad";
import { Dots } from "../../../lib/dots";
import { Timer } from "../../../lib/expireTime";
import { CheckCircle } from "lucide-react";
import PropTypes from 'prop-types';
import { generateSecureRandomNumber } from "../../../hook/generateSecureRandomNumber";
const EMAILID_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const schema = yup.object().shape({
    octEmail: yup.string()
        .matches(EMAILID_REGEX, "Invalid Email")
        .max(140, 'Email must be at most 140 characters')
        .required('Email is Required'),
    otp: yup.string(),
})


const VerifyEMail = ({ setValue, ismailVerified, setIsmailVerified,islinkVerified }) => {
    const [mailComplete, setMailComplete] = useState(false);
    const [mailOTP, setMailOTP] = useState("");
    const [waitingTime, setWaitingTime] = useState(false);

    const sendmutation = useSendOTP()
    const verifyOTPmutation = useVerifyOTP()

    const { control, handleSubmit, formState: { errors }, getValues, clearErrors, setError } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            octEmail: "",
            otp: ""
        }
    });

    useEffect(() => {
        let interval = setTimeout(() => {
            setWaitingTime(false);
        }, 60000);

        return () => {
            clearInterval(interval);
        };
    }, [waitingTime])

    const handleKeyDown = e => e.target.value = e.target.value.match(/^[0-9]{0,6}/)[0]

    const sendMailOTP = async (data) => {
        let random = Math.floor(generateSecureRandomNumber() * 10000)
        sessionStorage.setItem('random', random)
        let obj = {
            "type": "Email",
            "email": data.octEmail,
            "random": random
        }
        let res = await sendmutation.mutateAsync(obj)
        if (res?.status) {
            setWaitingTime(true)
            setMailComplete(true)
        }
    }

    const verifyMailOTP = async (e) => {
        if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
            clearErrors("otp")
            if (!mailOTP) {
                return setError("otp", { message: "OTP is required" })
            } else if (mailOTP.length !== 6) {
                return setError("otp", { message: "Invalid OTP" })
            }

            if (!getValues("octEmail")) {
                return setError("octEmail", { message: "Email is Required" })
            }

            let obj = {
                "type": "Email",
                "email": getValues("octEmail"),
                "email_OTP": mailOTP
            }

            let res = await verifyOTPmutation.mutateAsync(obj)
            if (res?.status) {
                setValue("email", getValues("octEmail"))
                setIsmailVerified(true);
            } else setMailOTP('')
        }
    }

    return (
        <Fragment>
            <div className="col-lg-6">
                <div className="form-group">
                    <label className="formLabel" htmlFor="regId3">Email ID</label>
                    <div className="input-group mb-3">
                        <Controller name='octEmail' control={control} render={({ field }) => (
                            <input
                                {...field}
                                type="text"
                                className="form-control"
                                disabled={ismailVerified || !islinkVerified}
                                placeholder='Enter your email'
                                autoComplete='off'
                            />
                        )} />
                        <div className="input-group-append">
                            {ismailVerified ?
                                <span className="input-group-text">
                                    <CheckCircle size={20} color="#05af05" />
                                </span>
                                :
                                sendmutation.isLoading ?
                                    <span className="input-group-text yellowTxt">
                                        <Dots>Sending</Dots>
                                    </span>
                                    :
                                    waitingTime ?
                                        <span className="input-group-text yellowTxt">
                                            <Timer />
                                        </span>
                                        :
                                        <span className="input-group-text yellowTxt" type="button" onClick={handleSubmit(sendMailOTP)} onKeyDown={handleSubmit(sendMailOTP)}>
                                            {mailComplete ? "Re-send" : "Send OTP"}
                                        </span>
                            }
                        </div>
                    </div>
                    {errors.octEmail && <p className="errMsg">* {errors.octEmail.message}</p>}
                </div>
            </div>

            <div className="col-lg-6">
                <div className="form-group">
                    <label className="formLabel" htmlFor="regId4">OTP send to Email ID</label>
                    <div className="input-group mb-3">
                        <input type="tel"
                            className="form-control"
                            maxLength={6}
                            value={mailOTP}
                            disabled={!mailComplete || ismailVerified}
                            onChange={(e) => setMailOTP(e.target.value)}
                            onInput={handleKeyDown}
                            placeholder="Enter your OTP"
                        />
                        <div className="input-group-append">
                            {ismailVerified ?
                                <span className="input-group-text grnTxt">
                                    Verified
                                </span>
                                :
                                <span className="input-group-text yellowTxt" type="button"
                                    disabled={verifyOTPmutation.isLoading}
                                    onKeyDown={(e)=>verifyMailOTP(e)}
                                    onClick={(e)=>verifyMailOTP(e)}>
                                    {verifyOTPmutation.isLoading ?
                                        <ButtonLoader />
                                        :
                                        "Verify"}
                                </span>
                            }
                        </div>
                    </div>
                    {errors.otp && <p className="errMsg">* {errors.otp.message}</p>}
                </div>
            </div>
        </Fragment>
    )

}
VerifyEMail.propTypes = {
    setValue : PropTypes.any,
    ismailVerified : PropTypes.any,
    setIsmailVerified : PropTypes.any,
    islinkVerified : PropTypes.any,
}

export default VerifyEMail;