import React, { useState, useEffect } from 'react'
import copyIcon from "../../../assets/images/copyIcon.svg";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useDeposite, useCurrency } from "../api/depositewithdrawapi";
import { useToast } from "../../../hook/usetoast";
import { CheckCircle } from "lucide-react";
import { QRLoader } from '../../../Components/Loader/buttonLoad';
import { queryClient } from '../../../config';
import { networkSymbols } from '../../../lib/network';
import { useModalContext } from '../../../contexts/ModalContext';


const Deposite = () => {

  const toast = useToast()

  const [inputValue, setInputValue] = useState("");
  const [inputImg, setInputImg] = useState("");

  const [selectedCurr, setSelectedCurr] = useState({});
  const [isCopied, setIsCopied] = useState(false);
  const [error, setError] = useState(false);
  const [curnetwork, setCurnetwork] = useState('')
  const { setMinDeposit } = useModalContext();

  const depositaddress = useDeposite()
  const { data: UserCurrency, isSuccess } = useCurrency()

  useEffect(() => {
    if (isSuccess)
      defaultcurrency()
  }, [isSuccess])

  const defaultcurrency = async () => {

    const defaultCurrencyObject = UserCurrency?.find(currency => currency.defaultCurrency === 2);
    setMinDeposit(defaultCurrencyObject?.minimumDeposit)

    const data = {
      "currency": defaultCurrencyObject?.currencySymbol,
      "currencyNetwork": defaultCurrencyObject?.network
    }

    const res = await depositaddress.mutateAsync(data)
    if (res?.status) {
      setInputValue(defaultCurrencyObject?.currencySymbol)
      setInputImg(defaultCurrencyObject?.currencyImage)
      setSelectedCurr(res?.data);
      queryClient.refetchQueries("withdrawHistory")
    } else setError(true)
  }


  const handleChange = async (item) => {
    setInputValue(item.currencySymbol);
    setInputImg(item?.currencyImage)
    setCurnetwork(item)
    setMinDeposit(item.minimumDeposit)
    setError(false);

    const data = {
      "currency": item.currencySymbol,
      "currencyNetwork": item.network
    }

    const res = await depositaddress.mutateAsync(data)
    if (res?.status) {
      setSelectedCurr(res?.data);
      queryClient.refetchQueries("withdrawHistory")
    } else setError(true)
  };


  const copytoClip = async (e,Address) => {
    if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
    toast.copy("Wallet address copied")
    setIsCopied(true)
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(Address);
    } else {
      document.execCommand("copy", true, Address);
    }
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  }
  }

  return (
    <form className="form-row">
      <div className="col-lg-7">
        <div className="form-group">
          <label className="formLabel" htmlFor="loginId1">Select</label>

          <UncontrolledDropdown >
            <DropdownToggle caret>
              {inputImg ?
                <>
                  < img src={inputImg} width="20" height="20" alt="" className='mr-2' />
                   {inputValue} {curnetwork?.currencySymbol === 'USDT' && networkSymbols[curnetwork?.network]}
                </> : null}
            </DropdownToggle>
            <DropdownMenu >
              {UserCurrency?.filter(c => c.status && c._id != curnetwork._id).map((n, index) => (
                <DropdownItem className='octDrpdown w-100' tag="a" key={n} onClick={() => handleChange(n)}>
                  <span className="mx-2"> <img src={n.currencyImage} width="20" height="20" alt="" className='mr-2' />
                    {n.currencySymbol} {n?.currencySymbol === 'USDT' && networkSymbols[n?.network]}</span>
                </DropdownItem>
              ))}
            </DropdownMenu>
            
          </UncontrolledDropdown>
      
        </div>
        <div className="form-group">
          <label className="formLabel" htmlFor="loginId2">Wallet Address</label>
          <div className="input-group mb-3">
            <input type="text"
              className={`form-control prmyTxt ${error && "is-invalid"}`}
              readOnly
              value={error ? `Error loading wallet` : depositaddress.isLoading ? 'Loading...' : selectedCurr?.address}
            />
            {depositaddress.isLoading ?
              ""
              :
              <div className="input-group-append">
                <span className="input-group-text" type='button'>
                  {isCopied ? <CheckCircle width={25} color="#F8DC8F" className="ml-2" />
                    :
                    selectedCurr?.address &&
                    <img src={copyIcon} width="20" height="20" alt="copyIcon" onClick={(e) => copytoClip(e,selectedCurr?.address)} onKeyDown={(e) => copytoClip(e,selectedCurr?.address)}/>
                  }
                </span>
              </div>
            }
          </div>
        </div>
      </div>
      <div className="col-lg-5 d-flex flex-column align-items-center">
        <label className="formLabel" htmlFor="loginId3">Scan QR Code</label>
        {depositaddress.isLoading ?
          <QRLoader />
          :
          selectedCurr?.qrImage ?
            <img src={selectedCurr?.qrImage} width="242" height="242" alt="qrcode" className="img-fluid" />
            :
            null
        }
      </div>
    </form>
  )
}

export default Deposite