import React, { Fragment, useEffect, useState } from 'react'
import xlsIcon from "../../../assets/images/xlsIcon.png";
import { useExportHistory, useHistory } from '../api/depositewithdrawapi';
import { useDebounce } from '../../../hook/useDounce';
import * as XLSX from "xlsx";
import Converter from '../../../lib/converter';
import Pagination from '../../common/pagination/Pagination';
import { TransferHistorySkeleton } from '../../../Components/Loader/skeleton';
import Nodatafound from '../../Nodata/Nodatafound';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import FileSaver from 'file-saver';
import { networkSymbols } from '../../../lib/network';
import { transactionurl } from '../../../lib/transactionurl';
import { ExternalLink } from 'lucide-react';

function DepositeHistory() {
    const size = 10;
    const [page, setPage] = useState(1)
    const [filter, setFilter] = useState('');
    const [time, setTime] = useState(0);
    const searchText = useDebounce(filter)
    const type = "Deposit"
    const exporthistorymutation = useExportHistory();

    const { data: transactionList, isLoading } = useHistory(
        type, page, size, searchText, time
    )
    useEffect(() => {
    }, [page, searchText, time, filter, type])


    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const handleDownload = async () => {
        let fileName = "Deposit";

        let res = await exporthistorymutation.mutateAsync({ type: "Deposit" })
        const ws = XLSX.utils.json_to_sheet(res?.data);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };


    return (
        <Fragment>
            <div className="d-flex align-items-center">
                <h3 className="cardTitle lgTitle">Deposit History</h3>
                <div className="d-flex align-items-center filterCnt mb-3 ml-auto">
                
                        <input type="search" placeholder="Search" className="form-control searchbox" onChange={e => setFilter(e.target.value)} />
                        <div className='octDrpdown dark'>
                            <UncontrolledDropdown>
                                <DropdownToggle caret>
                                    {time === 7 && "1 Week Ago"}
                                    {time === 30 && "1 Month Ago"}
                                    {!time && "Recently"}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem tag={"a"} onClick={() => setTime(0)}>Recently</DropdownItem>
                                    <DropdownItem tag={"a"} onClick={() => setTime(7)}>1 Week Ago</DropdownItem>
                                    <DropdownItem tag={"a"} onClick={() => setTime(30)}>1 Month Ago</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    

                    <button className="btn filterBtn my-1" onClick={handleDownload} disabled={Boolean(!transactionList?.status)}>
                        <img src={xlsIcon} width="24" height="24" alt="xlsIcon" />
                        Export File
                    </button>
                </div>
            </div>

            <div className="card tableCard">
                <div className="cardInnerCnt">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Date and Time</th>
                                    <th>Address</th>
                                    <th>Amount</th>
                                    <th>Transaction ID</th>
                                    <th className="text-right">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ?
                                    <TransferHistorySkeleton />
                                    :
                                    transactionList?.data?.length > 0 ?
                                        transactionList?.data?.map((n, i) => (
                                            <tr tabIndex={i} className='animated_div' key={n}>
                                                <td>{page == 1
                                                    ? i + 1
                                                    : (page - 1) * 10 +
                                                    (i + 1)}</td>
                                                <td>{Converter.formatDated(n.createdAt)}</td>
                                                <td>{n.cryptoAddress ? n.cryptoAddress?.slice(0, 10) + "..." + n.cryptoAddress?.slice(40, 45) : "-"}</td>
                                                <td>{n.amount} {n.currencySymbol} {n?.currencySymbol === 'USDT' && networkSymbols[n?.network]}</td>
                                                <td>{n.transactionHash ? n.transactionHash?.slice(0, 10) + "..." + n.transactionHash?.slice(-5) : "-"}
                                                    <a href={transactionurl[n.network] + n.transactionHash} target="_blank" style={{ color: "white" }} className='ml-2'>  {n.transactionHash ? <ExternalLink size={20} /> : ""}  </a>
                                                </td>
                                                {n.status === "Pending" ?
                                                    <td className="text-right text-danger">Failed</td>
                                                    :
                                                    <td className="text-right text-success">Completed</td>

                                                }
                                            </tr>
                                        ))
                                        :
                                        <tr className='animated_div'>
                                            <td colSpan={6}>
                                                <Nodatafound message="No Transactions found." />
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Pagination
                total={transactionList?.count}
                current={page}
                size={size}
                onPageChange={no => setPage(no)}
            />
        </Fragment>
    )
}

export default DepositeHistory