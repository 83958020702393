import "../../assets/scss/Innerpages/Innerpages.scss";
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import underMaintenance from '../../assets/images/underMaintenance.png';
import logo from '../../assets/images/logo.png';
import { useQuery } from 'react-query';
import DataService from '../../config/DataService';

const { getRequest } = DataService();

const UnderMaintenance = () => {
   const navigate = useNavigate();

   // Function to fetch site settings
   const fetchSiteSettings = async () => {
      const response = await getRequest('home/underMaintenance');
      return response.data;
   };

   // Query for fetching site settings with polling every 5 seconds
   const { data: siteSettings, refetch } = useQuery('fetchSiteSettings', fetchSiteSettings, {
      refetchInterval: 5000, 
      refetchOnMount: true,
     
   });

   useEffect(() => {
      if (siteSettings !== undefined && !siteSettings?.maintenance) {
         navigate('/');
      }
   }, [siteSettings?.maintenance, navigate]);
   return (
      <div className="innerpagesSec">
         <section className="innerPagesCnt underMaintenanceCnt">
            <div className="container">
               <div className="row justify-content-center">
                  <div className="col-lg-12">
                     <div className="d-flex justify-content-center">
                        <img src={logo} className="img-fluid" alt="logo"/>
                     </div>
                     <div className="d-flex justify-content-center">
                        <img src={underMaintenance} className="img-fluid lightBlend mb-5" alt="maintenance"/>

                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>
   )
}
export default UnderMaintenance;