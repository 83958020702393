import { useMutation, useQuery } from "react-query"
import DataService from "../../../config/DataService"
import { queryClient } from "../../../config";
import StakeService from "../../../config/StakeService";
import DepositService from "../../../config/DepositService";
import { useToast } from "../../../hook/usetoast";
const { getRequest, postRequest } = DataService();
const { stakepost } = StakeService();
const { depositgetRequest } = DepositService()

export const useGetIcoDetails = () => {

    return useQuery({
        queryKey: ['get_ico_details'],
        queryFn: () => {
            return getRequest("home/get_ico_details")
        },
        select: res => res
    })
}

export const useGetCurrency = () => {

    return useQuery({
        queryKey: ['get_currency'],
        queryFn: () => {
            return depositgetRequest("home/get_currency")
        },
        select: res => res?.data
    })
}

export const useAnawallet = () => {
    const toast = useToast()
    return useMutation({
        mutationFn: (data) => {
            return postRequest('home/buy_ana', data)
        },
        onSuccess: success => {
            if (success?.status) {
                queryClient.invalidateQueries('get_currency')
                return toast.success(success?.message)
            }
            return toast.error(success?.message)
        }
    })
}

export const useAnawalletHistory = () => {

    return useMutation({
        mutationFn: (data) => {
            return postRequest('home/ana_buy_history', data)
        },
    })
}

export const useExportAnawalletHistory = () => {

    return useMutation({
        mutationFn: (data) => {
            return postRequest('home/export_ana_buy_history', data)
        },
    })
}

export const useWalletHistory = () => {

    return useMutation({
        mutationKey:['wallethistory'],
        mutationFn: (data) => {
            return stakepost('user/anaWalletHistory', data)
        },
        select: res => res.data
    })
}
