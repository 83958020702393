import React, { useEffect, useState } from 'react';
import "../../../assets/scss/Innerpages/Innerpages.scss";
import { useOnerewardHistory } from '../api/fetchBusinessStore';
import Nodatafound from '../../Nodata/Nodatafound';
import Pagination from '../../common/pagination/Pagination';
import { TransferHistorySkeleton } from '../../../Components/Loader/skeleton';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const BusinessStoreHistory = () => {

    const { reward } = useParams();
    const [page, setPage] = useState(1);
    const size = 10
    const [historyData, setHistoryData] = useState([])
    const onerewardHistoryMutation = useOnerewardHistory()

    useEffect(() => {
        fetchData();
    }, [page])

    const fetchData = async () => {
        let obj = {
            "type": reward,
            "page": page,
            "size": size,
        }
        let res = await onerewardHistoryMutation.mutateAsync(obj);
        setHistoryData(res?.data);
    }

    return (
        <div className="container">
            <h3 className="secHeading text-center">OneTime Reward History</h3>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex align-items-center flex-wrap">
                    </div>
                    <div className="card tableCard">
                        <div className="cardInnerCnt">
                            <div className="table-responsive">
                                <table className="table table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Date & Time</th>
                                            <th>Email</th>
                                            <th>Member ID</th>
                                            <th className="text-center">Reward description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {onerewardHistoryMutation.isLoading ?
                                            <TransferHistorySkeleton />
                                            :
                                            historyData?.length > 0 ?
                                                historyData?.map((n, i) => (
                                                    <tr key={n}>
                                                        <td>{(page - 1) * 10 + (i + 1)}</td>
                                                        <td>{moment(n.executiveDirectorOneRewardDate || n.premiumDirectorOneRewardDate || n.titanDirectorOneRewardDate).format('D/ MM/ YYYY, h:mm:ss')}</td>
                                                        <td>{n?.email}</td>
                                                        <td>{n.memberID}</td>
                                                        <td>{n?.executiveRewardDescription || n?.premiumRewardDescription || n?.titanDirectorRewardDescription}</td>

                                                    </tr>
                                                ))
                                                :
                                                <tr>
                                                    <td colSpan={6}> <Nodatafound /></td>
                                                </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <Pagination
                        total={historyData?.length}
                        current={page}
                        size={10}
                        onPageChange={no => setPage(no)}
                    />
                </div>
            </div>
        </div>
    )
}
export default BusinessStoreHistory;