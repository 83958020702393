import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Calendar } from 'lucide-react';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment/moment';
import { useSignup, useVerifyLINK } from './api/authmutations';
import { ButtonLoader, WaitingLoader } from "../../Components/Loader/buttonLoad";
import { Dots } from '../../lib/dots';
import VerifyEMail from './tabs/verifymail';
import MobileVerification from './tabs/mobileVerify';
import { BounceInDownButton } from '../../Components/animation/bouncein';
import ReactFlagsSelect from "react-flags-select";
import EyeSlashIcon from '../../assets/images/Auth/eye-slash.png'
import EyeIcon from '../../assets/images/Auth/eye.png'
import { CountryFile } from '../common/country';
import { io } from 'socket.io-client';
import { useToast } from '../../hook/usetoast';
import { useModalContext } from '../../contexts/ModalContext';
import ReCAPTCHA from 'react-google-recaptcha';
import { generateSecureRandomNumber } from '../../hook/generateSecureRandomNumber';

const schema = yup.object().shape({
   name: yup.string()
      .min(3, 'Name must be at least 3 characters')
      .max(30, 'Name must be at most 30 characters')
      .required('Name is Required'),
   email: yup.string()
      .required('Verify E-mail ID'),
   mobile: yup.string(),
   // .required('Verify Mobile Number'),
   DOB: yup.date()
      .typeError('Date of Birth is Required')
      .required('Date of Birth is Required'),
   // .max(moment().endOf('day').subtract(18, 'years'), 'DOB should be 18years old'),
   password: yup.string()
      .min(6, 'Password must be at least 6 characters')
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,30}$/, 'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character')
      .max(30, 'Password must be at most 30 characters')
      .required('Password is Required'),
   confirm_password: yup.string()
      .required('Confirm Password is Required')
      .oneOf([yup.ref('password'), null], "Confirm Password doesn't match"),
   mpin: yup.string()
      .required('MPIN is Required')
      .matches(/^[0-9]{6}$/, 'MPIN must be 6 digits'),
   confirmMPIN: yup.string()
      .required('Confirm MPIN is Required')
      .oneOf([yup.ref('mpin'), null], "Confirm MPIN doesn't match"),
   country: yup.string().required('Select Country'),
   referredBy: yup.string().required("Referral link is required. Enter your Upline's Referral Link").matches(new RegExp(`^${window.origin}`), 'Invalid Referral Link'),
   isAgree: yup.boolean()
      .typeError('You must accept the terms and conditions')
      .oneOf([true], "You must accept the terms and conditions"),
   isAgreeAge: yup.boolean()
      .typeError('You must agree')
      .oneOf([true], "You must agree"),
   octcaptcha: yup.string()
      .required('Verify you are a Human')
   ,
})

const Register = () => {

   const { referalid, referalname } = useParams()
   const referallinks = `${window.origin}${window.location.pathname}`

   const { countryverified } = useModalContext();

   const verifylink = useVerifyLINK()

   const recaptchaRef = useRef();

   const signupMutation = useSignup()
   const [show, setShow] = useState(true);
   const [showCon, setShowCon] = useState(true);
   const [mpShow, setMpShow] = useState(true);
   const [mpShowCon, setMpShowCon] = useState(true);
   const [ismailVerified, setIsmailVerified] = useState(false);
   const [islinkVerified, setIslinkVerified] = useState(false);
   const [selected, setSelected] = useState("IN");
   const [loading, setLoading] = useState(false)
   const socket = io(process.env.REACT_APP_SOCKET_URL);


   const { control, handleSubmit, formState: { errors }, reset, setValue, getValues, setError } = useForm({
      mode: 'onChange',
      resolver: yupResolver(schema),
      defaultValues: {
         name: "",
         email: "",
         DOB: "",
         mobile: "",
         password: "",
         confirm_password: "",
         mpin: "",
         confirmMPIN: "",
         country: "",
         referredBy: "",
         isAgree: false,
         dial_code: "",
         octcaptcha: "",
         isAgreeAge: false
      }
   });


   const handleKeyDown = e => e.target.value = e.target.value.match(/^[0-9]{0,6}/)[0]

   const checkRefChar = (e) => {
      if (e.target.value.length === 0 && e.key === ' ') {
         e.preventDefault();
      }
   };

   useEffect(() => {
      if (referalid && referalname) {
         setValue("referredBy", referallinks)
      }
   }, [referalid, referalname])


   const onSubmit = async (data) => {

      setLoading(true)
      let random = Math.floor(generateSecureRandomNumber() * 10000)
      sessionStorage.setItem('random', random)
      const country = CountryFile.find(o => o.code === data.country);
      const dialSplit = data.dialCode
      let obj = {
         name: data.name,
         email: data.email,
         password: data.password,
         confirm_password: data.confirm_password,
         country: country?.name || data.country,
         DOB: data.DOB,
         MPIN: data.mpin,
         confirmMPIN: data.confirmMPIN,
         mobile: (data.mobile).split(data.dialCode)[1],
         isAgree: data.isAgree,
         referredBy: data.referredBy,
         dial_code: "+" + data.dialCode,
         random: random,
         octcaptcha: data.octcaptcha,
      }

      let res = await signupMutation.mutateAsync(obj)
      if (res?.status) {
         setLoading(false)
         reset()
      }
      // else if (res.message == "Invalid referral code") {
      //    setLoading(false)
      // }
      else {
         setLoading(false)
      }
   }

   const toast = useToast()
   const navigate = useNavigate()
   let hasProcessedChangeCredential = false;

   socket.on('mail-status', (data) => {
      if (sessionStorage.getItem('random') == data?.random && !hasProcessedChangeCredential) {
         hasProcessedChangeCredential = true
         sessionStorage.removeItem("random")
         sessionStorage.removeItem("token_ana")
         sessionStorage.removeItem("loginno")
         toast.success(data?.message)
      }
   })
   socket.on('register-mail', (data) => {
      if (sessionStorage.getItem('random') == data?.random && !hasProcessedChangeCredential) {
         hasProcessedChangeCredential = true
         sessionStorage.removeItem("random")
         sessionStorage.removeItem("token_ana")
         sessionStorage.removeItem("loginno")
         navigate('/login')
         toast.success(data?.message)
      }
   })

   const showPassword = e => {
      if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
         setShow(!show)
      }
   }

   const showConPassword = e => {
      if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
         setShowCon(!showCon)
      }
   }

   const showMPIN = e => {
      if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
         setMpShow(!mpShow)
      }
   }

   const showConMPIN = e => {
      if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
         setMpShowCon(!mpShowCon)
      }
   }
   const verifyreferallink = async (data) => {

      if (data.length == 0) {
         setError('referredBy', { message: "Referral link is required. Enter your Upline's Referral Link" })
      } else {
         let obj = {
            "referredBy": data,
         }
         let res = await verifylink.mutateAsync(obj)
         if (res?.status) {
            setIslinkVerified(true)
         } else {
            setIslinkVerified(false)

         }
      }
   }


   return (
      <BounceInDownButton>
         {loading && <WaitingLoader />}
         <div className="row justify-content-center animated_div">
            <div className="col-lg-8">
               <h3 className="modalHeading">Register</h3>
               <p className="modalSubHeading">Create your Account</p>
               <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">

                     <div className="col-lg-6">
                        <div className="form-group">
                           <label className="formLabel" htmlFor="regId12">Enter your upline Referral Link</label>
                           <div className="input-group mb-3">
                              <Controller
                                 name='referredBy'
                                 control={control}
                                 render={({ field }) => (
                                    <>
                                       <input
                                          {...field}
                                          type='text'
                                          disabled={Boolean(referalid && referalname) || islinkVerified}
                                          className='form-control'
                                          id="regId12"
                                          placeholder="Enter your referral link"
                                       />
                                       <div className="input-group-append">
                                          {islinkVerified ?
                                             <span className="input-group-text grnTxt">
                                                Verified
                                             </span> :
                                             <span
                                                className="input-group-text yellowTxt"
                                                type="button"
                                                disabled={verifylink.isLoading}
                                                onClick={() => verifyreferallink(field.value)} // Pass the input value to the function
                                             >
                                                {verifylink.isLoading ?
                                                   <ButtonLoader />
                                                   :
                                                   "Verify"}
                                             </span>
                                          }
                                       </div>
                                    </>
                                 )}
                              />
                           </div>


                           {errors.referredBy && <p className="errMsg">* {errors.referredBy.message}</p>}
                        </div>

                     </div>
                     <div className="col-lg-6">
                        <div className="form-group">
                           <label className="formLabel" htmlFor="regId11">Country</label>
                           <div className='octDrpdown'>
                              <ReactFlagsSelect
                                 selected={selected}
                                 searchable
                                 onSelect={(code) => {
                                    return setSelected(code)
                                 }
                                 }
                                 countries={['AS', 'AI', 'BM', 'VI', 'JE', 'TK', 'CK', 'GI', 'KY']}
                                 blacklistCountries={true}

                                 disabled={!islinkVerified || countryverified}
                              />
                           </div>
                        </div>
                     </div>
                  </div>


                  <div className="row">
                     <div className="col-lg-6">
                        <div className="form-group">
                           <label className="formLabel" htmlFor="regId1">Name</label>
                           <Controller
                              name="name"
                              control={control}
                              render={({ field }) => (
                                 <input
                                    {...field}
                                    type="text"
                                    className="form-control"
                                    onKeyPress={checkRefChar}
                                    id="regId1"
                                    placeholder="Enter your name"
                                    autoComplete="off"
                                    disabled={!islinkVerified}

                                 />
                              )}
                           />
                           {errors.name && <p className="errMsg">* {errors.name.message}</p>}
                        </div>
                     </div>
                     <div className="col-lg-6">
                        <div className="form-group">
                           <label className="formLabel" htmlFor="regId2">Date of Birth</label>
                           <div>
                              <Controller
                                 control={control}
                                 name="DOB"
                                 render={({ field: { onChange } }) => (

                                    <DatePicker
                                       // disabled={!islinkVerified}
                                       onChange={onChange}
                                       format="d-MM-y"
                                       dayPlaceholder="DD"
                                       monthPlaceholder="MM"
                                       yearPlaceholder="YYYY"
                                       value={getValues("DOB")}
                                       // minDate={moment().subtract(100, 'years')._d}
                                       maxDate={moment().subtract(1, 'days')._d}
                                       clearIcon={null}
                                       calendarIcon={<Calendar />}

                                    />
                                 )}
                              />
                           </div>
                           {errors.DOB && <p className="errMsg">* {errors.DOB.message}</p>}
                        </div>
                     </div>

                     <div className='col-12'>
                        <div className='row'>
                           <VerifyEMail
                              setValue={setValue}
                              ismailVerified={ismailVerified}
                              islinkVerified={islinkVerified}
                              setIsmailVerified={setIsmailVerified}
                           />
                           {errors.email && <p className="errMsg">* {errors.email.message}</p>}
                        </div>
                        <div className='row'>
                           <MobileVerification
                              setValue={setValue}
                              ismailVerified={ismailVerified}
                              selectedcountry={selected}
                              email={getValues("email")}
                           />
                           {errors.mobile && <p className="errMsg">* {errors.mobile.message}</p>}
                        </div>
                     </div>

                     <div className="col-lg-6">
                        <div className="form-group">
                           <label className="formLabel" htmlFor="regId7">Password</label>
                           <div className="input-group mb-3">
                              <Controller name='password' control={control} render={({ field }) => (
                                 <input
                                    {...field}
                                    type={show ? "password" : "text"}
                                    className="form-control"
                                    placeholder='Enter your password'
                                    autoComplete='off'
                                    disabled={!islinkVerified}
                                 />
                              )} />
                              <div className="input-group-append">
                                 <span className="input-group-text" type="button" onClick={(e) => showPassword(e)} onKeyDown={(e) => showPassword(e)} onTouchEnd={(e) => showPassword(e)}>
                                    {show ? <img src={EyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" /> :
                                       <img src={EyeIcon} width="20" height="20" alt="eyeSlashIcon" />}
                                 </span>
                              </div>
                           </div>
                           {errors.password && <p className="errMsg">* {errors.password.message}</p>}
                        </div>
                     </div>
                     <div className="col-lg-6">
                        <div className="form-group">
                           <label className="formLabel" htmlFor="regId8">Confirm Password</label>
                           <div className="input-group mb-3">
                              <Controller name='confirm_password' control={control} render={({ field }) => (
                                 <input
                                    {...field}
                                    type={showCon ? "password" : "text"}
                                    className="form-control"
                                    id="regId8" placeholder="Confirm your password"
                                    autoComplete='off'
                                    disabled={!islinkVerified}

                                 />
                              )} />
                              <div className="input-group-append">
                                 <span className="input-group-text" type="button" onClick={(e) => showConPassword(e)} onKeyDown={(e) => showConPassword(e)} onTouchEnd={(e) => showConPassword(e)}>
                                    {showCon ? <img src={EyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" /> :
                                       <img src={EyeIcon} width="20" height="20" alt="eyeSlashIcon" />}
                                 </span>
                              </div>
                           </div>
                           {errors.confirm_password && <p className="errMsg">* {errors.confirm_password.message}</p>}
                        </div>
                     </div>
                     <div className="col-lg-6">
                        <div className="form-group">
                           <label className="formLabel" htmlFor="regId9">Create MPIN</label>
                           <div className="input-group mb-3">
                              <Controller name='mpin' control={control} render={({ field }) => (
                                 <input
                                    {...field}
                                    type={mpShow ? "password" : "text"}
                                    maxLength={6}
                                    className="form-control"
                                    onInput={handleKeyDown}
                                    id="regId9" placeholder="Enter your MPIN"
                                    autoComplete='off'
                                    disabled={!islinkVerified}

                                 />
                              )} />
                              <div className="input-group-append">
                                 <span className="input-group-text" type="button" onClick={(e) => showMPIN(e)} onKeyDown={(e) => showMPIN(e)} onTouchEnd={(e) => showMPIN(e)}>
                                    {mpShow ? <img src={EyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" /> :
                                       <img src={EyeIcon} width="20" height="20" alt="eyeSlashIcon" />}
                                 </span>
                              </div>
                           </div>
                           {errors.mpin && <p className="errMsg">* {errors.mpin.message}</p>}
                        </div>
                     </div>
                     <div className="col-lg-6">
                        <div className="form-group">
                           <label className="formLabel" htmlFor="regId10">Confirm MPIN</label>
                           <div className="input-group mb-3">
                              <Controller name='confirmMPIN' control={control} render={({ field }) => (
                                 <input
                                    {...field}
                                    type={mpShowCon ? "password" : "text"}
                                    className="form-control"
                                    maxLength={6}
                                    onInput={handleKeyDown}
                                    id="regId10" placeholder="Confirm your MPIN"
                                    autoComplete='off'
                                    disabled={!islinkVerified}

                                 />
                              )} />
                              <div className="input-group-append">
                                 <span className="input-group-text" type="button" onClick={(e) => showConMPIN(e)} onKeyDown={(e) => showConMPIN(e) } onTouchEnd={(e) => showConMPIN(e)}>
                                    {mpShowCon ? <img src={EyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" /> :
                                       <img src={EyeIcon} width="20" height="20" alt="eyeSlashIcon" />}
                                 </span>
                              </div>
                           </div>
                           {errors.confirmMPIN && <p className="errMsg">* {errors.confirmMPIN.message}</p>}
                        </div>
                     </div>


                  </div>

                  <div className='my-4'>
                     <div className="d-flex justify-content-center agrTrm">
                        <label className="checkCnt">I agree to the <Link to="/terms" className="yellowTxt">Terms & condition</Link> & <Link to="/privacy" className="yellowTxt">Privacy Policy</Link>
                           <Controller name='isAgree' control={control} render={({ field }) => (
                              <input
                                 {...field}
                                 type="checkbox"
                              />
                           )} />
                           <span className="checkmark"></span>
                        </label>
                     </div>
                     {errors.isAgree && <p className="errMsg text-center">* {errors.isAgree.message}</p>}
                  </div>
                  <div className='my-4'>
                     <div className="d-flex justify-content-center agrTrm">
                        <label className="checkCnt">I confirm that I am <Link to="" className="yellowTxt">18 years</Link> or <Link to="" className="yellowTxt">older</Link>
                           <Controller name='isAgreeAge' control={control} render={({ field }) => (
                              <input
                                 {...field}
                                 type="checkbox"
                              />
                           )} />
                           <span className="checkmark"></span>
                        </label>
                     </div>
                     {errors.isAgreeAge && <p className="errMsg text-center">* {errors.isAgreeAge.message}</p>}
                  </div>
                  {errors.referredBy ?
                     < div className="d-flex justify-content-end">
                        <div className="refTstCnt">
                           <div className="d-flex align-items-start justify-content-between">
                              <div className="d-flex flex-column mr-4">
                                 <span>Enter Your Upline's Referal Link</span>
                              </div>
                              {/* <img src={close} alt='close' width="20px" /> */}
                           </div>
                        </div>
                     </div> : ""
                  }


                  <div className='d-flex justify-content-center my-4'>
                     <Controller name='octcaptcha' control={control} render={({ field }) => (
                        <ReCAPTCHA
                           {...field}
                           ref={recaptchaRef}
                           sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                        />
                     )} />
                  </div>
                  {errors.octcaptcha && <p className="errMsg text-center"> {errors.octcaptcha.message}</p>}

                  <div className="d-flex justify-content-center mb-4">
                     <button className="btn btn-11545 btnGold" disabled={signupMutation.isLoading}>
                        {signupMutation.isLoading ? <Dots>Processing</Dots> : "Create"}
                     </button>
                  </div>
                  <p className="text-center">Already have an account?
                     <Link to='/login' className="yellowTxt mx-2">Login</Link>
                  </p>
               </form>
            </div>
         </div >
      </BounceInDownButton>
   )
}

export default Register;