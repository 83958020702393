import React, { useEffect } from 'react'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import userAV from "../../Pages/Dashboard/userAV.webp";
import ddIcon from "../../assets/images/ddIcon.png";
import { Link, useNavigate } from 'react-router-dom';
import innerHeaderMenuIcon from "../../assets/images/innerHeaderMenuIcon.png";
import { useModalContext } from '../../contexts/ModalContext';
import { useGetProfile, useLogout, useFetchSiteSettings, useJwtLink, useCheckLogin } from './api';
import { io } from 'socket.io-client';
import Cookies from 'js-cookie'
import { useToast } from '../../hook/usetoast';
import PropTypes from 'prop-types';

function InnerHeader({ sbShow }) {

    const { data: getProfile, isLoading } = useGetProfile();
    const navigate = useNavigate()
    const { data: siteSettings } = useFetchSiteSettings();

    const { setprofileData, setisLoading, setIsLoggedIn } = useModalContext();
    const logout = useLogout();
    const { setSitesettings } = useModalContext();
    // const checklinkmutation = useJwtLink()
    // const checkLoginStatus = useCheckLogin()
    let token = sessionStorage.getItem('token_ana')
    let localemail = sessionStorage.getItem('email')
    let type = sessionStorage.getItem('type')
    let cookiedata = Cookies.get('sdgsdsadgt')
    const toast = useToast()


    // useEffect(() => {
    //     if (token) {
    //         CheckvalidLink()
    //         if (sessionStorage.getItem("type") != "admin") {
    //             CheckLogin()
    //         }
    //     }
    // }, [])

    // const CheckvalidLink = async () => {
    //     await checklinkmutation.mutateAsync({ link: token, email: localemail || '' })
    // }

    // const CheckLogin = async () => {
    //     const response = await checkLoginStatus.mutateAsync()
    //     if (response?.status == 444) {
    //         sessionStorage.clear()
    //         setIsLoggedIn("")
    //         Cookies.remove('sdgsdsadgt')
    //         navigate("/login")
    //     }
    // }

    useEffect(() => {
        setisLoading(isLoading)
        setprofileData(getProfile)
        if (siteSettings) {
            setSitesettings(siteSettings)
        }
    }, [getProfile, isLoading, siteSettings])

    const socket = io(process.env.REACT_APP_SOCKET_URL);


    socket.on('check-active', (data) => {
        if (sessionStorage.getItem('loginno') == data?.login_no) {
            socket.emit('user-emit', data);
        }
    });

    const logOut = async () => {   
        if (token) {
            CheckvalidLink()
            if (sessionStorage.getItem("type") != "admin") {
                // CheckLogin()
            }
        }
    }

    const CheckvalidLink = async () => {
        // await checklinkmutation.mutateAsync({ link: token, email: localemail || '' })
    // }

        if(sessionStorage.getItem("type") == "admin" || Cookies.get('sdgsdsadgt')){
            sessionStorage.clear()
            Cookies.remove('sdgsdsadgt')
            navigate('/login')
        }else{
        await logout.mutateAsync({})
        sessionStorage.removeItem("token_ana")
        sessionStorage.removeItem("loginno")
        sessionStorage.removeItem("type")
        Cookies.remove('sdgsdsadgt')
        setIsLoggedIn("")
        }
    }

    useEffect(() => {
        if (token) {
            if (sessionStorage.getItem("type") != "admin" && !Cookies.get('sdgsdsadgt') && sessionStorage.getItem("type") != "user") {
                Cookies.remove('sdgsdsadgt')
                logOut()
            } else if (!Cookies.get('sdgsdsadgt')) {
                if (sessionStorage.getItem("type") == "admin") {
                    Cookies.remove('sdgsdsadgt')
                    logOut()
                }
            } else if (sessionStorage.getItem("type") != "admin") {
                if (Cookies.get('sdgsdsadgt')) {
                    Cookies.remove('sdgsdsadgt')
                    logOut()
                }
            }
        }
    }, [type, cookiedata])

    useEffect(() => {
        const handleStorage = (e) => {
            setIsLoggedIn(token);
        };
        window.addEventListener("storage", handleStorage);

        return () => {
            window.removeEventListener("storage", handleStorage);
            setIsLoggedIn("");
        };
    }, []);

    const unavailable = async () => {
        return toast.error('Currently unavailable.')
    }


    return (
        <div className="hdRgt">
            <UncontrolledDropdown>
                <DropdownToggle caret className="btn btn-11545 btnGold">
                    Buy
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem tag="a">
                        <Link 
                        // to='/participate' 
                        onClick={unavailable}> Participate</Link>
                    </DropdownItem>
                    <DropdownItem tag="a">
                        <Link to='/bussiness-ico'>ANA Wallet</Link>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown className="prfdd">
                <DropdownToggle className="btn btn-11545">
                    <div className="d-flex align-items-center urNme">
                        <img src={getProfile?.profileImage || userAV} alt="profPic" width="24" height="24" className="mr-1" />
                        <span>{getProfile?.name}</span>
                        <img src={ddIcon} alt="profPic" width="8" height="5" className="ml-2" />
                    </div>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem tag="a">
                        <Link to='/dashboard' className='p-0'>Dashboard</Link>
                    </DropdownItem>
                    <DropdownItem tag="a">
                        <Link to='/profile' className='p-0'>Profile</Link>
                    </DropdownItem>
                    <DropdownItem tag="a" onClick={logOut}>Logout</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            <div className='menuToggle'>
                <UncontrolledDropdown>
                    <DropdownToggle className="iconToggle">
                        <img src={innerHeaderMenuIcon} alt="profPic" width="24" height="24" className="mr-1" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem tag="a">
                            <Link to="/wallet">Deposit</Link>
                        </DropdownItem>
                        <DropdownItem tag="a">
                            <Link to={{ pathname: '/withdraw' }}>Withdraw</Link>
                        </DropdownItem>
                        <DropdownItem tag="a">
                            <Link to="/redeem">Redeem</Link>
                        </DropdownItem>
                        <DropdownItem tag="a">
                            <Link to="/business-transfer">Transfer</Link>
                        </DropdownItem>
                        <DropdownItem tag="a">
                            <Link to="/bussiness-volume">Business Volume</Link>
                        </DropdownItem>
                        <DropdownItem tag="a">
                            <Link to="/bussiness-store">Business Store</Link>
                        </DropdownItem>
                        <DropdownItem tag="a">
                            <Link to="/liquidity-report">Liquidity Pool</Link>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
            <div className="mobMenu d-lg-none">
                <button className="smClose" onClick={sbShow}>
                    <svg id="closeicon" viewBox="0 0 800 600">
                        <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
                        <path d="M300,320 L460,320" id="middle"></path>
                        <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
                    </svg>
                </button>
            </div>
        </div>
    )
}

InnerHeader.propTypes = {
    sbShow : PropTypes.any
}

export default InnerHeader