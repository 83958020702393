import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import "../../../assets/scss/Innerpages/Innerpages.scss";
import { UncontrolledCollapse } from 'reactstrap';
import logoLg from "../../../assets/images/logoLg.png";
import crown from "../../../assets/images/crown.png";
import crown1 from "../../../assets/images/crown1.png";
import crown2 from "../../../assets/images/crown2.png";
import profile2user from "../../../assets/images/profile-2user.png";
import pf2 from "../../../assets/images/pf2.png";
import user from "../../../assets/images/user.png";
import downarr from "../../../assets/images/downarr.png";
import arrowCircleRight from "../../../assets/images/arrow-circle-right.png";
import collapseArrowCircle from "../../../assets/images/arrow-circle-down.png";
import eyeSlashIcon from '../../../assets/images/Auth/eye-slash.png';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useAchievement, usePremiumCondition } from '../api/fetchBusinessStore';
import { WaitingLoader } from '../../../Components/Loader/buttonLoad';
import { useLocation, useParams } from 'react-router-dom';


const BusinessStoreTitan = () => {

   const { state } = useLocation()
   let totalMember = state ? state?.levels : '--'
   console.log("✨✨✨✨ ~ BusinessStoreTitan ~ totalMember:", totalMember)

   const premiumAchiement = useAchievement()

   const [data, setData] = useState('')
   const [loading, setLoading] = useState(false)
   const [Qualification, setQualification] = useState('')
   console.log("✨✨✨✨ ~ BusinessStoreTitan ~ Qualification:", Qualification)


   useEffect(() => {
      const fetchPremium = async () => {
         try {
            setLoading(true)
            setQualification(totalMember)
            const response = await premiumAchiement.mutateAsync({ type: totalMember })
            console.log("🚀 ~ fetchPremium ~ response:", response)
            if (response.status) {
               setLoading(false)
               setData(response.data)
            } else {
               setLoading(false)
            }
         } catch (error) {
            setLoading(false)
         }
      }

      fetchPremium()
   }, [totalMember])



   return (
      <div className="container">
         {loading && <WaitingLoader />}
         <div className="d-flex align-items-center flex-wrap justify-content-center">
            <h3 className="secHeading text-center mr-3">Business Store</h3>
         </div>


         <section className="BisTorsec cmntxt">
            <div className="ashBg mt-5">
               <div className="inrCnt">
                  <div className="row align-items-center justify-content-between borderbtm mx-2">
                     <div className="col-xl-6 col-lg-5 col-md-4 px-0">
                        {Qualification === "moonstaker" ? <h4 className="pb-lg-0 pb-3">MOONSTAKER ELIGIBLITY CRITERIA</h4> :

                           <h4 className="pb-lg-0 pb-3">{`${Qualification === 'executive' ? "EXECUTIVE" : Qualification === 'premium' ? "PREMIUM" : "TITAN"} DIRECTOR ELIGIBLITY CRITERIA`}</h4>}
                     </div>
                  </div>
                  {Qualification === "moonstaker" ?
                     <div className="row mt20">
                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Self Stake</h5>
                           <h3 className="value">{data ?? "-"}</h3>
                        </div>
                     </div> :
                     <div className="row mt20">
                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Team Size</h5>
                           <h3 className="value">{data?.adminteamSize ?? "-"}</h3>
                        </div>
                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Direct Members</h5>
                           <h3 className="value">{data?.admindirectMembers ?? "-"}</h3>
                        </div>
                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Self Stake</h5>
                           <h3 className="value">{data?.adminselfStake ?? "-"}</h3>
                        </div>
                        {Qualification === 'executive' ?
                           <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                              <h5 className="heading">Leg Count</h5>
                              <h3 className="value">{data?.adminlegCount ?? "-"}</h3>
                           </div>
                           : ""}
                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Side Volume Count</h5>
                           <h3 className="value">{data?.adminSideVolumeCount ?? "-"}</h3>
                        </div>
                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Side Volume Business</h5>
                           <h3 className="value">{data?.adminSidevolumeBusiness ?? "-"}</h3>
                        </div>
                        {Qualification === 'executive' ?
                           <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                              <h5 className="heading">Leg Stake</h5>
                              <h3 className="value">{data?.adminlegStake ?? "-"}</h3>
                           </div>
                           : ""}
                        {Qualification !== 'executive' && (Qualification === "premium" || Qualification === "titan") ?
                           <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                              <h5 className="heading">Executive Director count</h5>
                              <h3 className="value">{data?.adminExecutiveCount ?? "-"}</h3>
                           </div>

                           : Qualification !== 'executive' ?
                              <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                                 <h5 className="heading">Premium Director count</h5>
                                 <h3 className="value">{data?.adminPremiumCount ?? "-"}</h3>
                              </div> : ""}
                        {Qualification === 'titan' ?
                           <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                              <h5 className="heading">Premium Director count</h5>
                              <h3 className="value">{data?.adminPremiumCount ?? "-"}</h3>
                           </div>
                           : ""}


                     </div>
                  }


               </div>
            </div>
            <div className="ashBg mt-3">
               <div className="inrCnt">
                  <div className="borderbtm ">
                  {Qualification === "moonstaker" ? <h4 className="pb-lg-0 pb-3">MOONSTAKER MY ACHIEVEMENT</h4> :
                     <h4 className="pb-lg-0 py-3">{`${Qualification === 'executive' ? "EXECUTIVE" : Qualification === 'premium' ? "PREMIUM" : "TITAN"} DIRECTOR MY ACHIEVEMENT`}</h4>}
                  </div>
                  {Qualification === "moonstaker" ?
                     <div className="row mt20">
                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Self Stake</h5>
                           <h3 className="value">{data ?? "-"}</h3>
                        </div>
                     </div>
                     :
                     <div className="row mt20">
                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Team Size</h5>
                           <h3 className="value">{data?.teamSize ?? "-"}</h3>
                        </div>
                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Direct Members</h5>
                           <h3 className="value">{data?.directMembers ?? "-"}</h3>
                        </div>
                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Self Stake</h5>
                           <h3 className="value">{data?.selfStake ?? "-"}</h3>
                        </div>
                        {Qualification === 'executive' ?

                           <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                              <h5 className="heading">Leg Count</h5>
                              <h3 className="value">{data?.legCount ?? "-"}</h3>
                           </div>
                           : ""}



                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Side Volume Count</h5>
                           <h3 className="value">{data?.sidevolumeCount ?? "-"}</h3>
                        </div>

                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Side Volume Business</h5>
                           <h3 className="value">{data?.sidevolumeBusiness ?? "-"}</h3>
                        </div>
                        {Qualification === 'executive' ?

                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Leg Stake</h5>
                           <h3 className="value">{data?.legStake ?? "-"}</h3>
                        </div>
                        :""}
                        {Qualification !== 'executive' && (Qualification === "premium" || Qualification === "titan") ?

                           <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                              <h5 className="heading">Executive Director count</h5>
                              <h3 className="value">{data?.executiveCount ?? "-"}</h3>
                           </div>


                           : Qualification !== 'executive' ?
                              <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                                 <h5 className="heading">Premium Director Count </h5>
                                 <h3 className="value">{data?.premiumCount ?? "-"}</h3>

                              </div>
                              : ""
                        }
                        {Qualification === 'titan' ?
                           <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                              <h5 className="heading">Premium Director count</h5>
                              <h3 className="value">{data?.premiumCount ?? "-"}</h3>
                           </div>
                           : ""}
                     </div>
                  }
               </div>
            </div>
         </section>
      </div>
   )
}
export default BusinessStoreTitan;