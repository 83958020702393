import { useMutation } from "react-query";
import DepositService from "../../../../config/DepositService";
const { depositpostRequest } = DepositService()


export const useMoveAna = () => {

    return useMutation({
        mutationFn: (data) => {
            return depositpostRequest('user/Move_ANA', data)
        },
    })
}
