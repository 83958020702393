import React, { useEffect, useState } from 'react';
import "../../../assets/scss/Innerpages/Innerpages.scss";
import xlsIcon from "../../../assets/images/xlsIcon.png";
import * as XLSX from "xlsx";
import { useBusinessRoleHistory } from '../api/fetchBusinessStore';
import Nodatafound from '../../Nodata/Nodatafound';
import { TransferHistorySkeleton } from '../../../Components/Loader/skeleton';
import { useLocation, useParams } from 'react-router-dom';


const BusinessStoreHistory = () => {

    const { level } = useParams();
    const { state } = useLocation()
    let totalMember = state ? state?.count : '--'
    const [historyData, setHistoryData] = useState([])

    const businessHistoryMutation = useBusinessRoleHistory()
    const handleDownload = async () => {
        let obj = {
            "keyType": level
        }
        let res = await businessHistoryMutation.mutateAsync(obj);
        if (res?.status) {
            const header = Object.keys(res.data[0]);
            const sheet = XLSX.utils.json_to_sheet(res.data, { header });
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, sheet, "Sheet1");
            XLSX.writeFile(wb, `Level-${level}.xlsx`);
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        let obj = {
            "keyType": level
        }
        let res = await businessHistoryMutation.mutateAsync(obj);
        setHistoryData(res?.data);
    }



    return (
        <div className="container">
            <h3 className="secHeading text-center">Business Store</h3>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex align-items-center flex-wrap">
                        <h3 className="cardTitle lgTitle" style={{textTransform:"capitalize"}}>{level == "moonStaker" ? 'Moon Staker' : level == "executiveDirector" ? "Executive Director" :  level == "premiumDirector" ? "Premium Director" : "Titan Director"}</h3>
                        <div className="d-flex align-items-center filterCnt mb-3 ml-auto flex-wrap flex-md-nowrap">
                            <div className="d-flex flex-column filtValueCnt">
                                <h5 className="cardLabel whiteTxt mb-0">Total Member</h5>
                                <h5 className="cardLabel prmyTxt mb-0">{totalMember}</h5>
                            </div>
                            <button className="btn filterBtn my-1" type="button" onClick={() => handleDownload()}><img src={xlsIcon} width="24" height="24" alt="xlsIcon" className="mr-2" />Export File</button>
                        </div>
                    </div>

                    <div className="card tableCard">
                        <div className="cardInnerCnt">
                            <div className="table-responsive">
                                <table className="table table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Member ID</th>
                                            <th>Username</th>
                                            <th>Stake amount</th>
                                            <th className="text-right">Direct refer</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {businessHistoryMutation.isLoading ?
                                            <TransferHistorySkeleton />
                                            :
                                            historyData?.length > 0 ?
                                                historyData?.map((n, i) => (
                                                    <tr key={i}>
                                                        <td>{(i + 1)}</td>
                                                        <td>{n?.MemberID}</td>
                                                        <td>{n?.username}</td>
                                                        <td>{n?.StakeAmount} ANA</td>
                                                        <td className="text-right">{n?.DirectRefer || 0}</td>
                                                    </tr>
                                                ))
                                                :
                                                <tr>
                                                    <td colSpan={4}> <Nodatafound /></td>
                                                </tr>}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BusinessStoreHistory;