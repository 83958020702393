import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import "../../assets/scss/Innerpages/Innerpages.scss";
import { Modal, ModalBody, ModalHeader, UncontrolledCollapse } from 'reactstrap';
import logoLg from "../../assets/images/logoLg.png";
import unlockIco from "../../assets/images/unlockIco.png";
import lockIcon from "../../assets/images/lockIcon.png";
import arrowCircleRight from "../../assets/images/arrow-circle-right.png";
import collapseArrowCircle from "../../assets/images/arrow-circle-down.png";
import { useGetCurrentPhase, useStakeHistory } from './api/fetchstakestore';
import Converter from '../../lib/converter';
import { StakeViewSkeleton } from '../../Components/Loader/skeleton';
import Nodatafound from '../Nodata/Nodatafound';
import Aos from 'aos';
import ClaimStore from './tabs/claim';
import { CheckActive, RemainingTime, RewardsEarned } from '../../lib/expireTime';
import { ButtonLoader, WaitingLoader } from '../../Components/Loader/buttonLoad';


const phaseList = [1, 2, 3, 4, 5, 6];

const StakingStoreDetails = () => {

   const { data: getCurrentphase, isLoading: phaseLoader } = useGetCurrentPhase();

   const [phase, setPhase] = useState();
   const { data: stakeHistory, isLoading } = useStakeHistory({ phase: phase });

   const [open, setOpen] = useState(false)
   const toggle = () => setOpen(!open)

   useEffect(() => {
      Aos.init({ once: true })
      window.scrollTo(0, 0)
   }, [])

   useEffect(() => {
      if (getCurrentphase?.status) {
         setPhase(getCurrentphase?.phase)
      }
   }, [getCurrentphase])

   const handlePhaseChange = (e, item) => {
      if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
         if (item > Number(getCurrentphase?.phase)) return
         setPhase(item)
      }
   }

   function addDays(temp) {
      let date = new Date();
      // date.setHours(0, 0, 0, 0);

      let enddate = new Date(temp);
      // enddate.setHours(0, 0, 0, 0);
      let difference = (enddate - date) / (1000 * 60 * 60 * 24);

      if (difference > 0) {
         return difference;
      } else {
         return 0
      }

   }

   function perDay(yeild, staked, period, temp) {

      var countDownDate = new Date(temp).getTime();

      var now = new Date().getTime();

      var timeleft = countDownDate - now;

      var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));

      let value = Number(yeild) / 100 * Number(staked);
      let noofDays = period - days;

      const getDays = addDays(temp)

      value = getDays ? (period - getDays) ? Number(value) / Number(period) * noofDays : 0 : Number(value);

      return value
   }

   return (
      <div className="container animated_div">
         {phaseLoader && <WaitingLoader />}
         <div className="d-flex align-items-center flex-wrap justify-content-between">
            <div className=''>
               <h3 className="secHeading text-center mr-3">Staking Store</h3>
               <h3 className="cardTitle lgTitle">Stake View</h3>
            </div>
            <div className="d-flex align-items-center justify-content-center flex-wrap">
               <button className="btn btn-11545 btnGold mx-2" onClick={toggle}>Claim</button>
               <div className="card mx-2">
                  <div className="cardInnerCnt">
                     <h5 className="cardLabel whiteTxt mb-0">Total available</h5>
                     <h5 className="cardValue lgTxt prmyTxt mb-0">{isLoading ?
                        <ButtonLoader /> :
                        Converter.formattedValue(stakeHistory?.data?.totalAvalible) || "0.00"}
                     </h5>
                  </div>
               </div>
               <div className="card goldcard">
                  <div className="cardInnerCnt">
                     <h5 className="cardLabel mb-0">Total Stake Redeem</h5>
                     <h5 className="cardValue lgTxt mb-0">{isLoading ?
                        <ButtonLoader /> :
                        Converter.formattedValue(stakeHistory?.data?.totalStakeRedeem) || "0.00"}{" "}ANA
                     </h5>
                  </div>
               </div>
            </div>
         </div>

         <div className="d-flex align-items-center flex-wrap justify-content-center mb-4">
            {phaseList?.map((n, i) => (
               <div key={i}
                  style={{
                     cursor: n > Number(getCurrentphase?.phase) ? 'not-allowed' : "pointer",
                     opacity: n > Number(getCurrentphase?.phase) ? 0.6 : 1
                  }}
                  className={`stakeViewPhaseCnt d-flex justify-content-center align-items-center ${Number(getCurrentphase?.phase) === n && "unlock"}`}
                  onClick={(e) => handlePhaseChange(e, n)}
                  onKeyDown={(e) => handlePhaseChange(e, n)}>
                  <div className="phaseContCnt">
                     <div>
                        <div className="d-flex align-items-center justify-content-center mb-3">
                           <div className="phaseNoCnt"><span>0{n}</span></div>
                           <h3>Phase</h3>
                        </div>
                        <div className="d-flex align-items-center justify-content-center" data-aos="zoom-in" data-aos-duration="200">
                           {Number(getCurrentphase?.phase) > n ?
                              <img src={unlockIco} className="lockIcon" height="40" alt="unlockIco" /> :
                              Number(getCurrentphase?.phase) === n ?
                                 <img src={logoLg} className="logoIcon" width="39" height="39" alt="coinIcon" /> :
                                 <img src={lockIcon} className="lockIcon" width="39" height="39" alt="lockIcon" />
                           }
                        </div>
                     </div>
                  </div>
               </div>
            ))}
            <div className="stakeViewPhaseCnt totClaimCnt unlock d-flex justify-content-center align-items-center">
               <div className="phaseContCnt">
                  <div>
                     <div className="d-flex align-items-center justify-content-start">
                        <h3>Total Claimed</h3>
                     </div>
                     <div className="d-flex align-items-center justify-content-between my-2">
                        <h3 className="coinCount prmyTxt">{isLoading ?
                           <ButtonLoader /> :
                           Converter.formattedValue(stakeHistory?.data?.totalClaimed) || "0.00"}{" "}ANA
                        </h3>
                        <Link onClick={toggle}><img src={arrowCircleRight} className="rightIcon" width="24" height="24" alt="rightIcon" /></Link>
                     </div>
                     <small><Link to="/claim-history" className="prmyTxt">View History</Link></small>
                  </div>
               </div>
            </div>
         </div>

         {isLoading ?
            <StakeViewSkeleton />
            :
            stakeHistory?.history?.length > 0 ?
               stakeHistory?.history?.map((n, i) => {
                  return (
                     <div className="stakeViewRow" key={i}>
                        <div className="stakeViewInnerCnt">
                           <div className="stakeValueCnt">
                              <h3 className="cardLabel">Stake Number</h3>
                              <h3 className="cardLabel whiteTxt">{n.stakeNumber}</h3>
                           </div>
                           <div className="stakeValueCnt">
                              <h3 className="cardLabel">Stake Value</h3>
                              <h3 className="cardLabel whiteTxt">{n.stakeValue} ANA</h3>
                           </div>
                           <div className="stakeValueCnt">
                              <h3 className="cardLabel">Stake Period</h3>
                              <h3 className="cardLabel whiteTxt">{n.noOfdays}</h3>
                           </div>
                           <div className="stakeValueCnt">
                              <h3 className="cardLabel">Stake Yield</h3>
                              <h3 className="cardLabel whiteTxt">{n.yield} %</h3>
                           </div>
                           <div className="stakeValueCnt">
                              <h3 className="cardLabel">Remaining Days</h3>
                              <h3 className="cardLabel whiteTxt"><RemainingTime temp={n.expiryDate} /></h3>
                           </div>
                           <div className="stakeValueCnt">
                              <h3 className="cardLabel">Redeem Value</h3>
                              <h3 className="cardLabel whiteTxt">{n.redeemValue}</h3>
                           </div>

                           <div className="stakeValueCnt">
                              <h3 className="cardLabel">Status</h3>
                              <CheckActive temp={n.expiryDate} />
                           </div>
                           <div className="stakeValueCnt d-flex justify-content-end collapseArrowCircle" id={`toggler${i}`} type="button">
                              <img src={collapseArrowCircle} width="30" height="30" alt="collapseArrowCircle" />
                           </div>

                           <UncontrolledCollapse toggler={`#toggler${i}`} className="w-100 toggleCnt">
                              <div className="d-flex flex-column flex-md-row align-items-center justify-content-center">
                                 <div className="col-lg-3 px-0">
                                    <div className="d-flex align-items-center">
                                       <h3 className="cardLabel mr-2">Date of Staking</h3>
                                       <h3 className="cardLabel whiteTxt ml-auto">{Converter.formatDated(n.createdAt)}</h3>
                                    </div>
                                    <div className="d-flex align-items-center w-150">
                                       <h3 className="cardLabel mr-2">Stake Expire</h3>
                                       <h3 className="cardLabel whiteTxt ml-auto">{Converter.formatDated(n.expiryDate)}</h3>
                                    </div>
                                 </div>
                                 <div className="col-lg-4 ml-auto stkStrView">
                                    <form>
                                       <div className="form-group mb-0">
                                          {/* <div className="input-group mb-3">
                                             <input type="password" readOnly className="form-control" id="regId4" placeholder="Rewards Earned" />
                                             <div className="input-group-append">
                                                <span className="input-group-text">
                                                   {perDay(n.yield, n.stakeValue, n.noOfdays, n.expiryDate) + " ANA"}
                                                   <RewardsEarned period={n.noOfdays} temp={n.expiryDate} staked={n.stakeValue} yeild={n.yield} /> 

                                                </span>
                                             </div>
                                          </div> */}
                                       </div>
                                    </form>
                                 </div>
                              </div>
                           </UncontrolledCollapse>
                        </div>
                     </div>
                  )
               })
               :
               <div className="stakeViewRow">
                  <div className="stakeViewInnerCnt justify-content-center">
                     <Nodatafound message="No Data Found" />
                  </div>
               </div>
         }

         <Modal isOpen={open} toggle={toggle} modalClassName="authModal">
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
               <ClaimStore toggle={toggle} available={stakeHistory?.data?.totalAvalible} phase={getCurrentphase?.phase} />
            </ModalBody>
         </Modal>

      </div>
   )
}
export default StakingStoreDetails;