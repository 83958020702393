import React, { Fragment, useEffect, useState } from 'react'
import xlsIcon from "../../../assets/images/xlsIcon.png";
import { useDebounce } from '../../../hook/useDounce';
import { useHistory, useExportHistory } from '../api/depositewithdrawapi';
import { TransferHistorySkeleton } from '../../../Components/Loader/skeleton';
import Converter from '../../../lib/converter';
import Nodatafound from '../../Nodata/Nodatafound';
import Pagination from '../../common/pagination/Pagination';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { networkSymbols } from '../../../lib/network';
import { transactionurl } from '../../../lib/transactionurl';
import { copyToClipboard } from '../../../lib/copyToClipboard';
import { Copy, Check, ExternalLink } from 'lucide-react';
import { useToast } from '../../../hook/usetoast';

function WithdrawHisroty() {
    const size = 10;
    const [page, setPage] = useState(1)
    const [filter, setFilter] = useState('')
    const [time, setTime] = useState(0);
    const searchText = useDebounce(filter)
    const type = "Withdraw"
    const [done, setDone] = useState(false)

    const toast = useToast()
    const exporthistorymutation = useExportHistory();
    useEffect(() => {
    }, [page, searchText, time, filter, type])

    const { data: transactionList, isLoading } = useHistory(
        type, page, size, searchText, time
    )
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const handleDownload = async () => {
        let fileName = "Withdraw";
        let res = await exporthistorymutation.mutateAsync({ type: "Withdraw" })
        const ws = XLSX.utils.json_to_sheet(res?.data);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    const copyuserAddress = async data => {
        setDone(data._id)
        await copyToClipboard(data.cryptoAddress)
        toast.success("Address copied")
        setTimeout(() => {
            setDone()
        }, 800)
    }


    return (
        <Fragment>
            <div className="d-flex align-items-center">
                <h3 className="cardTitle lgTitle">Withdraw History</h3>
                <div className="d-flex align-items-center filterCnt mb-3 ml-auto">
                    
                        
                            <input type="search" placeholder="Search" className="form-control searchbox" onChange={e => setFilter(e.target.value)} />

                            <div className='octDrpdown dark'>
                                <UncontrolledDropdown>
                                    <DropdownToggle caret>
                                        {time === 7 && "1 Week Ago"}
                                        {time === 30 && "1 Month Ago"}
                                        {!time && "Recently"}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag={"a"} onClick={() => setTime(0)}>Recently</DropdownItem>
                                        <DropdownItem tag={"a"} onClick={() => setTime(7)}>1 Week Ago</DropdownItem>
                                        <DropdownItem tag={"a"} onClick={() => setTime(30)}>1 Month Ago</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>

                        
                
                    <button className="btn filterBtn my-1" onClick={handleDownload} disabled={Boolean(!transactionList?.status)}>
                        <img src={xlsIcon} width="24" height="24" alt="xlsIcon" />
                        Export File
                    </button>
                </div>
            </div>

            <div className="card tableCard">
                <div className="cardInnerCnt">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Date and Time</th>
                                    <th>Address</th>
                                    <th>Amount</th>
                                    <th>Transaction ID</th>
                                    <th className="text-right">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ?
                                    <TransferHistorySkeleton />
                                    :
                                    transactionList?.data?.length > 0 ?
                                        transactionList?.data?.map((n, i) => (
                                            <tr tabIndex={i} key={n._id}>
                                                <td>{page == 1
                                                    ? i + 1
                                                    : (page - 1) * 10 +
                                                    (i + 1)}</td>
                                                <td>{Converter.formatDated(n.updatedAt)}</td>
                                                <td>{n.cryptoAddress ? n.cryptoAddress?.slice(0, 10) + "..." + n.cryptoAddress?.slice(40, 45) : "-"}
                                                    {done == n._id ?
                                                        <Check size={20} className="ml-3" />
                                                        :
                                                        <Copy onClick={() => copyuserAddress(n)} size={20} className='ml-3' style={{ cursor: "pointer" }} />
                                                    }
                                                </td>
                                                <td>{n.amount} {n.currencySymbol} {n?.currencySymbol === 'USDT' && networkSymbols[n?.network]}</td>
                                                <td style={{ color: "white" }}>{n.transactionHash ?
                                                    n.transactionHash?.slice(0, 10) + "..." + n.transactionHash?.slice(-5) : "-"}
                                                    <a href={transactionurl[n.network] + n.transactionHash} target="_blank" style={{ color: "white" }} className='ml-2'>  {n.transactionHash ? <ExternalLink size={20} /> : ""}  </a>
                                                </td>
                                                {n.adminTransfer ?
                                                    <td className="text-right text-success">Completed</td>
                                                    :
                                                    <td className="text-right text-danger">Pending</td>
                                                }

                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td colSpan={6}>
                                                <Nodatafound message="No Transactions found." />
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Pagination
                total={transactionList?.count}
                current={page}
                size={size}
                onPageChange={no => setPage(no)}
            />
        </Fragment>
    )
}

export default WithdrawHisroty